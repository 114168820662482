import { FC, useCallback, useMemo } from 'react';
import { LineTemplatesTable } from 'components/lineTemplate/LineTemplatesTable';
import { RouteComponentProps } from 'react-router-dom';
import {
  getComposition,
  getLineTemplates,
  setCurrentCompositionLineTemplates,
} from 'features/composition/compositionActions';
import { useDispatch, useSelector } from 'store/utils';
import { compositionConstructSelector } from 'features/composition/compositionSelectors';
import { LineTemplateSearch } from 'components/lineTemplate/LineTemplateSearch';
import { LineTemplateDto } from 'dto/lineTemplate';
import { LineTemplateEditModal } from 'routes/lineAssignment/LineTemplateEditModal';
import { Row } from 'react-table';
import { LineTemplateDetails } from 'components/lineTemplate/LineTemplateDetails';
import { RelationsSearchWrapper } from 'components/relationsSearchWrapper/RelationsSearchWrapper';
import { useCompositionManage } from 'hooks/useCompositionManage';
import { Button, Icon } from '@fleet/shared/mui';
import { Typography } from '@mui/material';
import { ModalProps } from '@fleet/shared/mui/Modal';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TransButton } from 'i18n/trans/button';

interface LineAssignmentProps
  extends RouteComponentProps<{ compositionId: string }> {}

export const LineAssignment: FC<LineAssignmentProps> = ({ match }) => {
  const dispatch = useDispatch();
  const {
    lineTemplateState: state,
    onModalClose,
    getModalHandler,
  } = useCompositionManage('lineTemplate');
  const { compositionId } = match.params;
  const compositionConstruct = useSelector(compositionConstructSelector);
  const getCurrentCompositionLineTemplates = useCallback(
    async (params?: PaginationParams) => {
      const { items, totalCount, offset } = await dispatch(
        getLineTemplates({
          vehicleCompositionId: +compositionId,
          ...params,
        })
      ).unwrap();

      return dispatch(
        setCurrentCompositionLineTemplates({ data: items, totalCount, offset })
      );
    },
    [compositionId, dispatch]
  );

  const onModalCloseHandler = useCallback<Required<ModalProps>['onClose']>(
    async (event, reason) => {
      reason === 'action' && getCurrentCompositionLineTemplates();
      onModalClose(event, reason);
    },
    [getCurrentCompositionLineTemplates, onModalClose]
  );

  const getCompositionWithLineTemplates = useCallback(async () => {
    await dispatch(getComposition(compositionId));
    getCurrentCompositionLineTemplates();
  }, [compositionId, dispatch, getCurrentCompositionLineTemplates]);

  const getCurrentVehicleComposition = useCallback(
    (lineTemplate: LineTemplateDto) =>
      lineTemplate.vehicleCompositions.find(
        ({ id }) => id === compositionConstruct?.id
      ),
    [compositionConstruct]
  );

  const lineTemplatesControl = useCallback(
    (lineTemplates: LineTemplateDto[]) => {
      return (
        <Button
          variant="text"
          startIcon={<Icon name="plus" />}
          onClick={getModalHandler({
            lineTemplates,
            vehicleComposition: compositionConstruct!,
            action: 'add',
          })}
          label={
            <Typography variant="body2">
              <TransLabel i18nKey="addComposition" />
            </Typography>
          }
        />
      );
    },
    [compositionConstruct, getModalHandler]
  );

  const connectedLineTemplatesControls = useCallback(
    (lineTemplates: LineTemplateDto[]) => {
      const currentVehicleComposition = getCurrentVehicleComposition(
        lineTemplates[0]
      )!;

      return (
        <>
          <Button
            variant="text"
            startIcon={<Icon name="replace" />}
            onClick={getModalHandler({
              lineTemplates,
              vehicleComposition: currentVehicleComposition,
              action: 'replace',
            })}
            label={
              <Typography variant="body2">
                <TransButton i18nKey="update" />
              </Typography>
            }
          />
          <Button
            variant="text"
            startIcon={<Icon name="close" />}
            onClick={getModalHandler({
              lineTemplates,
              vehicleComposition: currentVehicleComposition,
              action: 'delete',
            })}
            label={
              <Typography variant="body2">
                <TransButton i18nKey="delete" />
              </Typography>
            }
          />
        </>
      );
    },
    [getCurrentVehicleComposition, getModalHandler]
  );
  const assignedLinesPresent =
    !!compositionConstruct?.lineTemplates?.data?.length;

  const title = useMemo(() => {
    if (!compositionConstruct) return '';
    const { name, code } = compositionConstruct;

    return [name, code].filter(Boolean).join('/');
  }, [compositionConstruct]);

  return (
    <RelationsSearchWrapper
      entity="lineTemplate"
      onMount={getCompositionWithLineTemplates}
    >
      <>
        <div className="composition-relations panel">
          <h2>{title}</h2>
          <div className="heading">
            <div className="heading-title">
              <TransTitle i18nKey="lineTemplatesConnected" />
            </div>
            {assignedLinesPresent && (
              <div className="connected-number">
                <TransLabel
                  i18nKey="qtyTemplates"
                  values={{
                    count: compositionConstruct!.lineTemplates!.totalCount,
                  }}
                />
              </div>
            )}
          </div>
          {assignedLinesPresent ? (
            <LineTemplatesTable
              vehicleConstructId={compositionConstruct!.id}
              data={compositionConstruct!.lineTemplates!.data}
              totalCount={compositionConstruct!.lineTemplates!.totalCount}
              offset={compositionConstruct!.lineTemplates!.offset}
              onPageChange={getCurrentCompositionLineTemplates}
              controlsAccessor={connectedLineTemplatesControls}
              autoResetExpanded={state.autoResetExpanded}
              renderRowSubComponent={(row: Row<LineTemplateDto>) => (
                <LineTemplateDetails
                  vehicleConstructId={compositionConstruct!.id}
                  {...row}
                />
              )}
            />
          ) : (
            <div className="assigned-none-message">
              <TransLabel i18nKey="noLineTemplatesAssigned" />
            </div>
          )}
        </div>
        <LineTemplateSearch
          title={<TransTitle i18nKey="lineTemplates" />}
          refreshSearch={state.refreshSearch}
          vehicleCompositionRelation="all"
        >
          {({ data, loading, totalCount, offset, onPageChange }) => (
            <LineTemplatesTable
              controlsAccessor={lineTemplatesControl}
              data={data}
              totalCount={totalCount}
              loading={loading}
              onPageChange={onPageChange}
              offset={offset}
              renderRowSubComponent={(row: Row<LineTemplateDto>) => (
                <LineTemplateDetails
                  {...row}
                  getModalHandler={getModalHandler}
                />
              )}
            />
          )}
        </LineTemplateSearch>
        {!!state.lineTemplates.length && (
          <LineTemplateEditModal
            lineTemplates={state.lineTemplates}
            vehicleComposition={state.vehicleComposition!}
            action={state.action}
            onClose={onModalCloseHandler}
          />
        )}
      </>
    </RelationsSearchWrapper>
  );
};
