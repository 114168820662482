import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TripsTable } from 'components/trip/TripsTable';
import { TripDto } from 'dto/trip';
import { VehiclesPanel } from 'components/palettePanel/VehiclesPanel';
import { CompositionManage } from 'components/compositionManage/CompositionManage';
import {
  COMPOSITION_VEHICLE_LOADING,
  CompositionVehicle,
} from 'routes/сomposition/CompositionVehicle';
import {
  addVehicleToTripComposition,
  insetVehicleToTripComposition,
  clearCurrentComposition,
  CompositionElements,
  getAssignedComposition,
  getTrips,
  removeTripCompositionVehicle,
  replaceTripCompositionVehicle,
  setCompositionProcessId,
  TripEditPayload,
  TripVehicleAddPayload,
  TripVehicleReplacePayload,
  updateTripComposition,
  updateTripCompositionElements,
} from 'features/composition/compositionActions';
import { useDispatch, useSelector } from 'store/utils';
import { setLoadingKey } from 'features/common/commonActions';
import { compositionConstructSelector } from 'features/composition/compositionSelectors';
import { setCurrentVehicle } from 'features/vehicle/vehicleActions';
import { CompositionConstructVehicleDto } from '@fleet/widget/dto/composition';
import { Modal } from '@fleet/shared/mui';
import { loadingKeySelector } from 'features/common/commonSelectors';
/**
 * BR-44961 VM: UI freezes for 15 minutes when blocking seats on trip where are transactions as transaction move fails
 * It happens because the new world doesn`t support transaction move (moving passengers to other seats) and then UI freezes
 * so because of that Marek told that we just had to disable this transaction move logic from UI side
 */
// import { ProcessProgress } from 'routes/tripManagement/ProcessProgress';
import { TripDropArea } from 'routes/tripManagement/TripDropArea';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';

interface TripCompositionEditProps
  extends RouteComponentProps<{ tripId: string }, {}, { trip?: TripDto }> {
  updateCompositionControl: (payload: TripDto[]) => JSX.Element;
  compositionId: string;
  refreshComposition: boolean;
}

export const TripCompositionEdit: FC<TripCompositionEditProps> = ({
  location,
  match,
  history,
  updateCompositionControl,
  compositionId,
  refreshComposition,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [vehicleToDelete, setDeleteVehicle] =
    useState<CompositionConstructVehicleDto | null>(null);
  const { state = {} } = location;
  const { params } = match;
  const currentLoadingKey = useSelector(loadingKeySelector);
  const compositionConstruct = useSelector(compositionConstructSelector);
  const compositionVehicles = useMemo(() => {
    return compositionConstruct?.compositionVehicles || [];
  }, [compositionConstruct?.compositionVehicles]);
  const readTripComposition = useCallback(async () => {
    const orderNumber = compositionConstruct?.vehicle?.orderNumber;
    try {
      dispatch(setLoadingKey(COMPOSITION_VEHICLE_LOADING));

      const [
        {
          items: [trip],
        },
      ] = await Promise.all([
        dispatch(getTrips({ id: +params.tripId })).unwrap(),
        dispatch(
          getAssignedComposition({
            compositionId,
            type: 'trip',
            orderNumber,
          })
        ),
      ]);

      await history.replace({
        pathname: location.pathname,
        search: location.search,
        state: { trip },
      });
    } catch (e) {
    } finally {
      await dispatch(setLoadingKey(null));
    }
  }, [
    compositionConstruct?.vehicle?.orderNumber,
    compositionId,
    dispatch,
    history,
    location.pathname,
    location.search,
    params.tripId,
  ]);

  useEffect(() => {
    if (currentLoadingKey === COMPOSITION_VEHICLE_LOADING) return;
    if (compositionConstruct === undefined || refreshComposition) {
      readTripComposition();
    }
  }, [
    readTripComposition,
    refreshComposition,
    dispatch,
    compositionConstruct,
    currentLoadingKey,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearCurrentComposition());
      dispatch(setCurrentVehicle());
    };
  }, [dispatch]);

  const onVehicleChange = useCallback(
    (orderNumber: number) => {
      dispatch(
        setCurrentVehicle(
          compositionConstruct!.compositionVehiclesData![orderNumber - 1]!
        )
      );
    },
    [compositionConstruct, dispatch]
  );

  const addVehicle = useCallback(
    async ({ vehicleId, orderNumber, number }: TripVehicleAddPayload) => {
      dispatch(setLoadingKey(COMPOSITION_VEHICLE_LOADING));
      try {
        if (
          orderNumber > compositionConstruct!.compositionVehiclesData!.length
        ) {
          await dispatch(
            addVehicleToTripComposition({
              tripRelationId: +compositionId,
              vehicleId,
            })
          );
          await readTripComposition();
        } else {
          const { processId } = await dispatch(
            insetVehicleToTripComposition({
              tripRelationId: +compositionId,
              vehicleId,
              orderNumber,
              number,
            })
          ).unwrap();
          await readTripComposition(); // probably we should remove once BR-44961 resolves
          dispatch(setCompositionProcessId(processId));
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        dispatch(setLoadingKey(null));
      }
    },
    [compositionConstruct, compositionId, dispatch, readTripComposition]
  );

  const replaceVehicle = useCallback(
    async (payload: TripVehicleReplacePayload) => {
      try {
        dispatch(setLoadingKey(COMPOSITION_VEHICLE_LOADING));
        const { processId } = await dispatch(
          replaceTripCompositionVehicle({
            tripRelationId: +compositionId,
            ...payload,
          })
        ).unwrap();
        await readTripComposition(); // probably we should remove once BR-44961 resolves
        dispatch(setCompositionProcessId(processId));
      } catch (e) {
        return Promise.reject(e);
      } finally {
        dispatch(setLoadingKey(null));
      }
    },
    [compositionId, dispatch, readTripComposition]
  );

  const deleteVehicle = useCallback(async () => {
    const { id } = vehicleToDelete!;
    const { processId } = await dispatch(
      removeTripCompositionVehicle({
        compositionId: +compositionId,
        compositionVehicleId: id,
      })
    ).unwrap();
    dispatch(setCompositionProcessId(processId));
    readTripComposition();
  }, [compositionId, dispatch, readTripComposition, vehicleToDelete]);

  const updateTripCompositionData = useCallback(
    async (composition: TripEditPayload) => {
      await dispatch(updateTripComposition(composition));
      readTripComposition();
    },
    [dispatch, readTripComposition]
  );

  const updateVehicleElements = useCallback(
    async (elementPayload: CompositionElements) => {
      try {
        const { processId } = await dispatch(
          updateTripCompositionElements(elementPayload)
        ).unwrap();
        dispatch(setCompositionProcessId(processId));
        readTripComposition();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    [dispatch, readTripComposition]
  );

  const vehicleTabsActions = useMemo(() => {
    return {
      onVehicleChange: onVehicleChange,
      updateComposition: updateTripCompositionData,
      deleteVehicle: (vehicleId: number) => {
        setDeleteVehicle(
          compositionVehicles!.find(({ id }) => id === vehicleId)!
        );
      },
    };
  }, [compositionVehicles, onVehicleChange, updateTripCompositionData]);

  const vehicleTabsRender = useCallback(
    (props) => (
      <CompositionVehicle
        key={props.vehicle.id}
        {...props}
        actions={vehicleTabsActions}
      />
    ),
    [vehicleTabsActions]
  );
  const tripCompositions = useMemo(() => {
    return state.trip
      ? state.trip.vehicleCompositions.map(
          ({ tripVehicleCompositionId, name }) => ({
            id: tripVehicleCompositionId,
            name,
          })
        )
      : [];
  }, [state.trip]);

  if (!state.trip) return null;

  return (
    <div className="trip-composition-edit panel">
      <h2>
        <TransTitle i18nKey="manageCompositionForTrip" />
      </h2>
      {/*<ProcessProgress onComplete={readTripComposition} />*/}
      <TripsTable
        className="trip-search"
        data={[state.trip]}
        {...(compositionConstruct?.isActive && {
          controlsAccessor: updateCompositionControl,
        })}
        initialExpanded={{ '0': true }}
        renderRowSubComponent={() => (
          <CompositionManage
            palettePanel={
              <VehiclesPanel vehicleTypeId="VEHICLE_TYPE.CARRIAGE" />
            }
            dropArea={
              <TripDropArea
                onAddVehicle={addVehicle}
                onReplaceVehicle={replaceVehicle}
              />
            }
            compositionsList={tripCompositions}
            vehicleTabsRenderFn={vehicleTabsRender}
            updateTripComposition={updateTripCompositionData}
            updateVehicleElements={updateVehicleElements}
          />
        )}
      />
      {vehicleToDelete && (
        <Modal
          open
          title={<TransTitle i18nKey="deleteConfirmation" />}
          message={
            t('message.areYouSureYouWantToRemoveEntity', {
              defaultValue: 'Are you sure you want to remove the {{entity}}?',
              entity: t('label.vehicle', 'Vehicle').toLowerCase(),
            })!
          }
          actionButton={{
            className: 'delete',
            label: <TransButton i18nKey="delete" />,
            onClick: deleteVehicle,
          }}
          onClose={setDeleteVehicle.bind(null, null)}
        />
      )}
    </div>
  );
};
