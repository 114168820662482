import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getLineTemplates } from 'features/composition/compositionActions';
import { useDispatch, useSelector } from 'store/utils';
import { LineTemplateDto, LineTemplateFilterDto } from 'dto/lineTemplate';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import emptySearchImg from '@fleet/shared/icons/empty-search.svg';
import {
  Button,
  Checkbox,
  DatePicker,
  Icon,
  RadioGroup,
  Select,
  ToggleButtonGroup,
} from '@fleet/shared/mui';
import { assignEntityFilterOptionsSelector } from 'features/classification/classificationSelectors';
import { format } from 'date-fns';
import { VehicleCompositionRelation } from 'dto/composition';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import { makeStyles } from '@mui/styles';
import { CardHeader, DatePickerProps, RadioGroupProps } from '@fleet/shared';
import { SelectValue } from '@fleet/shared/mui/Select';
import { DatePickerValue } from '@fleet/shared/mui/DatePicker';
import { TransLabel } from 'i18n/trans/label';
import { WeekDays } from 'features/utils';
import type { PaginationParams } from '@fleet/shared/dto/pagination';
import { TransButton } from 'i18n/trans/button';

interface LineTemplateSearchProps {
  refreshSearch?: boolean;
  title: ReactNode;
  children(props: {
    data: LineTemplateDto[];
    loading: boolean;
    totalCount: number;
    offset?: number;
    limit?: number;
    onPageChange(params: PaginationParams): Promise<void>;
  }): ReactNode;
  vehicleCompositionRelation: VehicleCompositionRelation;
  filterByCompositionRelation?: boolean;
}
const dateFields = [
  ['lineTemplateStartDateFrom', <TransLabel i18nKey="templateStartDateFrom" />],
  ['lineTemplateStartDateTo', <TransLabel i18nKey="templateStartDateTo" />],
  ['lineTemplateEndDateFrom', <TransLabel i18nKey="templateEndDateFrom" />],
  ['lineTemplateEndDateTo', <TransLabel i18nKey="templateEndDateTo" />],
] as const;

const useStyles = makeStyles(
  () => ({
    select: {
      paddingRight: '32px',
      maxWidth: '17%',
      '& .MuiTypography-body2 + .MuiBox-root': {
        marginBottom: 0,
      },
    },
  }),
  {
    name: 'LineTemplateSearch',
  }
);

interface LineTemplateResult extends PaginationParams {
  data?: Array<LineTemplateDto>;
  totalCount: number;
  loading: boolean;
}

export const LineTemplateSearch: FC<LineTemplateSearchProps> = ({
  title,
  children,
  refreshSearch,
  vehicleCompositionRelation,
  filterByCompositionRelation,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isoDateFormat] = isoDateTimeFormat.split("'T'");
  const lineTemplateDateDefaultFormatted = useMemo(
    () => formatDate(new Date(), isoDateFormat),
    [isoDateFormat]
  );
  const [filterParams, _setFilterParam] = useState<
    Partial<LineTemplateFilterDto>
  >({
    subContractorIds: [],
    serviceCodes: [],
    vehicleCompositionRelation,
    lineTemplateStartDateTo: lineTemplateDateDefaultFormatted,
    lineTemplateEndDateFrom: lineTemplateDateDefaultFormatted,
  });
  const [lineTemplatesResult, setLineTemplates] = useState<LineTemplateResult>({
    limit: 10,
    offset: 0,
    totalCount: 0,
    loading: false,
  });

  const setFilterParam = useCallback(
    (param) => _setFilterParam((filter) => ({ ...filter, ...param })),
    []
  );
  const touched = useMemo(
    () => _isArray(lineTemplatesResult.data),
    [lineTemplatesResult.data]
  );
  const noResults = useMemo(
    () => touched && _isEmpty(lineTemplatesResult.data),
    [lineTemplatesResult.data, touched]
  );

  const searchLineTemplates = useCallback(
    async (params?: PaginationParams) => {
      const latestFilter = {
        ...filterParams,
        ...params,
      };
      setLineTemplates((state) => ({ ...state, loading: true }));

      const { items, totalCount, offset } = await dispatch(
        getLineTemplates(latestFilter)
      ).unwrap();

      setFilterParam(latestFilter);

      setLineTemplates({ data: items, totalCount, offset, loading: false });
    },
    [dispatch, filterParams, setFilterParam]
  );
  useEffect(() => {
    if (refreshSearch) {
      searchLineTemplates({
        offset: lineTemplatesResult.offset,
        limit: lineTemplatesResult.limit,
      });
    }
  }, [lineTemplatesResult, refreshSearch, searchLineTemplates]);

  const departureDaysHandler = useCallback(
    (days: WeekDays) => setFilterParam({ departureDays: days }),
    [setFilterParam]
  );

  const getSelectHandler = useCallback(
    (fieldName) => (selected: SelectValue | Array<SelectValue>) => {
      setFilterParam({
        [fieldName]: selected,
      });
    },
    [setFilterParam]
  );

  const getDateHandler = useCallback<
    (fieldName: string) => Required<DatePickerProps>['onChange']
  >(
    (fieldName) => (date) => {
      setFilterParam({
        [fieldName]: date && format(date as Date, isoDateFormat),
      });
    },
    [setFilterParam, isoDateFormat]
  );

  const onIntervalDepartureChange = useCallback<
    Required<RadioGroupProps>['onChange']
  >(
    (val) => {
      setFilterParam({ hasIntervalDepartures: Boolean(Number(val)) });
    },
    [setFilterParam]
  );

  const { lines, serviceCodes, vehicleCompositions, subContractors, days } =
    useSelector(assignEntityFilterOptionsSelector);

  const getMinDate = useCallback(
    (field: typeof dateFields[number][0]) => {
      const minDateField = _last(
        dateFields
          .slice(
            0,
            dateFields.findIndex((dateField) => dateField[0] === field)
          )
          .filter(([field]) => !!filterParams[field])
      );

      return minDateField && new Date(filterParams[minDateField[0]]!);
    },
    [filterParams]
  );

  return (
    <div className="relations-search panel">
      <CardHeader title={title} className="relations-title" />
      <div className="relations-filter">
        <div className="filter-row">
          <Select
            className={classes.select}
            label={<TransLabel i18nKey="lineNumber" />}
            onChange={getSelectHandler('lineId')}
            options={lines}
            labelPosition="top"
            showEmptyOption
          />
          <Select
            className={classes.select}
            label={<TransLabel i18nKey="serviceCode" />}
            labelPosition="top"
            onChange={getSelectHandler('serviceCodes')}
            options={serviceCodes}
            value={filterParams.serviceCodes}
            multiple
          />
          <Select
            className={classes.select}
            label={<TransLabel i18nKey="subContractor" />}
            onChange={getSelectHandler('subContractorIds')}
            options={subContractors}
            value={filterParams.subContractorIds}
            labelPosition="top"
            multiple
          />
          <Select
            className={classes.select}
            label={<TransLabel i18nKey="connectedVehicles" />}
            labelPosition="top"
            onChange={getSelectHandler('vehicleCompositionId')}
            options={vehicleCompositions}
            showEmptyOption
          />
          <Button
            label={<TransButton i18nKey="search" />}
            className="filter-btn"
            onClickAsync={searchLineTemplates.bind(null, { offset: 0 })}
            startIcon={<Icon name="search" color="white" />}
          />
        </div>
        <div className="filter-row">
          {dateFields.map(([field, label]) => (
            <DatePicker
              key={field}
              label={label}
              value={filterParams[field] as DatePickerValue<false>}
              onChange={getDateHandler(field)}
              minDate={getMinDate(field)}
            />
          ))}
        </div>
        <div className="filter-row">
          <div className="departure-days-filter">
            <label>
              <TransLabel i18nKey="departureDays" />
            </label>
            <ToggleButtonGroup
              options={days}
              onChange={departureDaysHandler}
              value={filterParams.departureDays}
              color="secondary"
              multiple
            />
          </div>
          <RadioGroup
            name="hasIntervalDepartures"
            defaultValue={0}
            onChange={onIntervalDepartureChange}
            options={[
              { label: <TransLabel i18nKey="departureDays" />, value: 0 },
              { label: <TransLabel i18nKey="departureIntervals" />, value: 1 },
            ]}
            inline
          />
          {filterByCompositionRelation && (
            <Checkbox
              name={'vehicleCompositionRelation'}
              label={<TransLabel i18nKey="hasCompositions" />}
              checked={
                filterParams.vehicleCompositionRelation === 'has_compositions'
              }
              onChange={({ target: { name, checked } }) =>
                setFilterParam({ [name]: checked ? 'has_compositions' : 'all' })
              }
              inline
            />
          )}
        </div>
      </div>

      {touched && (
        <div className="heading">
          <div className="heading-title">
            <TransLabel i18nKey="searchResults" />
          </div>
          <div className="connected-number">
            <TransLabel
              i18nKey="qtyTemplates"
              values={{
                count: lineTemplatesResult.totalCount,
              }}
            />
          </div>
        </div>
      )}
      {touched ? (
        noResults ? (
          <div className="empty-search-result">
            <img src={emptySearchImg} width="80" height="80" alt="" />
            <span>
              <TransLabel i18nKey="noLineTemplatesFound" />
            </span>
          </div>
        ) : (
          children({
            data: lineTemplatesResult.data!,
            offset: lineTemplatesResult.offset,
            loading: lineTemplatesResult.loading,
            totalCount: lineTemplatesResult.totalCount,
            onPageChange: searchLineTemplates,
          })
        )
      ) : null}
    </div>
  );
};
