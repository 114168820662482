import type { FC } from 'react';
import type { Config as FormConfig, FormApi } from 'final-form';
import { DragEvent, useCallback, useMemo } from 'react';
import { Button } from '@mui/material';
import {
  Icon,
  Modal,
  formSubmit,
  useForm,
  FormProvider,
  SelectField,
  TextField,
} from '@fleet/shared';
import { useSelector } from 'store/utils';
import { useAlert } from 'react-alert';
import { TransButton } from 'i18n/trans/button';
import { useModal } from '@fleet/shared/hooks';
import {
  TripVehicleAddPayload,
  TripVehicleReplacePayload,
} from 'features/composition/compositionActions';
import { TransMessage } from 'i18n/trans/message';
import { TransTitle } from 'i18n/trans/title';
import classNames from 'classnames';
import { TransLabel } from 'i18n/trans/label';
import { isDraggingFromPaletteSelector } from 'features/common/commonSelectors';
import { compositionConstructSelector } from 'features/composition/compositionSelectors';
import _range from 'lodash/range';
import { getOrdinalNumber } from 'features/utils';

interface TripDropAreaProps {
  onAddVehicle: (payload: TripVehicleAddPayload) => void;
  onReplaceVehicle: (payload: TripVehicleReplacePayload) => void;
}

interface TripDropAreaPayload {
  vehicleId: number;
  actionType?: 'add' | 'replace';
  carriageIdx: number;
  number: string;
}

export const TripDropArea: FC<TripDropAreaProps> = ({
  onAddVehicle,
  onReplaceVehicle,
}) => {
  const isDraggingFromPalette = useSelector(isDraggingFromPaletteSelector);
  const compositionConstruct = useSelector(compositionConstructSelector);

  const { open: isOpen, onOpen, onClose } = useModal();
  const handleCloseModal = useCallback(
    (form: FormApi<TripDropAreaPayload>) => {
      onClose();
      form.reset();
    },
    [onClose]
  );

  const alert = useAlert();
  const onSubmit = useCallback<FormConfig<TripDropAreaPayload>['onSubmit']>(
    ({ actionType, carriageIdx, vehicleId, number }, form) =>
      formSubmit(async () => {
        const orderNumber = carriageIdx + 1;
        switch (actionType) {
          case 'add': {
            await onAddVehicle({
              vehicleId,
              orderNumber,
              number,
            });
            handleCloseModal(form);
            break;
          }
          case 'replace': {
            await onReplaceVehicle({
              newVehicleId: vehicleId,
              tripVehicleCompositionVehicleToDelete:
                compositionConstruct!.compositionVehicles[carriageIdx].id,
              number,
            });
            handleCloseModal(form);
            break;
          }
        }
        alert.success(<TransMessage i18nKey="allChangesSaved" />);
      }),
    [
      alert,
      compositionConstruct,
      handleCloseModal,
      onAddVehicle,
      onReplaceVehicle,
    ]
  );

  const initialValues = useMemo(
    () => ({
      vehicleId: -1,
      carriageIdx: 0,
      number: '',
    }),
    []
  );
  const { form, handleSubmit, values, submitting } =
    useForm<TripDropAreaPayload>({
      initialValues,
      onSubmit,
      subscription: { values: true, submitting: true },
    });

  const onCloseModal = useCallback(
    () => handleCloseModal(form),
    [form, handleCloseModal]
  );

  const onDropHandler = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      const { actionType } = e.currentTarget
        .dataset as unknown as TripDropAreaPayload;
      const vehicleId = +e.dataTransfer.getData('text');
      form.batch(() => {
        form.change('actionType', actionType);
        form.change('vehicleId', vehicleId);
        form.change('carriageIdx', 0);
      });
      onOpen();
    },
    [form, onOpen]
  );
  const toggleDragAreaHoverClass = useCallback((e: DragEvent) => {
    const hasHoverClass = e.currentTarget.classList.contains('hover');
    if (
      (e.type === 'dragleave' && hasHoverClass) ||
      (e.type === 'dragenter' && !hasHoverClass)
    ) {
      e.currentTarget.classList.toggle('hover');
    }
  }, []);

  const vehiclesNumber = useMemo(
    () => compositionConstruct?.compositionVehicles?.length || 0,
    [compositionConstruct?.compositionVehicles]
  );
  const carriagePositionOptions = useMemo(() => {
    const { actionType } = values;
    if (!actionType) return [];
    return _range(0, vehiclesNumber + (actionType === 'add' ? 1 : 0)).map(
      (idx) => {
        return {
          label: getOrdinalNumber(idx + 1),
          value: idx,
        };
      }
    );
  }, [values, vehiclesNumber]);
  return (
    <>
      <div
        className={classNames('trip-drop-area', {
          active: isDraggingFromPalette,
        })}
      >
        {['add', 'replace'].map((option) => (
          <div
            key={option}
            data-action-type={option}
            className="drop-area-option"
            onDragEnter={toggleDragAreaHoverClass}
            onDragLeave={toggleDragAreaHoverClass}
            onDrop={onDropHandler}
          >
            <TransLabel
              i18nKey={
                option === 'add' ? 'addNewCarriage' : 'changeExistingCarriage'
              }
            />
          </div>
        ))}
      </div>

      <Modal
        open={isOpen}
        onClose={onCloseModal}
        title={<TransTitle i18nKey="addCarriage" />}
        actionButton={
          <form onSubmit={handleSubmit}>
            <Button
              type="submit"
              startIcon={<Icon name="check" size={16} />}
              variant="contained"
              disabled={submitting}
            >
              {values?.actionType && (
                <TransButton i18nKey={values.actionType} />
              )}
            </Button>
          </form>
        }
        maxWidth="xs"
      >
        <FormProvider {...form}>
          <SelectField
            name="carriageIdx"
            options={carriagePositionOptions}
            {...(values?.actionType && {
              label: <TransButton i18nKey={values.actionType} />,
            })}
            margin="normal"
            required
          />

          <TextField
            name="number"
            label={<TransLabel i18nKey="number" />}
            margin="normal"
            required
          />
        </FormProvider>
      </Modal>
    </>
  );
};
