import { FC, forwardRef, MouseEvent, PropsWithChildren } from 'react';
import { Button } from '@mui/material';
import {
  useActive,
  useRemirrorContext,
  useChainedCommands,
  useCommands,
  useHelpers,
} from '@remirror/react';
import { AnyExtension } from 'remirror';
import { ProsemirrorAttributes } from '@remirror/core';
import { ReactFrameworkOutput } from '@remirror/react-core/dist/declarations/src/react-types';
import { makeStyles } from '@mui/styles';

const preventBubblingUp = (event: MouseEvent): void => {
  event.preventDefault();
};

interface ToolbarButtonProps {
  key?: string | number;
  className?: string;
  /**
   * The name of the command to use.
   */
  commandName: Remirror.AllUiCommandNames;
  /**
   * The attributes to pass through when the command is called.
   */
  attrs?: ProsemirrorAttributes;
  /**
   * Whether to display the shortcut.
   *
   * @default true
   */
  displayShortcut?: boolean;
  onClick?: (
    event: MouseEvent<HTMLButtonElement>,
    context: ReactFrameworkOutput<Remirror.Extensions>
  ) => void;
}

export const createButton =
  (props: PropsWithChildren<ToolbarButtonProps>) => () =>
    <ToolbarButton {...props} />;

const useToolbarButtonLabelStyles = makeStyles(
  () => ({
    root: {
      lineHeight: '20px',
      fontWeight: 'bold',
      fontSize: 18,
      alignSelf: 'baseline',
    },
  }),
  {
    name: 'ToolbarButtonLabel',
  }
);
interface ToolbarButtonLabelProps {}
export const ToolbarButtonLabel: FC<ToolbarButtonLabelProps> = ({
  children,
}) => {
  const classes = useToolbarButtonLabelStyles();
  return <span className={classes.root}>{children}</span>;
};

export const ToolbarButton: FC<ToolbarButtonProps> = forwardRef<
  HTMLSpanElement,
  ToolbarButtonProps
>(({ commandName, attrs, children, onClick, ...props }, ref) => {
  const commands = useCommands();
  const chain = useChainedCommands();
  const { getCommandOptions } = useHelpers();

  // Will cause the editor to rerender for each state update.
  const active = useActive<AnyExtension>();
  const context = useRemirrorContext();

  const options = getCommandOptions(commandName);

  if (!options) {
    return <></>;
  }

  const enabled = commands[commandName]?.enabled(attrs) ?? false;
  let { name } = options;
  const isAlign = [
    'leftAlign',
    'centerAlign',
    'rightAlign',
    'justifyAlign',
  ].includes(commandName);
  if (isAlign) name = 'paragraph';
  const isActive = active[name]?.(attrs) ?? false;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onClick) {
      onClick(event, context);
      return;
    }
    if (options.disableChaining) {
      if (isAlign && isActive) {
        commands.setTextAlignment('none');
      } else commands[commandName]?.(attrs);
      return;
    }

    const chainCommand =
      isAlign && isActive
        ? chain.setTextAlignment('none')
        : chain[commandName]?.(attrs);

    chainCommand?.focus?.();
    chainCommand?.run();
  };

  return (
    <span ref={ref} {...props} onMouseDown={preventBubblingUp}>
      <Button
        type="button"
        children={children}
        onClick={handleClick}
        color={isActive ? 'primary' : 'inherit'}
        disabled={!enabled}
        size="small"
        sx={{ minWidth: 0 }}
      />
    </span>
  );
});
