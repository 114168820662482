import {
  createContext,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { Vehicle } from '@fleet/widget/dto/vehicle';
import { CompositionConstructDto } from 'dto/composition';
import { noop } from 'features/utils';
import _isEqual from 'lodash/isEqual';
import { useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { Icon, Tooltip } from '@fleet/shared/mui';
import { Box } from '@mui/material';
import { TransLabel } from 'i18n/trans/label';
import { Classifier } from '@fleet/shared/dto/classifier';

interface TabsProps {
  currentEntity?: Vehicle | CompositionConstructDto;
  basePath: string;
  onCurrentTabClose(): void;
  className?: string;
}

interface TabsContext {
  tabs: Classifier<number>[];
  updateTabs(payload: Classifier<number>[]): void;
  closeCurrentTab(): void;
}

const defaultValue: TabsContext = {
  tabs: [],
  updateTabs: noop,
  closeCurrentTab: noop,
};

export const TabsContext = createContext<TabsContext>(defaultValue);

export const Tabs: FC<TabsProps> = ({
  currentEntity,
  basePath,
  onCurrentTabClose,
  className,
  children,
}) => {
  const businessEntityId = useSelector(currentBusinessEntityIdSelector);
  const history = useHistory();
  const storageKey = `${basePath.substring(1)}Tabs`;
  const storageTabsStr = localStorage.getItem(storageKey);
  const storageTabs = useMemo(
    () => (storageTabsStr ? JSON.parse(storageTabsStr) : []),
    [storageTabsStr]
  );
  const [tabs, setTabs] = useState<Classifier<number>[]>(storageTabs);
  const [prevOrgId, setPrevOrgId] = useState<number>(businessEntityId);
  const updateTabs = useCallback(
    (updatedTabs) => {
      if (_isEqual(updatedTabs, tabs)) return;

      setTabs(updatedTabs);
      localStorage.setItem(storageKey, JSON.stringify(updatedTabs));
    },
    [storageKey, tabs]
  );

  useEffect(() => {
    if (businessEntityId !== prevOrgId) {
      setPrevOrgId(businessEntityId);
      updateTabs([]);
    }
  }, [businessEntityId, prevOrgId, updateTabs]);

  useEffect(() => {
    if (!currentEntity) return;
    const { id, name } = currentEntity;
    const existingTabIdx = tabs.map(({ id }) => id).indexOf(currentEntity.id);
    const tabNameChanged = tabs[existingTabIdx]?.name !== currentEntity.name;

    if (existingTabIdx === -1) {
      updateTabs([...tabs, { id, name }]);
    } else if (tabNameChanged) {
      updateTabs([
        ...tabs.slice(0, existingTabIdx),
        { id, name },
        ...tabs.slice(existingTabIdx + 1),
      ]);
    }
  }, [currentEntity, updateTabs, storageKey, tabs, storageTabs]);

  const homePath = `${basePath}/all`;

  const closeTab = useCallback(
    (id: number) => {
      updateTabs(tabs.filter((tab) => tab.id !== id));
    },
    [tabs, updateTabs]
  );

  const closeCurrentTab = useCallback(() => {
    const { id: currentTabId } = currentEntity!;
    onCurrentTabClose();
    closeTab(currentTabId);
    history.replace(homePath);
  }, [currentEntity, onCurrentTabClose, closeTab, history, homePath]);

  const tabsProviderValue: TabsContext = useMemo(
    () => ({
      tabs,
      updateTabs,
      closeCurrentTab,
    }),
    [closeCurrentTab, tabs, updateTabs]
  );
  return (
    <>
      <div className={classNames('tabs', className)}>
        <Tooltip content={<TransLabel i18nKey="home" />} delay={500}>
          <NavLink
            to={homePath}
            exact
            className={classNames('icon home', { active: !currentEntity })}
            data-id=""
          />
        </Tooltip>
        {tabs.map(({ id, name }) => (
          <Fragment key={id}>
            <Tooltip content={name} placement="bottom" delay={500}>
              <NavLink
                to={`${basePath}/${id}`}
                data-id={id}
                className="tab-name"
              >
                {name}
              </NavLink>
            </Tooltip>
            <Tooltip content={<TransLabel i18nKey="close" />} delay={500}>
              <Box sx={{ flexShrink: 0, margin: '0 16px 0 12px' }}>
                <Icon
                  name="close"
                  onClick={
                    currentEntity?.id === id
                      ? closeCurrentTab
                      : closeTab.bind(null, id)
                  }
                />
              </Box>
            </Tooltip>
            <span className="separator" />
          </Fragment>
        ))}
      </div>
      <TabsContext.Provider value={tabsProviderValue}>
        {children}
      </TabsContext.Provider>
    </>
  );
};
