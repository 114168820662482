import { selector } from 'store/utils';
import { ClassificationGroup } from 'dto/classification';
import { createSelector } from '@reduxjs/toolkit';
import { InventoryType } from 'dto/organization';
import { compositionsSelector } from 'features/composition/compositionSelectors';
import _uniq from 'lodash/uniq';
import { WEEK_DAYS } from 'features/utils';
import { makeClassificationOptions } from '@fleet/shared';

export const businessEntitiesSelector = selector(
  (state) => state.classification[ClassificationGroup.BUSINESS_ENTITY]
);
export const inventoryClassesSelector = selector(
  (state) => state.classification[ClassificationGroup.INVENTORY_CLASS]
);
export const inventoryClassesOptionsSelector = createSelector(
  inventoryClassesSelector,
  (inventoryClasses) =>
    makeClassificationOptions(
      inventoryClasses.filter(
        ({ inventoryType }) => inventoryType.id === InventoryType.SEAT
      )
    )
);

export const inventoryClassCompartmentBedOptionsSelector = createSelector(
  inventoryClassesSelector,
  (inventoryClasses) =>
    makeClassificationOptions(
      inventoryClasses.filter(({ inventoryType }) =>
        [InventoryType.COMPARTMENT, InventoryType.BED].includes(
          inventoryType.id
        )
      )
    )
);

export const inventoryBlockingReasonsSelector = selector(
  (state) => state.classification[ClassificationGroup.INVENTORY_BLOCKING_REASON]
);
export const linesSelector = selector(
  (state) => state.classification[ClassificationGroup.LINE]
);
export const subContractorsSelector = selector(
  (state) => state.classification[ClassificationGroup.SUB_CONTRACTOR]
);
export const serviceCodesSelector = selector(
  (state) => state.classification.SERVICE_CODE
);
export const serviceCodeOptionsSelector = createSelector(
  serviceCodesSelector,
  (codes) =>
    codes.map(({ id, code }) => ({
      value: `${id}`,
      label: code,
    }))
);

export const floorElementsSelector = selector(
  (state) => state.classification.FLOOR_ELEMENT
);
export const classifiersSelector = selector((state) => state.classification);
export const classifiersCultureMapSelector = selector(
  (state) =>
    new Map(state.classification.CULTURE.map(({ id, name }) => [id, name]))
);
export const classifierSubTypeMapSelector = createSelector(
  classifiersSelector,
  ({ SIGN_SUB_TYPE, INTERNAL_SUB_TYPE, PLACE_SUB_TYPE }) =>
    new Map(
      [...SIGN_SUB_TYPE, ...INTERNAL_SUB_TYPE, ...PLACE_SUB_TYPE].map(
        ({ id, name, localizations }) => [id, { name, localizations }]
      )
    )
);
export const assignEntityFilterOptionsSelector = createSelector(
  [
    linesSelector,
    subContractorsSelector,
    serviceCodesSelector,
    compositionsSelector,
    classifiersSelector,
  ],
  (
    lines,
    subContractors,
    serviceCodes,
    vehicleCompositions,
    { TRIP_STATUS, VEHICLE_COMPOSITION_DIRECTION }
  ) => {
    return {
      lines: lines.map(({ number, name, id }) => ({
        label: `${number} - ${name}`,
        value: id,
      })),
      vehicleCompositions: vehicleCompositions.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
      serviceCodes: _uniq(serviceCodes.map(({ code }) => code))
        .sort()
        .map((code) => ({
          label: code,
          value: code,
        })),
      subContractors: subContractors.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
      tripStatuses: TRIP_STATUS
        ? TRIP_STATUS.map(({ name, id }) => ({
            label: name,
            value: id,
          }))
        : [],
      vehicleCompositionDirection: VEHICLE_COMPOSITION_DIRECTION.map(
        ({ id }) => ({
          value: id,
          label: '', // TODO,
          icon:
            id === 'VEHICLE_COMPOSITION_DIRECTION.FORWARD'
              ? 'direction-top'
              : 'direction-bottom',
        })
      ),
      days: WEEK_DAYS.map((value) => ({
        value,
        label: value,
      })),
    };
  }
);
