import {
  createButton,
  ToolbarButton,
  ToolbarButtonLabel,
} from 'components/common/editor/createButton';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatCodeIcon from '@mui/icons-material/Code';
//
export const FormatBoldButton = createButton({
  commandName: 'toggleBold',
  children: <FormatBoldIcon />,
});
export const FormatItalicButton = createButton({
  commandName: 'toggleItalic',
  children: <FormatItalicIcon />,
});
export const FormatStrikeButton = createButton({
  commandName: 'toggleStrike',
  children: <FormatStrikethroughIcon />,
});
export const FormatUnderlinedButton = createButton({
  commandName: 'toggleUnderline',
  children: <FormatUnderlinedIcon />,
});

export const FormatHeadingButtons = () => (
  <>
    {[1, 2, 3, 4, 5, 6].map((level) => (
      <ToolbarButton key={level} commandName="toggleHeading" attrs={{ level }}>
        <ToolbarButtonLabel>H{level}</ToolbarButtonLabel>
      </ToolbarButton>
    ))}
  </>
);

export { FormatLinkButton } from 'components/common/editor/buttons/FormatLinkButton';
export { InsertImageButton } from 'components/common/editor/buttons/InsertImageButton';

export const FormatAlignLeftButton = createButton({
  commandName: 'leftAlign',
  children: <FormatAlignLeftIcon />,
  attrs: {
    nodeTextAlignment: 'left',
  },
});
export const FormatAlignCenterButton = createButton({
  commandName: 'centerAlign',
  children: <FormatAlignCenterIcon />,
  attrs: {
    nodeTextAlignment: 'center',
  },
});
export const FormatAlignRightButton = createButton({
  commandName: 'rightAlign',
  children: <FormatAlignRightIcon />,
  attrs: {
    nodeTextAlignment: 'right',
  },
});
export const FormatListBulletedButton = createButton({
  commandName: 'toggleBulletList',
  children: <FormatListBulletedIcon />,
});
export const FormatListNumberedButton = createButton({
  commandName: 'toggleOrderedList',
  children: <FormatListNumberedIcon />,
});
export const FormatQuoteButton = createButton({
  commandName: 'toggleBlockquote',
  children: <FormatQuoteIcon />,
});
export const FormatCodeButton = createButton({
  commandName: 'toggleCode',
  children: <FormatCodeIcon />,
});
