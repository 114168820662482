import { FC, useCallback, useMemo, useState } from 'react';
import { LineTemplateDto } from 'dto/lineTemplate';
import { Table, TableProps } from 'components/common/table/Table';
import { Column } from 'react-table';
import { formatDate } from '@fleet/shared/utils/date';
import _partition from 'lodash/partition';
import { sortWeekDays } from 'features/utils';
import { ExternalLink, Tooltip } from '@fleet/shared/mui';
import _isEqual from 'lodash/isEqual';
import { TransLabel } from 'i18n/trans/label';

export interface LineTemplatesTableProps
  extends Omit<TableProps<LineTemplateDto>, 'columns'> {
  vehicleConstructId?: number;
  selectableRows?: boolean;
}

export const LineTemplatesTable: FC<LineTemplatesTableProps> = ({
  vehicleConstructId,
  loading,
  data = [],
  controlsAccessor,
  totalCount,
  onPageChange,
  renderRowSubComponent,
  page,
  initialExpanded,
  selectableRows,
  ...props
}) => {
  const [selectedRows, _setSelectedRows] = useState<LineTemplateDto[]>([]);
  const setSelectedRows = useCallback(
    (selected: LineTemplateDto[]) => {
      !_isEqual(selected, selectedRows) && _setSelectedRows(selected);
    },
    [selectedRows]
  );
  const connectedVehiclesColumn: Array<Column<LineTemplateDto>> =
    useMemo(() => {
      if (vehicleConstructId) return [];
      return [
        {
          Header: <TransLabel i18nKey="vehicleCompositionsConnected" />,
          id: 'connectedVehicles',
          accessor: ({ vehicleCompositions }) => {
            const content = vehicleCompositions
              .map(({ name, code }) => [name, code].filter(Boolean).join('/'))
              .join(', ');

            return (
              <Tooltip
                content={content}
                placement="bottom-start"
                delay={[300, 0]}
              >
                <span>{content}</span>
              </Tooltip>
            );
          },
          disableSortBy: true,
        },
      ];
    }, [vehicleConstructId]);
  const controlColumn = useMemo(() => {
    if (!controlsAccessor) return [];
    const column = {
      id: 'controls',
      className: 'row-controls',
      disableSortBy: true,
      accessor: (lineTemplate: LineTemplateDto) =>
        controlsAccessor([lineTemplate]),
    };

    if (vehicleConstructId) return [column];

    return [
      {
        ...column,
        Header: () =>
          selectedRows.length ? controlsAccessor(selectedRows) : <span />,
        accessor: selectedRows.length
          ? undefined
          : (lineTemplate: LineTemplateDto) => controlsAccessor([lineTemplate]),
      },
    ];
  }, [controlsAccessor, selectedRows, vehicleConstructId]);

  const columns: Array<Column<LineTemplateDto>> = useMemo(() => {
    return [
      {
        Header: <TransLabel i18nKey="name" />,
        id: 'name',
        accessor: ({ name, id }) => (
          <Tooltip content={name} placement="bottom-start" delay={[300, 0]}>
            <span>
              <ExternalLink
                path={`/BusLines/BusLineTemplate/Edit/${id}`}
                content={name}
              />
            </span>
          </Tooltip>
        ),
        className: 'line-template-name-col',
        disableSortBy: true,
      },
      {
        Header: <TransLabel i18nKey="serviceCode" />,
        id: 'serviceCodes',
        accessor: ({ serviceCodes }) => (
          <Tooltip
            content={serviceCodes.join(', ')}
            placement="bottom-start"
            delay={[300, 0]}
          >
            <span>{serviceCodes.join(', ')}</span>
          </Tooltip>
        ),
        disableSortBy: true,
      },
      {
        Header: <TransLabel i18nKey="lineNumber" />,
        accessor: 'lineNumber',
        disableSortBy: true,
      },
      {
        Header: <TransLabel i18nKey="subContractor" />,
        id: 'subContractor',
        accessor: ({ subContractor }) =>
          subContractor || <TransLabel i18nKey="noSubContractor" />,
        disableSortBy: true,
      },
      {
        Header: (
          <TransLabel
            i18nKey={
              vehicleConstructId ? 'connectionStartDate' : 'templateStartDate'
            }
          />
        ),
        id: 'startDate',
        accessor: ({ startDate, vehicleCompositions }) => {
          const dateToShow = vehicleConstructId
            ? vehicleCompositions.find(({ id }) => id === vehicleConstructId)!
                .startDate
            : startDate;
          return formatDate(dateToShow);
        },
        disableSortBy: true,
      },
      {
        Header: (
          <TransLabel
            i18nKey={
              vehicleConstructId ? 'connectionEndDate' : 'templateEndDate'
            }
          />
        ),
        id: 'endDate',
        accessor: ({ endDate, vehicleCompositions }) => {
          const dateToShow = vehicleConstructId
            ? vehicleCompositions.find(({ id }) => id === vehicleConstructId)!
                .endDate
            : endDate;
          return formatDate(dateToShow);
        },
        disableSortBy: true,
      },
      {
        id: 'departureDays',
        Header: <TransLabel i18nKey="departureDays" />,
        accessor: ({ departureTimes, vehicleCompositions }) => {
          const currentVehicleCompositions = vehicleConstructId
            ? vehicleCompositions.filter(({ id }) => id === vehicleConstructId)
            : vehicleCompositions;
          const templateDepartureTimes = departureTimes
            .map(({ departureDay }) => departureDay)
            .join(', ');
          const [timesFromTemplate, customTimes] = _partition(
            currentVehicleCompositions,
            ({ departureTimesFromTemplate }) => departureTimesFromTemplate
          );
          const showTemplateTimes = Boolean(
            !vehicleConstructId ||
              timesFromTemplate.length ||
              !vehicleCompositions.length
          );
          if (showTemplateTimes) {
            return templateDepartureTimes;
          } else {
            const compositionsDays = customTimes.reduce(
              (acc, { departureTimes }) => [
                ...acc,
                ...departureTimes.map(({ departureDay }) => departureDay),
              ],
              [] as string[]
            );
            return sortWeekDays(compositionsDays).join(', ');
          }
        },
        disableSortBy: true,
      },
      ...connectedVehiclesColumn,
      ...controlColumn,
    ];
  }, [connectedVehiclesColumn, controlColumn, vehicleConstructId]);

  return (
    <Table<LineTemplateDto>
      className="composition-relations-table"
      columns={columns}
      loading={loading}
      data={data}
      totalCount={totalCount}
      onPageChange={onPageChange}
      page={page}
      renderRowSubComponent={renderRowSubComponent}
      initialExpanded={initialExpanded}
      {...(selectableRows ? { onSelectedRowsChange: setSelectedRows } : {})}
      {...props}
    />
  );
};
