import { FC, useCallback, useMemo } from 'react';
import { Row } from 'react-table';
import {
  Button,
  Icon,
  ReadOnlyField as Field,
  Tooltip,
} from '@fleet/shared/mui';
import { TripDto, TripVehicleComposition } from 'dto/trip';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { useSelector } from 'store/utils';
import { classifiersSelector } from 'features/classification/classificationSelectors';
import { TransButton } from 'i18n/trans/button';

// TODO clean up repeated styles
export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      whiteSpace: 'normal',
    },
    card: {
      width: '50%',
      padding: '16px 24px',
      '&:first-of-type': {
        background: 'var(--bg-light)',
      },
      '&:last-of-type': {
        background: 'var(--bg)',
      },
    },
    controlPopup: {
      borderRadius: 3,
      display: 'flex',
      flexDirection: 'column',

      '& .MuiButton-text': {
        color: theme.palette.text.primary,
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        padding: '6px 4px',
        '&:hover': {
          color: theme.palette.action.hover,
        },
      },
    },
    dots: {
      position: 'absolute',
      right: 12,
    },
    emptyControl: {
      position: 'absolute',
      top: 20,
      right: 20,

      '& .MuiButton-text': {
        paddingTop: 0,
      },
    },
    infoFields: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
  {
    name: 'LineTemplateDetails',
  }
);

interface TripDetailsProps extends Row<TripDto> {
  selectedRows?: TripDto[];
  vehicleConstructId?: number;
  getModalHandler?: (params: {
    trips: TripDto[];
    vehicleComposition: Partial<TripVehicleComposition>;
    action: string;
  }) => () => void;
}

export const TripDetails: FC<TripDetailsProps> = ({
  vehicleConstructId,
  getModalHandler,
  selectedRows,
  ...row
}) => {
  const classes = useStyles();
  const {
    name,
    subContractor,
    serviceCode,
    lineNumber,
    departureDateTime,
    vehicleCompositions,
  } = row.original;
  const getFormattedDate = useCallback(
    (dateStr) => formatDate(dateStr, currentDateTimeFormat),
    []
  );
  const preparedConnectedVehicles = useMemo(() => {
    return vehicleConstructId
      ? vehicleCompositions.filter(
          ({ originatesFromVehicleCompositionId }) =>
            originatesFromVehicleCompositionId === vehicleConstructId
        )
      : vehicleCompositions;
  }, [vehicleCompositions, vehicleConstructId]);
  const classifiers = useSelector(classifiersSelector);

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <h5>
          <TransTitle i18nKey="tripInformation" />
        </h5>
        <div className={classes.infoFields}>
          <Field label={<TransLabel i18nKey="tripName" />} value={name} />
          <Field
            label={<TransLabel i18nKey="serviceCode" />}
            value={serviceCode}
          />
          <Field
            label={<TransLabel i18nKey="lineNumber" />}
            value={lineNumber}
          />
          <Field
            label={<TransLabel i18nKey="subContractor" />}
            value={subContractor || <TransLabel i18nKey="noSubContractor" />}
          />
          <Field
            label={<TransLabel i18nKey="departureDateTime" />}
            value={getFormattedDate(departureDateTime)}
          />
        </div>
      </div>
      <div className={classes.card}>
        <h5>
          <TransTitle i18nKey="connectedVehicleCompositionInformation" />
        </h5>
        {preparedConnectedVehicles.map((composition) => (
          <div
            className={classes.infoFields}
            key={composition.tripVehicleCompositionId}
          >
            <Field
              label={<TransLabel i18nKey="vehicle" />}
              value={composition.name}
            />
            <Field
              label={<TransLabel i18nKey="code" />}
              value={composition.code}
            />
            <Field
              label={<TransLabel i18nKey="direction" />}
              value={
                classifiers.VEHICLE_COMPOSITION_DIRECTION.find(
                  ({ id }) => id === composition.vehicleCompositionDirectionId
                )?.name
              }
            />
            {getModalHandler && (
              <>
                <Tooltip
                  theme="light"
                  placement="bottom-end"
                  content={
                    <Box className={classes.controlPopup}>
                      <Button
                        variant="text"
                        startIcon={<Icon name="edit" />}
                        label={
                          <Link
                            to={{
                              pathname: `/manage/trip/${row.original.id}`,
                              state: { trip: row.original },
                              search: `compositionId=${composition.tripVehicleCompositionId}`,
                            }}
                          >
                            <TransButton i18nKey="manage" />
                          </Link>
                        }
                      />
                      <Button
                        variant="text"
                        startIcon={<Icon name="replace" />}
                        onClick={getModalHandler!({
                          trips: [row.original],
                          vehicleComposition: composition,
                          action: 'replace',
                        })}
                        label={<TransButton i18nKey="update" />}
                      />
                      {!!selectedRows?.length && (
                        <Button
                          onClick={getModalHandler!({
                            trips: selectedRows!,
                            vehicleComposition: composition,
                            action: 'copyComposition',
                          })}
                          variant="text"
                          startIcon={<Icon name="clone" />}
                          label={<TransButton i18nKey="copy" />}
                        />
                      )}
                      <Button
                        variant="text"
                        startIcon={<Icon name="delete" />}
                        onClick={getModalHandler!({
                          trips: [row.original],
                          vehicleComposition: composition,
                          action: 'delete',
                        })}
                        label={<TransButton i18nKey="delete" />}
                      />
                    </Box>
                  }
                >
                  <Icon
                    className={classes.dots}
                    name="dots"
                    height={20}
                    width={20}
                  />
                </Tooltip>
              </>
            )}
          </div>
        ))}
        {!preparedConnectedVehicles.length && (
          <div>
            <TransTitle i18nKey="noVehicleConnections" />

            {getModalHandler && (
              <Box className={classes.emptyControl}>
                <Button
                  variant="text"
                  startIcon={<Icon name="plus" />}
                  onClick={getModalHandler!({
                    trips: [row.original],
                    vehicleComposition: {},
                    action: 'addComposition',
                  })}
                  label={
                    <Typography variant="body2">
                      <TransLabel i18nKey="addComposition" />
                    </Typography>
                  }
                />
              </Box>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
