import { FC, useCallback, useMemo, useState } from 'react';
import { Table, TableProps } from 'components/common/table/Table';
import { Column } from 'react-table';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { TripDto } from 'dto/trip';
import _isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ExternalLink, Tooltip } from '@fleet/shared/mui';
import { TransLabel } from 'i18n/trans/label';

export interface TripTableProps extends Omit<TableProps<TripDto>, 'columns'> {
  vehicleConstructId?: number;
  selectableRows?: boolean;
}

export const TripsTable: FC<TripTableProps> = ({
  className,
  vehicleConstructId,
  loading,
  data = [],
  controlsAccessor,
  totalCount,
  onPageChange,
  renderRowSubComponent,
  selectableRows,
  page,
  initialExpanded,
  ...props
}) => {
  const [selectedRows, _setSelectedRows] = useState<TripDto[]>([]);
  const setSelectedRows = useCallback(
    (selected: TripDto[]) => {
      !_isEqual(selected, selectedRows) && _setSelectedRows(selected);
    },
    [selectedRows]
  );
  const connectedVehiclesColumn: Array<Column<TripDto>> = useMemo(() => {
    if (vehicleConstructId) return [];
    return [
      {
        Header: <TransLabel i18nKey="vehicleCompositionsConnected" />,
        id: 'connectedVehicles',
        accessor: ({ vehicleCompositions }) => {
          const content = vehicleCompositions
            .map(({ name, code }) => [name, code].filter(Boolean).join('/'))
            .join(', ');

          return (
            <Tooltip
              content={content}
              placement="bottom-start"
              delay={[300, 0]}
            >
              <span>{content}</span>
            </Tooltip>
          );
        },
        disableSortBy: true,
      },
    ];
  }, [vehicleConstructId]);
  const controlColumn = useMemo(() => {
    if (!controlsAccessor) return [];
    const column = {
      id: 'controls',
      className: 'row-controls',
      disableSortBy: true,
      accessor: (trip: TripDto) => controlsAccessor([trip]),
    };

    if (vehicleConstructId) return [column];

    return [
      {
        ...column,
        Header: () =>
          selectedRows.length ? controlsAccessor(selectedRows) : null,
        accessor: selectedRows.length
          ? undefined
          : (trip: TripDto) => controlsAccessor([trip]),
      },
    ];
  }, [controlsAccessor, selectedRows, vehicleConstructId]);
  const columns: Array<Column<TripDto>> = useMemo(() => {
    return [
      {
        Header: <TransLabel i18nKey="departureDateTime" />,
        id: 'departureDateTime',
        accessor: ({ departureDateTime, id }) => (
          <ExternalLink
            path={`/Trips/Trip/Edit/${id}`}
            content={formatDate(departureDateTime, currentDateTimeFormat)}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: <TransLabel i18nKey="name" />,
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: <TransLabel i18nKey="serviceCode" />,
        accessor: 'serviceCode',
        disableSortBy: true,
      },
      {
        Header: <TransLabel i18nKey="lineNumber" />,
        accessor: 'lineNumber',
        disableSortBy: true,
      },
      {
        Header: <TransLabel i18nKey="subContractor" />,
        id: 'subContractor',
        accessor: ({ subContractor }) =>
          subContractor || <TransLabel i18nKey="noSubContractor" />,
        disableSortBy: true,
      },
      ...connectedVehiclesColumn,
      ...controlColumn,
    ];
  }, [connectedVehiclesColumn, controlColumn]);

  return (
    <>
      <Table<TripDto>
        className={classNames('composition-relations-table', className)}
        columns={columns}
        loading={loading}
        data={data}
        totalCount={totalCount}
        onPageChange={onPageChange}
        page={page}
        renderRowSubComponent={(row) =>
          renderRowSubComponent(row, selectedRows)
        }
        initialExpanded={initialExpanded}
        {...(selectableRows ? { onSelectedRowsChange: setSelectedRows } : {})}
        {...props}
      />
    </>
  );
};
