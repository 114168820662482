import type { FC } from 'react';
import type { FloorElement } from '@fleet/widget/dto/floor';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InventoryClassSelect } from 'components/common/select/InventoryClass';
import { Button } from '@fleet/shared/mui';
import { useAlert } from 'react-alert';
import type { CompositionElements } from 'features/composition/compositionActions';
import _pickBy from 'lodash/pickBy';
import { TransMessage } from 'i18n/trans/message';
import { TransTitle } from 'i18n/trans/title';
import { TransLabel } from 'i18n/trans/label';
import { SeatRankSelect } from 'components/common/SeatRankSelect';
import { useInventory } from 'hooks/useInventory';
import { InventoryBlockingLevel, InventoryType } from 'dto/organization';
import { InventoryBlockingReasonSelect } from 'components/common/select/InventoryBlockingReason';
import { Typography } from '@mui/material';
import {
  initialFilterState,
  SeatsFilter,
  useSeatsFilter,
} from 'routes/designer/SeatsFilter';
import _isEqual from 'lodash/isEqual';
import { TransButton } from 'i18n/trans/button';

interface EditParams {
  rank?: number;
  inventoryClassId?: string;
  isBlocked: boolean | 'indeterminate';
  blockingReasonId?: string;
}

const initialEditState: EditParams = {
  isBlocked: false,
};

export interface PlacePropertiesEditProps {
  filteredPlaces: Array<FloorElement>;
  setFilteredPlaces(elements: Array<FloorElement>): void;
  updatePlaceProperties?: (elements: CompositionElements) => Promise<void>;
}

export const PlacePropertiesEdit: FC<PlacePropertiesEditProps> = ({
  filteredPlaces,
  setFilteredPlaces,
  updatePlaceProperties,
}) => {
  const alert = useAlert();
  const seatsFilter = useSeatsFilter({
    setFilteredPlaces,
  });

  const [editState, _setEditProperties] =
    useState<EditParams>(initialEditState);
  const setEditProperties = useCallback((payload: Partial<EditParams>) => {
    _setEditProperties((old) => ({ ...old, ...payload }));
  }, []);
  const nodesInventoryClass = useInventory(filteredPlaces);

  const {
    isActive: seatsFilterActive,
    toggleActive: toggleSeatsFilterActive,
    seatsFilterHandler,
  } = seatsFilter;
  const toggleBulkEdit = useCallback(() => {
    toggleSeatsFilterActive();
    setFilteredPlaces([]);
    !seatsFilterActive && seatsFilterHandler(initialFilterState);
  }, [
    seatsFilterActive,
    seatsFilterHandler,
    setFilteredPlaces,
    toggleSeatsFilterActive,
  ]);

  const shouldResetEditState = useMemo(
    () => !filteredPlaces.length && !_isEqual(initialEditState, editState),
    [editState, filteredPlaces.length]
  );

  useEffect(() => {
    if (!seatsFilterActive && shouldResetEditState) {
      setEditProperties(initialEditState);
    }
  }, [seatsFilterActive, setEditProperties, shouldResetEditState]);

  useEffect(() => {
    if (!filteredPlaces.length) return;

    const [{ category }] = filteredPlaces;
    if (filteredPlaces.length === 1 || category === 'compartment') {
      const [{ inventoryClass, isBlocked, blockingReason, rank }] =
        filteredPlaces;

      _setEditProperties({
        inventoryClassId: inventoryClass?.id,
        isBlocked: isBlocked ?? 'indeterminate',
        blockingReasonId: blockingReason?.id,
        rank,
      });
    } else {
      _setEditProperties({
        inventoryClassId: nodesInventoryClass?.inventoryClassId,
        isBlocked: 'indeterminate',
        blockingReasonId: nodesInventoryClass?.blockingReasonId,
        rank: nodesInventoryClass?.rank,
      });
    }
  }, [filteredPlaces, nodesInventoryClass, setEditProperties]);

  const [selected] = filteredPlaces;
  const compartmentSelected = selected?.category === 'compartment';
  const placeSelected = selected?.category === 'place';

  const saveChanges = useCallback(async () => {
    const props = _pickBy(
      editState,
      (val) => val !== 'indeterminate'
    ) as Partial<Required<CompositionElements>['places'][0]>;

    const [selected] = filteredPlaces;

    const getUpdatedFloorElement = (floorElement: FloorElement) => ({
      id: floorElement.id,
      inventoryClassId: floorElement.inventoryClass?.id,
      isBlocked: floorElement.isBlocked,
      blockingReasonId: floorElement.blockingReason?.id,
      ...props,
    });

    await updatePlaceProperties?.({
      ...(compartmentSelected
        ? {
            compartments: [getUpdatedFloorElement(selected)],
          }
        : {
            places: filteredPlaces.map(getUpdatedFloorElement),
          }),
    });
    alert.success(<TransMessage i18nKey="allChangesSaved" />);
    setFilteredPlaces([]);
    if (seatsFilterActive) toggleBulkEdit();
  }, [
    editState,
    filteredPlaces,
    updatePlaceProperties,
    compartmentSelected,
    alert,
    setFilteredPlaces,
    seatsFilterActive,
    toggleBulkEdit,
  ]);

  if (!seatsFilterActive && !filteredPlaces.length) return null;

  return (
    <div className="place-properties-edit">
      <SeatsFilter
        filter={seatsFilter}
        toggleLabel={(active) => (
          <TransTitle
            i18nKey={`${active ? 'closeBulkEditor' : 'openBulkEditor'}`}
          />
        )}
        filteredPlaces={filteredPlaces}
      />

      {placeSelected && (
        <>
          <SeatRankSelect
            label={
              <Typography variant="subtitle">
                <TransTitle i18nKey="chooseRank" />
              </Typography>
            }
            value={editState?.rank ?? null}
            onChange={(rank) => setEditProperties({ rank })}
            margin="normal"
          />
          <InventoryClassSelect
            label={
              <Typography variant="subtitle">
                <TransTitle i18nKey="class" />
              </Typography>
            }
            value={editState?.inventoryClassId ?? null}
            onChange={(inventoryClassId) =>
              setEditProperties({ inventoryClassId })
            }
            inventoryType={nodesInventoryClass?.type}
            margin="dense"
          />
        </>
      )}
      {compartmentSelected && (
        <InventoryClassSelect
          label={
            <Typography variant="subtitle">
              <TransTitle i18nKey="class" />
            </Typography>
          }
          value={selected?.inventoryClass?.id ?? null}
          onChange={(inventoryClassId) =>
            setEditProperties({ inventoryClassId })
          }
          inventoryType={InventoryType.COMPARTMENT}
          margin="dense"
          showEmptyOption
        />
      )}
      <InventoryBlockingReasonSelect
        label={
          <Typography variant="subtitle">
            <TransLabel i18nKey="property.blockingReason" />
          </Typography>
        }
        level={
          compartmentSelected
            ? InventoryBlockingLevel.COMPARTMENT
            : InventoryBlockingLevel.PLACE
        }
        value={editState?.blockingReasonId ?? null}
        onChange={(blockedReason) =>
          setEditProperties({
            isBlocked: Boolean(blockedReason),
            blockingReasonId: blockedReason,
          })
        }
      />
      {seatsFilterActive ? (
        <div className="bulk-edit-controls">
          <Button
            variant="text"
            label={<TransButton i18nKey="cancel" />}
            onClick={toggleBulkEdit}
          />
          <Button
            label={<TransButton i18nKey="save" />}
            onClickAsync={saveChanges}
            disabled={!filteredPlaces.length}
          />
        </div>
      ) : (
        <div className="bulk-edit-controls">
          <Button
            variant="text"
            label={<TransButton i18nKey="cancel" />}
            onClick={() => setFilteredPlaces([])}
          />
          <Button
            label={<TransButton i18nKey="save" />}
            onClick={saveChanges}
          />
        </div>
      )}
    </div>
  );
};
