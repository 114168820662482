import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  allocatableInventoryUpperLimit: (
    <Trans
      i18nKey="table.head.allocatableInventoryUpperLimit"
      defaults="Allocatable inventory upper limit"
    />
  ),
  daysBeforeDeparture: (
    <Trans
      i18nKey="table.head.daysBeforeDeparture"
      defaults="Days before departure"
    />
  ),
  exceptionDates: (
    <Trans i18nKey="table.head.exceptionDates" defaults="Exception dates" />
  ),
  inventoryClass: (
    <Trans i18nKey="table.head.inventoryClass" defaults="Inventory class" />
  ),
  isException: (
    <Trans i18nKey="table.head.isException" defaults="Is exception" />
  ),
  genderizationRule: (
    <Trans
      i18nKey="table.head.genderizationRule"
      defaults="Genderization rule"
    />
  ),
  serviceCodes: (
    <Trans i18nKey="table.head.serviceCodes" defaults="Service codes" />
  ),
  siAllocationRuleset: (
    <Trans
      i18nKey="table.head.siAllocationRuleset"
      defaults="Sleeper inventory allocation rulesets"
    />
  ),
  validity: <Trans i18nKey="table.head.validity" defaults="Validity" />,
});
