import { FC, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import {
  FormProvider,
  useForm,
  SearchForm,
  formSubmit,
  SelectField,
  RadioGroupField,
  PeriodField,
} from '@fleet/shared';
import { Button, FormControl } from '@fleet/shared/mui';
import { Grid, Stack } from '@mui/material';
import { TransLabel } from 'i18n/trans/label';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { SiServiceAllocationRulesetQueryParams } from 'dto/siServiceAllocationRuleset';
import { useDispatch, useSelector } from 'store/utils';
import { getSiServiceAllocationRulesets } from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetActions';
import { selectSiServiceAllocationRulesetsFilter } from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetSelectors';
import { siAllocationRulesetOptionsSelector } from 'features/siAllocationRuleset/siAllocationRulesetSelectors';
import { serviceCodeOptionsSelector } from 'features/classification/classificationSelectors';

const useStyles = makeStyles(
  () => ({
    root: {},
  }),
  {
    name: 'SiServiceAllocationRulesetSearchForm',
  }
);

interface SiServiceAllocationRulesetSearchFormProps {}

export const SiServiceAllocationRulesetSearchForm: FC<SiServiceAllocationRulesetSearchFormProps> =
  () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector(selectSiServiceAllocationRulesetsFilter);

    const onSubmit = useCallback(
      (values: SiServiceAllocationRulesetQueryParams) =>
        formSubmit(async () => {
          (document.activeElement as HTMLInputElement)?.blur?.();
          await dispatch(getSiServiceAllocationRulesets(values));
        }),
      [dispatch]
    );
    const { form, handleSubmit, dirty } =
      useForm<SiServiceAllocationRulesetQueryParams>({
        onSubmit,
        initialValues: filter,
        subscription: { dirty: true },
      });
    const handleReset = useCallback(() => {
      form.reset({});
      dirty && form.submit();
    }, [form, dirty]);

    const siAllocationRulesetOptions = useSelector(
      siAllocationRulesetOptionsSelector
    );
    const serviceCodeOptions = useSelector(serviceCodeOptionsSelector);

    return (
      <SearchForm
        title={<TransSubtitle i18nKey="search" />}
        className={classes.root}
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <Grid container columns={4} spacing={2}>
              <Grid item xs={1}>
                <SelectField
                  name="sleeperInventoryAllocationRulesetId"
                  label={
                    <TransLabel i18nKey="sleeperInventoryAllocationRuleset" />
                  }
                  options={siAllocationRulesetOptions}
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="serviceCodes"
                  label={<TransLabel i18nKey="serviceCodes" />}
                  options={serviceCodeOptions}
                  multiple
                />
              </Grid>
              <PeriodField
                from={{
                  name: 'validityFrom.from',
                  label: <TransLabel i18nKey="validFrom" />,
                  isClearable: true,
                  popperProps: { strategy: 'fixed' },
                }}
                to={{
                  name: 'validityFrom.to',
                  label: null,
                  isClearable: true,
                  popperProps: { strategy: 'fixed' },
                }}
              />
              <PeriodField
                from={{
                  name: 'validityTo.from',
                  label: <TransLabel i18nKey="validTo" />,
                  isClearable: true,
                  popperProps: { strategy: 'fixed' },
                }}
                to={{
                  name: 'validityTo.to',
                  label: null,
                  isClearable: true,
                  popperProps: { strategy: 'fixed' },
                }}
              />

              <Grid item xs={1}>
                <RadioGroupField
                  name="isException"
                  label={<TransLabel i18nKey="isException" />}
                  options="BOOL"
                  inline
                />
              </Grid>

              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={2}>
                  <FormControl label="&nbsp;">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      onClick={handleReset}
                    >
                      <TransButton i18nKey="resetFilters" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button variant="contained" type="submit" icon="search">
                      <TransButton i18nKey="search" />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </SearchForm>
    );
  };
