import { DragEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getCompositions } from 'features/composition/compositionActions';
import { useDispatch, useSelector } from 'store/utils';
import { compositionsSelector } from 'features/composition/compositionSelectors';
import { useTranslation } from 'react-i18next';
import { Input, Tooltip } from '@fleet/shared/mui';
import { TransTitle } from 'i18n/trans/title';

export const CompositionsPanel = () => {
  const [searchStr, setSearchStr] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompositions());
  }, [dispatch]);
  const vehicleCompositions = useSelector(compositionsSelector);
  const handleDragStart = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('text', event.currentTarget.id);
  }, []);

  const handleDragEnd = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.dataTransfer.clearData();
  }, []);

  const filteredCompositions = useMemo(
    () =>
      vehicleCompositions.filter(
        ({ name }) =>
          name.toLocaleLowerCase().indexOf(searchStr.toLocaleLowerCase()) > -1
      ),
    [vehicleCompositions, searchStr]
  );

  return (
    <div className="palette-panel compositions scrollable">
      <h2>
        <TransTitle i18nKey="vehicleCompositions" />
      </h2>
      <Input
        className="search-input"
        placeholder={t('label.find', 'Find')!}
        icon="search"
        value={searchStr}
        onChange={(event) => setSearchStr(event.target.value)}
      />
      <div className="palette-elements">
        {filteredCompositions.map(({ id, name }) => (
          <Tooltip key={id} content={name}>
            <div
              id={`${id}`}
              className="palette-elements-item"
              draggable="true"
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              {name}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
