import { FC, Fragment, useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { classifiersCultureMapSelector } from 'features/classification/classificationSelectors';
import { Icon, Input, Select, SelectOption } from '@fleet/shared/mui';
import { Editor } from 'components/common/editor/Editor';
import { ElementDetailsRow } from 'components/elements/ElementDetailsRow';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { Localization } from 'dto/element';
import { AvailableLocalization } from 'dto/classification';
import { SelectValue } from '@fleet/shared/mui/Select';
import { TransLabel } from 'i18n/trans/label';
import { TransButton } from 'i18n/trans/button';

export interface ElementLocalizationFieldsProps {
  availableLocalizations: Array<AvailableLocalization>;
}

export interface ElementLocalizationFormValues {
  localizations: Array<Localization>;
}

const defaultLocalization: Localization = {
  languageId: '',
  name: '',
  description: '',
};

export const ElementLocalizationFields: FC<ElementLocalizationFieldsProps> = ({
  availableLocalizations,
}) => {
  const classifiersCultureMap = useSelector(classifiersCultureMapSelector);

  const {
    watch,
    control,
    setValue,
    trigger,
    formState: { isValid },
  } = useFormContext<ElementLocalizationFormValues>();
  const { fields, append } = useFieldArray({
    control,
    name: 'localizations',
  });

  const values = watch();
  const localizations: Array<Localization> = values.localizations ?? [];

  const usedLocalizations = localizations.map(({ languageId }) => languageId);
  const localizationOptions = useMemo(() => {
    return fields.reduce((acc: SelectOption[][], field, index: number) => {
      const value = usedLocalizations[index];
      const currentUsedLocalizations = usedLocalizations.filter(
        (languageId) => languageId !== value
      );
      if (availableLocalizations) {
        acc[index] = availableLocalizations
          .filter((c) => !currentUsedLocalizations.includes(c.languageId))
          .map(({ languageId }) => ({
            value: languageId,
            label: classifiersCultureMap.get(languageId),
          }));
      } else {
        acc[index] = [];
      }
      return acc;
    }, []);
  }, [
    fields,
    usedLocalizations,
    availableLocalizations,
    classifiersCultureMap,
  ]);

  const getSelectChangeHandler = useCallback(
    (name) => (value: SelectValue) => {
      setValue(name, value!, {
        shouldValidate: true,
      });
    },
    [setValue]
  );

  const handleAddLocalization = useCallback(async () => {
    await trigger();
    isValid && append(defaultLocalization);
  }, [append, isValid, trigger]);

  if (!Boolean(availableLocalizations.length)) return null;

  return (
    <>
      <ElementDetailsRow columns={[<TransLabel i18nKey="translations" />]} />
      {Boolean(fields.length) ? (
        fields.map((field, index) => {
          const localizations = localizationOptions[index];
          const { length: localizationsLength } = localizations;
          return (
            Boolean(localizationsLength) && (
              <Fragment key={field.id}>
                {index === 0 && <ElementDetailsRow columns={[null]} />}
                <ElementDetailsRow
                  columns={[
                    <TransLabel i18nKey="language" />,
                    <Controller
                      control={control}
                      name={`localizations.${index}.languageId`}
                      render={({ field, fieldState }) => (
                        <Select
                          className={classNames({
                            invalid: fieldState.invalid,
                          })}
                          options={localizations}
                          value={field.value}
                          onChange={getSelectChangeHandler(field.name)}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />,
                  ]}
                />
                <ElementDetailsRow
                  columns={[
                    <TransLabel i18nKey="name" />,
                    <Controller
                      control={control}
                      name={`localizations.${index}.name`}
                      render={({ field, fieldState }) => (
                        <Input
                          className={classNames({
                            invalid: fieldState.invalid,
                          })}
                          type="string"
                          {...field}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />,
                  ]}
                />
                <ElementDetailsRow
                  columns={[<TransLabel i18nKey="description" />]}
                />
                <ElementDetailsRow
                  columns={[
                    <Controller
                      control={control}
                      name={`localizations.${index}.description`}
                      render={({ field }) => <Editor {...field} />}
                    />,
                  ]}
                />
                <ElementDetailsRow columns={[null]} />
                {index === fields.length - 1 && localizations.length > 1 && (
                  <ElementDetailsRow
                    columns={[
                      <Button
                        variant="text"
                        color="primary"
                        startIcon={<Icon name="plus" />}
                        onClick={handleAddLocalization}
                      >
                        <TransButton i18nKey="addNewTranslation" />
                      </Button>,
                    ]}
                  />
                )}
              </Fragment>
            )
          );
        })
      ) : (
        <ElementDetailsRow
          columns={[
            <Button
              variant="text"
              color="primary"
              size="large"
              startIcon={<Icon name="plus" />}
              onClick={handleAddLocalization}
            >
              <TransButton i18nKey="addNewTranslation" />
            </Button>,
          ]}
        />
      )}
    </>
  );
};
