import { createAsyncThunk } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { getFloorElementsList } from 'features/classification/classificationActions';
import { FloorElementIcon } from 'dto/floor';
import { Localization } from 'dto/element';
import { api } from '@fleet/shared';

export const updateFloorElementIcon = createAsyncThunk<
  void,
  { id: string; category: string; icon: FloorElementIcon | null }
>(
  'element/currentOrganizationId/updateFloorElementIcon',
  async ({ id, category, icon }, { dispatch }) => {
    await api.patch(`/vehicles/floor-elements/${category}/${id}`, {
      icon,
    });
    await dispatch(getFloorElementsList());
  }
);

export const getSignLocalizations = createAsyncThunk<
  { localizations: Array<Localization> },
  string
>(
  'element/currentOrganizationId/getSignLocalizations',
  async (id, { getState }) =>
    (
      await api.get(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicle-floor-elements/signs/${id}/localizations`
      )
    ).data
);

export const updateSignLocalizations = createAsyncThunk<
  void,
  { id: string; localizations: Array<Localization> }
>(
  'element/currentOrganizationId/updateSignLocalizations',
  async ({ id, localizations }, { getState }) => {
    await api.put(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicle-floor-elements/signs/${id}/localizations`,
      { localizations }
    );
  }
);
