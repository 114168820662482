import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Icon,
  RadioGroup,
  Select,
  ToggleButtonGroup,
} from '@fleet/shared/mui';
import _isArray from 'lodash/isArray';
import { useDispatch, useSelector } from 'store/utils';
import { assignEntityFilterOptionsSelector } from 'features/classification/classificationSelectors';
import { TripDto, TripFilterDto } from 'dto/trip';
import { getTrips } from 'features/composition/compositionActions';
import _isEmpty from 'lodash/isEmpty';
import emptySearchImg from '@fleet/shared/icons/empty-search.svg';
import { VehicleCompositionRelation } from 'dto/composition';
import { formatDate, isoDateTimeFormat } from '@fleet/shared/utils/date';
import { makeStyles } from '@mui/styles';
import { CardHeader, RadioGroupProps } from '@fleet/shared';
import { SelectValue } from '@fleet/shared/mui/Select';
import { DatePickerRangeValue } from '@fleet/shared/mui/DatePicker';
import { TransLabel } from 'i18n/trans/label';
import { WeekDays } from 'features/utils';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TransButton } from 'i18n/trans/button';

interface TripSearchProps {
  title: ReactNode;
  refreshSearch?: boolean;
  children(props: {
    data: TripDto[];
    loading: boolean;
    totalCount: number;
    offset: number;
    onPageChange(params?: PaginationParams): Promise<void>;
  }): ReactNode;
  vehicleCompositionRelation?: VehicleCompositionRelation;
  filterByCompositionRelation?: boolean;
}

const useStyles = makeStyles(
  () => ({
    select: {
      paddingRight: '32px',
      maxWidth: '17%',
      '& .MuiTypography-body2 + .MuiBox-root': {
        marginBottom: 0,
      },
    },
  }),
  {
    name: 'TripSearch',
  }
);

export const TripSearch: FC<TripSearchProps> = ({
  title,
  children,
  refreshSearch,
  vehicleCompositionRelation,
  filterByCompositionRelation,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isoDateFormat] = isoDateTimeFormat.split("'T'");
  const tripDateFromDefaultFormatted = useMemo(
    () => formatDate(new Date(), isoDateFormat),
    [isoDateFormat]
  );
  const [filterParams, _setFilterParam] = useState<Partial<TripFilterDto>>({
    subContractorIds: [],
    serviceCodes: [],
    isTripActive: true,
    vehicleCompositionRelation,
    tripDateFrom: tripDateFromDefaultFormatted,
  });

  const [tripsResult, setTrips] = useState<{
    data?: TripDto[];
    totalCount: number;
    offset: number;
    loading: boolean;
  }>({
    totalCount: 0,
    offset: 0,
    loading: false,
  });

  const setFilterParam = useCallback(
    (param) => _setFilterParam((filter) => ({ ...filter, ...param })),
    []
  );
  const touched = useMemo(() => _isArray(tripsResult.data), [tripsResult.data]);
  const noResults = useMemo(
    () => touched && _isEmpty(tripsResult.data),
    [tripsResult.data, touched]
  );
  const searchTrips = useCallback(
    async (params?: PaginationParams) => {
      const latestFilter = {
        ...filterParams,
        ...params,
      };
      setTrips((trips) => ({ ...trips, loading: true }));

      const { items, totalCount, offset } = await dispatch(
        getTrips(latestFilter)
      ).unwrap();

      setFilterParam(latestFilter);

      setTrips({ data: items, totalCount, offset, loading: false });
    },
    [dispatch, filterParams, setFilterParam]
  );

  useEffect(() => {
    if (refreshSearch) {
      searchTrips({
        offset: tripsResult.offset,
      });
    }
  }, [refreshSearch, searchTrips, tripsResult.offset]);
  const {
    lines,
    serviceCodes,
    vehicleCompositions,
    subContractors,
    tripStatuses,
    days,
  } = useSelector(assignEntityFilterOptionsSelector);

  const getSelectHandler = useCallback(
    (fieldName: string) => (selected: SelectValue | Array<SelectValue>) => {
      setFilterParam({
        [fieldName]: selected,
      });
    },
    [setFilterParam]
  );

  const getDateHandler = useCallback(
    (period: DatePickerRangeValue<Date>) => {
      setFilterParam({
        tripDateFrom: formatDate(period.from, isoDateFormat),
        tripDateTo: formatDate(period.to, isoDateFormat),
      });
    },
    [setFilterParam, isoDateFormat]
  );

  const departureDaysHandler = useCallback(
    (days: WeekDays) => setFilterParam({ departureDays: days }),
    [setFilterParam]
  );

  const tripActiveHandler = useCallback<Required<RadioGroupProps>['onChange']>(
    (val) => setFilterParam({ isTripActive: Boolean(Number(val)) }),
    [setFilterParam]
  );

  return (
    <div className="relations-search panel">
      <CardHeader title={title} className="relations-title" />
      <div className="relations-filter">
        <div className="filter-row">
          <Select
            className={classes.select}
            label={<TransLabel i18nKey="lineNumber" />}
            onChange={getSelectHandler('lineId')}
            options={lines}
            labelPosition="top"
            showEmptyOption
          />
          <Select
            className={classes.select}
            label={<TransLabel i18nKey="serviceCode" />}
            labelPosition="top"
            onChange={getSelectHandler('serviceCodes')}
            options={serviceCodes}
            value={filterParams.serviceCodes}
            multiple
          />
          <Select
            className={classes.select}
            label={<TransLabel i18nKey="subContractor" />}
            labelPosition="top"
            onChange={getSelectHandler('subContractorIds')}
            options={subContractors}
            value={filterParams.subContractorIds}
            multiple
          />
          <Select
            className={classes.select}
            label={<TransLabel i18nKey="vehicleCompositionsConnected" />}
            labelPosition="top"
            onChange={getSelectHandler('vehicleCompositionId')}
            options={vehicleCompositions}
            showEmptyOption
          />
          <Button
            label={<TransButton i18nKey="search" />}
            className="filter-btn"
            onClickAsync={searchTrips.bind(null, { offset: 0 })}
            startIcon={<Icon name="search" color="white" />}
          />
        </div>
        <div className="filter-row">
          <Select
            className={classes.select}
            label={<TransLabel i18nKey="tripStatus" />}
            labelPosition="top"
            onChange={getSelectHandler('tripStatus')}
            options={tripStatuses}
            showEmptyOption
          />
          <RadioGroup
            label={<TransLabel i18nKey="active" />}
            name="isTripActive"
            defaultValue={1}
            onChange={tripActiveHandler}
            options={[
              { label: <TransLabel i18nKey="yes" />, value: 1 },
              { label: <TransLabel i18nKey="no" />, value: 0 },
            ]}
            inline
          />

          <DatePicker
            label={<TransLabel i18nKey="tripDateFromTo" />}
            value={
              {
                from: filterParams.tripDateFrom,
                to: filterParams.tripDateTo,
              } as DatePickerRangeValue<string>
            }
            onChange={getDateHandler}
            selectsRange
          />
          <div className="departure-days-filter">
            <label>
              <TransLabel i18nKey="departureDays" />
            </label>
            <ToggleButtonGroup
              options={days}
              onChange={departureDaysHandler}
              value={filterParams.departureDays}
              color="secondary"
              multiple
            />
          </div>
          {filterByCompositionRelation && (
            <Checkbox
              name={'vehicleCompositionRelation'}
              label={<TransLabel i18nKey="hasCompositions" />}
              checked={
                filterParams.vehicleCompositionRelation === 'has_compositions'
              }
              onChange={({ target: { name, checked } }) =>
                setFilterParam({ [name]: checked ? 'has_compositions' : 'all' })
              }
              inline
            />
          )}
        </div>
      </div>
      {touched && (
        <div className="heading">
          <div className="heading-title">
            {<TransLabel i18nKey="searchResults" />}
          </div>
          {touched && (
            <div className="connected-number">
              <TransLabel
                i18nKey="tripsFound"
                values={{ count: tripsResult.totalCount }}
              />
            </div>
          )}
        </div>
      )}
      {touched ? (
        noResults ? (
          <div className="empty-search-result">
            <img src={emptySearchImg} width="80" height="80" alt="" />
            <span>
              <TransLabel i18nKey="noTripsFound" />
            </span>
          </div>
        ) : (
          children({
            data: tripsResult.data!,
            loading: tripsResult.loading,
            totalCount: tripsResult.totalCount,
            offset: tripsResult.offset,
            onPageChange: searchTrips,
          })
        )
      ) : null}
    </div>
  );
};
