import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { TransLabel } from 'i18n/trans/label';
import { Modal, useModal } from '@fleet/shared';
import {
  FormProvider,
  FormField,
  SwitchField,
  ReadOnlyField,
} from '@fleet/shared';
import { formSubmit, useForm } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { InventoryBlockingLevel } from 'dto/organization';
import { InventoryBlockingReasonSelect } from 'components/common/select/InventoryBlockingReason';
import Grid from '@mui/material/Grid';
import { StopsSelect } from 'components/common/select/StopsSelect';
import {
  blockLineTemplateVehicle,
  getAssignedComposition,
  updateTripComposition,
} from 'features/composition/compositionActions';
import {
  compositionConstructSelector,
  compositionConstructVehicleSelector,
} from 'features/composition/compositionSelectors';
import { Button } from '@mui/material';
import { metaError } from '@fleet/shared/form/FormField';
import { CarriageBlockingValues } from 'dto/vehicle';
import { TransButton } from 'i18n/trans/button';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    content: {
      paddingLeft: theme.spacing(2),
      borderLeft: `1px solid ${theme.palette.divider}`,
      '& .MuiFormControl-root > label': {
        fontWeight: 400,
      },
    },
  }),
  {
    name: 'CarriageBlockingProperty',
  }
);

interface CarriageBlockingPropertyProps {}

export const CarriageBlockingProperty: FC<CarriageBlockingPropertyProps> =
  () => {
    const composition = useSelector(compositionConstructSelector)!;
    const vehicle = useSelector(compositionConstructVehicleSelector)!;
    const { id: compositionId, vehicleCompositionId } = composition;
    const { blockingReason, blockingOriginStop, blockingDestinationStop } =
      vehicle;

    const isBlocked = useMemo(
      () => Boolean(blockingReason?.id),
      [blockingReason?.id]
    );
    const modal = useModal();
    const dispatch = useDispatch();
    const onSubmit = useCallback(
      ({
        isBlocked,
        blockingReasonId,
        blockingOriginStopId,
        blockingDestinationStopId,
      }: CarriageBlockingValues) =>
        formSubmit(async () => {
          if (vehicleCompositionId) {
            await dispatch(
              blockLineTemplateVehicle({
                lineTemplateId: `${compositionId}`,
                vehicleId: `${vehicle.id}`,
                isBlocked,
                blockingReasonId,
                blockingOriginStopId,
                blockingDestinationStopId,
              })
            );
            await dispatch(
              getAssignedComposition({
                compositionId: `${compositionId}`,
                type: 'line-template',
                orderNumber: vehicle.orderNumber,
              })
            );
          } else {
            await dispatch(
              updateTripComposition({
                tripRelationId: compositionId,
                vehicleCompositionDirectionId:
                  composition.vehicleCompositionDirection!.id,
                tripVehicleCompositionVehicles: [
                  {
                    id: vehicle.id,
                    orderNumber: vehicle.orderNumber,
                    isVehicleFlipped: vehicle.isVehicleFlipped,
                    isBlocked,
                    blockingReason: { id: blockingReasonId!, name: '' },
                    blockingOriginStop: {
                      id: blockingOriginStopId!,
                      name: '',
                    },
                    blockingDestinationStop: {
                      id: blockingDestinationStopId!,
                      name: '',
                    },
                    number: vehicle.number!,
                    salesOpeningPriority: vehicle!.salesOpeningPriority,
                    salesOpeningThresholdPercentage:
                      vehicle!.salesOpeningThresholdPercentage,
                  },
                ],
              })
            );
            await dispatch(
              getAssignedComposition({
                compositionId: `${compositionId}`,
                type: 'trip',
                orderNumber: vehicle.orderNumber,
              })
            );
          }
          modal.onClose();
        }),
      [
        vehicleCompositionId,
        modal,
        dispatch,
        compositionId,
        vehicle,
        composition.vehicleCompositionDirection,
      ]
    );

    const initialValues = useMemo(
      () => ({
        isBlocked,
        blockingReasonId: blockingReason?.id,
        blockingOriginStopId: blockingOriginStop?.id,
        blockingDestinationStopId: blockingDestinationStop?.id,
      }),
      [
        blockingDestinationStop?.id,
        blockingOriginStop?.id,
        blockingReason?.id,
        isBlocked,
      ]
    );

    const { form, handleSubmit } = useForm<CarriageBlockingValues>({
      initialValues,
      onSubmit,
    });

    const classes = useStyles();
    return (
      <div className={classes.root}>
        <FormProvider {...form}>
          <SwitchField
            name="isBlocked"
            labelPosition="left"
            label={<TransLabel i18nKey="property.isBlocked" />}
            checked={Boolean(blockingReason?.id)}
            onChange={(_, checked) => {
              checked ? modal.onOpen() : onSubmit({ isBlocked: false });
            }}
          />
          {isBlocked && (
            <div className={classes.content}>
              <ReadOnlyField
                labelPosition="left"
                label={<TransLabel i18nKey="reason" />}
                value={vehicle.blockingReason?.name}
              />
              <ReadOnlyField
                labelPosition="left"
                label={<TransLabel i18nKey="originStop" />}
                value={vehicle.blockingOriginStop?.name}
              />
              <ReadOnlyField
                labelPosition="left"
                label={<TransLabel i18nKey="destinationStop" />}
                value={vehicle.blockingDestinationStop?.name}
              />
              <Button
                variant="text"
                sx={{ ml: -2, pt: 0, minWidth: 0 }}
                onClick={modal.onOpen}
              >
                <TransButton i18nKey="edit" />
              </Button>
            </div>
          )}
          <Modal
            title={<TransTitle i18nKey="blockingCarriage" />}
            actionButton={
              <Button variant="contained" onClick={() => handleSubmit()}>
                <TransButton i18nKey="save" />
              </Button>
            }
            open={modal.open}
            onClose={modal.onClose}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormField<string>
                  name="blockingReasonId"
                  render={({ input, meta }) => (
                    <InventoryBlockingReasonSelect
                      label={<TransLabel i18nKey="reason" />}
                      level={InventoryBlockingLevel.CARRIAGE}
                      value={input.value}
                      onChange={input.onChange}
                      showEmptyOption
                      error={metaError(meta)}
                    />
                  )}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormField<number>
                  name="blockingOriginStopId"
                  render={({ input, meta }) => (
                    <StopsSelect
                      label={<TransLabel i18nKey="blockedOriginDestination" />}
                      value={input.value}
                      stop={blockingOriginStop}
                      onChange={input.onChange}
                      error={metaError(meta)}
                    />
                  )}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <FormField<number>
                  name="blockingDestinationStopId"
                  render={({ input, meta }) => (
                    <StopsSelect
                      label="&nbsp;"
                      value={input.value}
                      stop={blockingDestinationStop}
                      onChange={input.onChange}
                      error={metaError(meta)}
                    />
                  )}
                  required
                />
              </Grid>
            </Grid>
          </Modal>
        </FormProvider>
      </div>
    );
  };
