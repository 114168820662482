import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Drawer,
  Field,
  FormProvider,
  formSubmit,
  RadioGroupField,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Icon, Tooltip } from '@fleet/shared/mui';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { InventoryClass, InventoryClassPayload } from 'dto/inventoryClass';
import {
  createOrUpdateInventoryClass,
  getInventoryClass,
  getInventoryClasses,
  setInventoryClass,
} from 'features/inventoryClass/inventoryClassActions';
import {
  currentInventoryClassSelector,
  inventoryClassFilterSelector,
} from 'features/inventoryClass/inventoryClassSelectors';
import { TransTitle } from 'i18n/trans/title';
import { useAlert } from 'react-alert';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { TransLabel } from 'i18n/trans/label';
import { TransMessage } from 'i18n/trans/message';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  Properties,
  PropertiesProps,
} from 'components/propertiesPanel/Properties';
import { InventoryType } from 'dto/organization';
import { TransButton } from 'i18n/trans/button';

interface InventoryClassDetailsProps {}

export const InventoryClassDetails: FC<InventoryClassDetailsProps> = () => {
  const { action, id } =
    useParams<{ action: 'create' | 'edit'; id?: string }>();
  const { state } =
    useLocation<{ inventoryType: InventoryClass['inventoryType'] }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentInventoryClass = useSelector(currentInventoryClassSelector);
  const filter = useSelector(inventoryClassFilterSelector);
  const inventoryTypeOptions = useClassificationOptions(
    ClassificationGroup.INVENTORY_TYPE
  );
  const comfortLevelOptions = useClassificationOptions(
    ClassificationGroup.COMFORT_LEVEL
  );
  const serviceClassOptions = useClassificationOptions(
    ClassificationGroup.SERVICE_CLASS
  );
  const bedSubTypeOptions = useClassificationOptions(
    ClassificationGroup.BED_INVENTORY_SUB_TYPE
  );

  const splitsOptions = useMemo(
    () =>
      [...Array(3).keys()].map((value) => ({
        value,
        label: `${value}`,
      })),
    []
  );

  useEffect(() => {
    if (action === 'edit' && id) {
      dispatch(getInventoryClass(id));
    }

    return () => {
      dispatch(setInventoryClass());
    };
  }, [action, dispatch, id]);

  const closeDrawer = useCallback(() => {
    history.replace('/inventory-class');
  }, [history]);

  const onSubmit = useCallback(
    (values: InventoryClassPayload) =>
      formSubmit(async () => {
        const data = await dispatch(
          createOrUpdateInventoryClass(values)
        ).unwrap();

        alert.success(
          <TransMessage
            i18nKey={
              values.id ? 'inventoryClassUpdated' : 'inventoryClassCreated'
            }
          />
        );

        history.push(`/inventory-class/edit/${data.id}`);
        await dispatch(getInventoryClasses(filter));
      }),
    [dispatch, alert, history, filter]
  );

  const initialValues = useMemo(
    () => ({
      inventoryType: state?.inventoryType.id,
      isActive: false,
      isAssignmentEnabled: false,
      isSoldOnce: false,
      isDefault: false,
      propertyIds: [],
      ...(currentInventoryClass && {
        ...currentInventoryClass,
        comfortLevel: currentInventoryClass.comfortLevel.id,
        serviceClass: currentInventoryClass.serviceClass.id,
        inventoryType: currentInventoryClass.inventoryType.id,
        inventorySubType: currentInventoryClass.inventorySubType?.id,
        propertyIds: currentInventoryClass.properties.map(({ id }) => id),
      }),
    }),
    [currentInventoryClass, state]
  );

  const { form, handleSubmit, submitting } = useForm<InventoryClassPayload>({
    initialValues,
    onSubmit,
    subscription: { submitting: true, values: true },
  });

  const handlePropertiesChange = useCallback<PropertiesProps['onConfirm']>(
    ({ properties }) => {
      form.change(
        'propertyIds',
        properties?.map(({ id }) => id)
      );
    },
    [form]
  );

  return (
    <Drawer anchor="right" elevation={0} onClose={closeDrawer} open>
      <FormProvider {...form}>
        <Stack
          sx={{
            height: '100%',
            width: '424px !important',
            minWidth: '0 !important',
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <CardHeader
            sx={{ pb: 3 }}
            title={
              <Typography variant="subtitle">
                {id ? (
                  <TransTitle i18nKey="inventoryClass" />
                ) : (
                  <TransTitle i18nKey="newInventoryClass" />
                )}
              </Typography>
            }
            action={
              <IconButton aria-label="close" onClick={closeDrawer}>
                <Tooltip content={<TransLabel i18nKey="close" />} delay={500}>
                  <Icon name="close" size={24} />
                </Tooltip>
              </IconButton>
            }
          />
          <CardContent sx={{ flex: 1, py: 0, pb: 3, overflowY: 'auto' }}>
            <SelectField
              name="inventoryType"
              disabled
              label={<TransLabel i18nKey="inventoryType" />}
              options={inventoryTypeOptions}
              margin="dense"
            />
            <Field<InventoryClassPayload['inventoryType']>
              name="inventoryType"
              render={({ input: { value: currentInventoryType } }) =>
                currentInventoryType === InventoryType.BED && (
                  <SelectField
                    name="inventorySubType"
                    label={<TransLabel i18nKey="inventorySubType" />}
                    options={bedSubTypeOptions}
                    margin="dense"
                  />
                )
              }
            />

            <TextField
              label={<TransLabel i18nKey="name" />}
              name="name"
              required
              margin="dense"
            />
            <TextField
              label={<TransLabel i18nKey="code" />}
              name="code"
              required
              margin="dense"
            />
            <TextField
              label={<TransLabel i18nKey="description" />}
              name="description"
              margin="dense"
            />
            <SelectField
              name="comfortLevel"
              label={<TransLabel i18nKey="comfortClass" />}
              options={comfortLevelOptions}
              required
              margin="dense"
            />
            <SelectField
              name="serviceClass"
              label={<TransLabel i18nKey="serviceLevel" />}
              options={serviceClassOptions}
              required
              margin="dense"
            />
            <Field<InventoryClassPayload['inventoryType']>
              name="inventoryType"
              render={({ input: { value: currentInventoryType } }) => (
                <>
                  {currentInventoryType === InventoryType.COMPARTMENT && (
                    <TextField
                      name="minOccupancy"
                      label={<TransLabel i18nKey="minOccupancy" />}
                      required
                      margin="dense"
                    />
                  )}
                  {currentInventoryType === InventoryType.COMPARTMENT && (
                    <TextField
                      name="maxOccupancy"
                      label={<TransLabel i18nKey="maxOccupancy" />}
                      required
                      margin="dense"
                    />
                  )}
                  {(currentInventoryType === InventoryType.BED ||
                    currentInventoryType === InventoryType.COMPARTMENT) && (
                    <RadioGroupField
                      name="isSoldOnce"
                      label={<TransLabel i18nKey="soldOnce" />}
                      options="BOOL_ONLY"
                      margin="dense"
                      inline
                    />
                  )}
                  {currentInventoryType === InventoryType.SEAT && (
                    <SelectField
                      name="allowedNumberOfSplits"
                      label={<TransLabel i18nKey="splitsEnabled" />}
                      options={splitsOptions}
                      required
                      margin="dense"
                    />
                  )}
                  {(currentInventoryType === InventoryType.SEAT ||
                    currentInventoryType === InventoryType.BED) && (
                    <RadioGroupField
                      name="isAssignmentEnabled"
                      label={<TransLabel i18nKey="seatAssignmentEnabled" />}
                      options="BOOL_ONLY"
                      margin="dense"
                      inline
                    />
                  )}
                </>
              )}
            />
            <RadioGroupField
              name="isActive"
              label={<TransLabel i18nKey="active" />}
              options="BOOL_ONLY"
              margin="dense"
              inline
            />
            <RadioGroupField
              name="isDefault"
              label={<TransLabel i18nKey="default" />}
              options="BOOL_ONLY"
              margin="dense"
              inline
            />

            <Field<InventoryClassPayload['inventoryType']>
              name="inventoryType"
              render={({ input: { value } }) => (
                <Field<InventoryClassPayload['propertyIds']>
                  name="propertyIds"
                  render={({ input: { value: propertyIds = [] } }) => (
                    <Properties
                      typeId={value}
                      onConfirm={handlePropertiesChange}
                      propertiesOnly
                      properties={propertyIds.map((id) => ({ id }))}
                    />
                  )}
                />
              )}
            />
          </CardContent>
          <CardActions
            sx={{
              padding: 3,
              justifyContent: 'flex-end',
              boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Button variant="text" color="primary" onClick={closeDrawer}>
              <TransButton i18nKey="cancel" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting}
              startIcon={<Icon name={id ? 'check' : 'plus'} />}
            >
              <TransButton i18nKey={id ? 'save' : 'create'} />
            </Button>
          </CardActions>
        </Stack>
      </FormProvider>
    </Drawer>
  );
};
