import { FC, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
  AddButton,
  CardHeader,
  Layout,
  Loadable,
  SearchResult,
} from '@fleet/shared';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'store/utils';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { AllocationScoreTemplateDetails } from 'routes/allocationScoreTemplate/AllocationScoreTemplateDetails';
import { dataLoadingSelector } from 'features/common/commonSelectors';
import { getAllocationScoreTemplates } from 'features/allocationScoreTemplate/allocationScoreTemplateActions';
import { allocationScoreTemplatesSelector } from 'features/allocationScoreTemplate/allocationScoreTemplateSelectors';
import { CardContent, Grid } from '@mui/material';
import { AllocationScoreTemplateCard } from 'routes/allocationScoreTemplate/AllocationScoreTemplateCard';

const useStyles = makeStyles(
  () => ({
    layout: { padding: 0 },
  }),
  {
    name: 'AllocationScoreTemplate',
  }
);

interface AllocationScoreTemplateProps {}

export const AllocationScoreTemplate: FC<AllocationScoreTemplateProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(dataLoadingSelector);
  const data = useSelector(allocationScoreTemplatesSelector);

  useEffect(() => {
    dispatch(getAllocationScoreTemplates());
  }, [dispatch]);

  return (
    <Loadable loading={loading}>
      <Layout
        componentClasses={{ root: classes.layout }}
        header={
          <CardHeader title={<TransTitle i18nKey="allocationScoreTemplate" />}>
            <AddButton
              onClick={() => history.push('/allocation-score-templates/create')}
            >
              <TransButton i18nKey="add" />
            </AddButton>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/allocation-score-templates/:action(create|edit)/:id?"
              component={AllocationScoreTemplateDetails}
              exact
            />
          </Switch>
        }
      >
        <SearchResult
          results={data?.length}
          i18nKey="noDataAdded"
          loading={loading}
        >
          <CardContent sx={{ p: 2, flexGrow: 1 }}>
            <Grid container spacing={3}>
              {data?.map((item) => (
                <Grid key={item.id} item sm={4} md={3}>
                  <AllocationScoreTemplateCard {...item} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </SearchResult>
      </Layout>
    </Loadable>
  );
};
