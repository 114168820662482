import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Classifier } from '@fleet/shared/dto/classifier';
import qs from 'qs';
import debounce from 'lodash/debounce';
import { Pagination } from '@fleet/shared/dto/pagination';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { api, FormControl } from '@fleet/shared';

interface StopsSelectProps
  extends Partial<
    Omit<AutocompleteProps<number, false, true, false>, 'options' | 'onChange'>
  > {
  label?: ReactNode;
  error?: ReactNode;
  stop?: Classifier<number>;
  onChange: (value: number) => void;
}

export const StopsSelect: FC<StopsSelectProps> = ({
  stop,
  onChange,
  label,
  error,
}) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(stop);
  const [inputValue, setInputValue] = useState(stop?.name ?? '');
  const [options, setOptions] = useState<Array<Classifier<number>>>(
    stop ? [stop] : []
  );

  const fetch = useMemo(
    () =>
      debounce(
        (input: string, callback: (results: Classifier<number>[]) => void) => {
          api
            .get<Pagination<Classifier<number>>>(
              `/stops${qs.stringify(
                { name: input },
                {
                  addQueryPrefix: true,
                  skipNulls: true,
                }
              )}`,
              { baseURL: process.env.REACT_APP_API_LM }
            )
            .then(({ data }) =>
              callback(data.items.map(({ id, name }) => ({ id, name })))
            );
        },
        400
      ),
    []
  );

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
      return;
    }

    setLoading(true);
    fetch(inputValue, (options) => {
      setOptions(options);
      setLoading(false);
    });
  }, [fetch, inputValue]);

  const handleInputChange = useCallback(async (event, inputValue: string) => {
    setInputValue(inputValue);
  }, []);

  const handleChange = useCallback(
    (event, value: Classifier<number>) => {
      setValue(value);
      onChange?.(value.id);
    },
    [onChange]
  );

  return (
    <FormControl label={label} error={error}>
      <Autocomplete<Classifier<number>, false, true>
        loading={loading}
        value={value}
        options={loading ? [] : options}
        renderInput={(params) => <TextField {...params} fullWidth />}
        onChange={handleChange}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => option.name}
        disableClearable
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </FormControl>
  );
};
