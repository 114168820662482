import { useCallback, useEffect, useMemo } from 'react';
import {
  TableOptions as ReactTableOptions,
  SortingRule as ReactTableSortingRule,
  useTable,
  useSortBy,
  useExpanded,
  Row,
  useRowSelect,
  useMountedLayoutEffect,
  TableOptions,
  UseRowSelectRowProps,
  UseTableRowProps,
  IdType,
  usePagination,
} from 'react-table';
import classNames from 'classnames';
import { Checkbox, Loader } from '@fleet/shared/mui';
import { Table as FleetTable } from '@fleet/shared';
import type { PaginationParams } from '@fleet/shared/dto/pagination';

import 'components/common/table/table.scss';

const INITIAL_SORT_BY: { id: string; desc: boolean }[] = [];
const INITIAL_SELECTED_ROW_IDS = {};

export interface TableProps<D extends object> extends ReactTableOptions<D> {
  className?: string;
  sortBy?: Array<ReactTableSortingRule<D>>;
  onRowClick?: (row: D) => void;
  renderRowSubComponent?: (row: Row<D>) => JSX.Element;
  totalCount?: number;
  offset?: number;
  onPageChange?: (params: PaginationParams) => void;
  loading?: boolean;
  onSelectedRowsChange?: (selected: D[]) => void;
  initialExpanded?: Record<string, boolean>;
}

export function Table<D extends object = {}>(props: TableProps<D>) {
  const {
    className,
    data,
    columns,
    sortBy = INITIAL_SORT_BY,
    onRowClick,
    renderRowSubComponent,
    totalCount,
    onPageChange,
    loading,
    offset = 0,
    onSelectedRowsChange,
    initialExpanded,
    autoResetExpanded = false,
  } = props;

  const getPage = useCallback(
    (pageSize: number) => {
      if (offset) {
        return offset / pageSize;
      }
      return 0;
    },
    [offset]
  );

  const preparedProps = useMemo<TableOptions<D>>(
    () => ({
      data,
      columns: onSelectedRowsChange
        ? [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <Checkbox
                  name="all"
                  size="small"
                  {...getToggleAllRowsSelectedProps()}
                  inline
                />
              ),
              Cell: ({
                row,
              }: {
                row: UseRowSelectRowProps<D> & UseTableRowProps<D>;
              }) => (
                <Checkbox
                  name={row.id}
                  size="small"
                  {...row.getToggleRowSelectedProps()}
                />
              ),
              disableSortBy: true,
            },
            ...columns,
          ]
        : columns,
      initialState: {
        sortBy,
        selectedRowIds: INITIAL_SELECTED_ROW_IDS as Record<IdType<D>, boolean>,
        expanded: initialExpanded || {},
        ...(!totalCount && {
          pageSize: Infinity,
        }),
      },
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      autoResetExpanded,
      ...(totalCount && {
        pageCount: -1,
        total: totalCount,
        manualPagination: true,
        onPageChange,
      }),
    }),
    [
      data,
      onSelectedRowsChange,
      columns,
      sortBy,
      initialExpanded,
      totalCount,
      autoResetExpanded,
      onPageChange,
      getPage,
    ]
  );
  const tableProps = useTable(
    preparedProps,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const { toggleRowExpanded, setSortBy, selectedFlatRows } = tableProps;

  const selectedRowOriginals = useMemo(
    () => selectedFlatRows.map(({ original }) => ({ ...original })),
    [selectedFlatRows]
  );

  useEffect(() => {
    sortBy && setSortBy(sortBy);
  }, [sortBy, setSortBy]);

  useMountedLayoutEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(selectedRowOriginals);
  }, [selectedRowOriginals]);

  return (
    <div className={classNames('table-wrapper', className)}>
      <Loader active={loading} size="container" />
      <FleetTable
        table={tableProps}
        getTableProps={() => ({
          className: 'table',
        })}
        getHeaderGroupProps={() => ({
          className: classNames('row', {
            withSelection: onSelectedRowsChange,
          }),
        })}
        getRowProps={(row, meta) => ({
          className: classNames('row', {
            expandable: !!renderRowSubComponent,
            expanded: meta.row.isExpanded,
            withSelection: onSelectedRowsChange,
          }),
          onClick: () => {
            renderRowSubComponent &&
              toggleRowExpanded([meta.row.id], !meta.row.isExpanded);
            onRowClick && onRowClick(meta.row.original);
          },
        })}
        getCellProps={(cell, meta) => ({
          className: meta.cell.column.className,
        })}
        {...(renderRowSubComponent && {
          getSubRow: (row) => renderRowSubComponent(row),
          getSubRowProps: () => ({
            className: 'expanded-row-info',
          }),
        })}
      />
    </div>
  );
}
