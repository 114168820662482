import { DragEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { useTranslation } from 'react-i18next';
import { getVehicles } from 'features/vehicle/vehicleActions';
import { vehicleListSelector } from 'features/vehicle/vehicleSelector';
import { Input, Tooltip } from '@fleet/shared/mui';
import { setIsDraggingFromPalette } from 'features/common/commonActions';
import { VehicleType } from 'dto/vehicle';
import { TransTitle } from 'i18n/trans/title';

export const VehiclesPanel = ({
  vehicleTypeId,
}: {
  vehicleTypeId?: VehicleType;
}) => {
  const [searchStr, setSearchStr] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getVehicles({
        vehicleTypeId,
      })
    );
  }, [dispatch, vehicleTypeId]);
  const vehicleList = useSelector(vehicleListSelector);
  const handleDragStart = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      dispatch(setIsDraggingFromPalette(true));
      event.dataTransfer.setData('text', event.currentTarget.id);
    },
    [dispatch]
  );

  const handleDragEnd = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      dispatch(setIsDraggingFromPalette(false));
      event.dataTransfer.clearData();
    },
    [dispatch]
  );

  const filteredVehicles = useMemo(
    () =>
      vehicleList.filter(
        ({ name }) =>
          name.toLocaleLowerCase().indexOf(searchStr.toLocaleLowerCase()) > -1
      ),
    [vehicleList, searchStr]
  );

  return (
    <div className="palette-panel vehicles scrollable">
      <h2>
        <TransTitle i18nKey="vehiclePlans" />
      </h2>
      <Input
        className="search-input"
        placeholder={t('label.find', 'Find')!}
        icon="search"
        value={searchStr}
        onChange={(event) => setSearchStr(event.target.value)}
      />
      <div className="palette-elements">
        {filteredVehicles.map(({ id, name }) => (
          <Tooltip placement="bottom" content={name} key={id}>
            <div
              id={String(id)}
              className="palette-elements-item"
              draggable="true"
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              {name}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
