import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from 'features/loading/loadingReducer';
import { classificationReducer } from 'features/classification/classificationReducer';
import { commonReducer } from 'features/common/commonReducer';
import { floorReducer } from 'features/floor/floorReducer';
import { vehicleReducer } from 'features/vehicle/vehicleReducer';
import { compositionReducer } from 'features/composition/compositionReducer';
import { inventoryClassReducer } from 'features/inventoryClass/inventoryClassReducer';
import { allocationScoreTemplateReducer } from 'features/allocationScoreTemplate/allocationScoreTemplateReducer';
import { siAllocationRulesetReducer } from 'features/siAllocationRuleset/siAllocationRulesetReducer';
import { siServiceAllocationRulesetReducer } from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetReducer';

export default combineReducers({
  loading: loadingReducer,
  classification: classificationReducer,
  common: commonReducer,
  floor: floorReducer,
  vehicle: vehicleReducer,
  composition: compositionReducer,
  inventoryClass: inventoryClassReducer,
  allocationScoreTemplate: allocationScoreTemplateReducer,
  siAllocationRuleset: siAllocationRulesetReducer,
  siServiceAllocationRuleset: siServiceAllocationRulesetReducer,
});
