import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  ConfirmDeleteModal,
  FormProvider,
  Icon,
  TextField,
  useForm,
  useModal,
} from '@fleet/shared';
import { SiAllocationRuleset } from 'dto/siAllocationRuleset';
import { useCallback, useMemo } from 'react';
import { CardContent, Stack, Typography } from '@mui/material';
import { TransTitle } from 'i18n/trans/title';
import Grid from '@mui/material/Grid';
import { SelectOwnerField } from 'components/field/SelectOwnerField';
import { TransButton } from 'i18n/trans/button';
import { TransLabel } from 'i18n/trans/label';
import {
  copySiAllocationRuleset,
  createSiAllocationRuleset,
  deleteSiAllocationRuleset,
  updateSiAllocationRuleset,
} from 'features/siAllocationRuleset/siAllocationRulesetActions';
import { useAlert } from 'react-alert';
import { TransMessage } from 'i18n/trans/message';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { TransModal } from 'i18n/trans/modal';
import { currentSiAllocationRulesetSelector } from 'features/siAllocationRuleset/siAllocationRulesetSelectors';

const useStyles = makeStyles(
  () => ({
    root: {},
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
  }),
  {
    name: 'SiAllocationRulesetDetailsForm',
  }
);

interface SiAllocationRulesetDetailsFormProps {}

export const SiAllocationRulesetDetailsForm: FC<SiAllocationRulesetDetailsFormProps> =
  () => {
    const { id } = useParams<{ id: string }>();
    const siAllocationRuleset = useSelector(currentSiAllocationRulesetSelector);

    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();

    const onSubmit = useCallback(
      async (values: Partial<SiAllocationRuleset>) => {
        const data = await dispatch(
          values?.id
            ? updateSiAllocationRuleset(values)
            : createSiAllocationRuleset(values)
        ).unwrap();
        alert.success(
          <TransMessage
            i18nKey={
              values?.id
                ? 'siAllocationRulesetUpdated'
                : 'siAllocationRulesetCreated'
            }
          />
        );
        if (!values.id) history.replace(`/allocation-rulesets/edit/${data.id}`);
      },
      [alert, dispatch, history]
    );

    const initialValues = useMemo<Partial<SiAllocationRuleset>>(
      () => siAllocationRuleset ?? {},
      [siAllocationRuleset]
    );
    const { form, handleSubmit, submitting, pristine } =
      useForm<SiAllocationRuleset>({
        initialValues,
        onSubmit,
        subscription: { submitting: true, pristine: true },
      });

    const handleCopy = useCallback(async () => {
      const data = await dispatch(copySiAllocationRuleset()).unwrap();
      alert.success(
        <TransMessage i18nKey="serviceSiAllocationRulesetCopied" />
      );
      history.push(`/allocation-rulesets/edit/${data.id}`);
    }, [alert, dispatch, history]);

    const handleDelete = useCallback(async () => {
      await dispatch(deleteSiAllocationRuleset(id)).unwrap();
      alert.success(<TransMessage i18nKey="siAllocationRulesetDeleted" />);
      history.push('/allocation-rulesets');
    }, [alert, dispatch, history, id]);

    const handleReset = useCallback(() => {
      form.reset();
    }, [form]);

    const modal = useModal();
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <FormProvider {...form}>
          <CardContent
            component="form"
            sx={{ py: 2, px: 3, pb: 3 }}
            onSubmit={handleSubmit}
          >
            <div className={classes.header}>
              <Typography variant="subtitle" fontWeight="700" paragraph>
                <TransTitle i18nKey="details" />
              </Typography>
              {siAllocationRuleset && (
                <Stack direction="row">
                  <>
                    <Button
                      onClick={handleCopy}
                      startIcon={<Icon name="clone" />}
                      variant="text"
                      size="small"
                    >
                      <TransButton i18nKey="copy" />
                    </Button>
                    <Button
                      onClick={modal.onOpen}
                      startIcon={<Icon name="delete" />}
                      variant="text"
                      size="small"
                      color="error"
                    >
                      <TransButton i18nKey="delete" />
                    </Button>
                    <ConfirmDeleteModal
                      handleDelete={handleDelete}
                      title={
                        <TransModal i18nKey="siAllocationRulesetDeletionTitle" />
                      }
                      description={
                        <TransModal
                          i18nKey="siAllocationRulesetDeletionDescription"
                          values={{ name: siAllocationRuleset.name }}
                        />
                      }
                      isOpen={modal.open}
                      onClose={modal.onClose}
                    />
                  </>
                </Stack>
              )}
            </div>
            <Grid container columns={5} spacing={2}>
              <Grid item xs={1}>
                <TextField name="name" label={<TransLabel i18nKey="name" />} />
              </Grid>

              <Grid item xs={1}>
                <SelectOwnerField disabled />
              </Grid>

              <Stack
                direction="row"
                sx={{ ml: 'auto', alignItems: 'end' }}
                spacing={1}
              >
                <Button
                  onClick={handleReset}
                  variant="text"
                  disabled={pristine}
                >
                  <TransButton i18nKey="resetChanges" />
                </Button>
                <Button
                  icon="check"
                  type="submit"
                  sx={{ ml: 2 }}
                  disabled={submitting}
                >
                  <TransButton
                    i18nKey={siAllocationRuleset?.id ? 'save' : 'create'}
                  />
                </Button>
              </Stack>
            </Grid>
          </CardContent>
        </FormProvider>
      </div>
    );
  };
