import { FC, useCallback, MouseEvent } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'store/utils';
import {
  deleteCompositionVehicle,
  duplicateVehicleComposition,
  setCurrentCompositionVehicle,
  TripEditPayload,
  updateCompositionVehicle,
} from 'features/composition/compositionActions';
import { readVehicle } from 'features/vehicle/vehicleActions';
import { ReactComponent as TrainTop } from '@fleet/shared/icons/carriage-top.svg';
import { ReactComponent as TrainMid } from '@fleet/shared/icons/carriage-mid.svg';
import { ReactComponent as TrainBot } from '@fleet/shared/icons/carriage-bot.svg';
import {
  compositionConstructSelector,
  compositionConstructVehicleSelector,
} from 'features/composition/compositionSelectors';
import { CompositionConstructVehicleDto } from '@fleet/widget/dto/composition';
import { setLoadingKey } from 'features/common/commonActions';
import { setActiveFloorIdx } from 'features/floor/floorActions';
import _partition from 'lodash/partition';
import { Button, Icon, Tooltip } from '@fleet/shared/mui';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

interface CompositionVehicleProps {
  vehicle: CompositionConstructVehicleDto;
  isFirst: boolean;
  isMid: boolean;
  isLast: boolean;
  readOnly?: boolean;
  actions?: {
    onVehicleChange: (id: number) => Promise<void>;
    updateComposition?: (payload: TripEditPayload) => void;
    deleteVehicle?: (vehicleId: number) => void;
    duplicateVehicle?: (vehicleId: number) => void;
  };
}

export const COMPOSITION_VEHICLE_LOADING = 'COMPOSITION_VEHICLE_LOADING';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      width: 42,
      height: 115,
      margin: '0 auto -1px',
      color: theme.palette.common.white,
      cursor: 'pointer',
    },
    blocked: {
      color: theme.palette.divider,
    },
    carriageIcon: {
      position: 'relative',
      fontSize: 0,
      lineHeight: 0,
      '&:hover, &.active': {
        color: 'rgba(var(--primary-rgb), 0.1)',
      },
    },
    carriageBlockedIcon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      margin: -8,
      color: theme.palette.secondary.light,
    },
    controlPopup: {
      display: 'flex',
      '& .MuiButton-text': {
        padding: '4px',
        minWidth: 'auto',
        color: theme.palette.text.primary,

        '&:hover': {
          color: theme.palette.action.hover,
        },

        '& .MuiButton-startIcon': {
          marginRight: 0,
        },
      },
    },
  }),
  {
    name: 'CompositionVehicle',
  }
);

export const CompositionVehicle: FC<CompositionVehicleProps> = ({
  vehicle,
  isFirst,
  isMid,
  isLast,
  readOnly,
  actions,
}) => {
  const classes = useStyles();
  const currentComposition = useSelector(compositionConstructSelector)!;
  const { id: compositionId } = currentComposition;
  const currentCompositionVehicle = useSelector(
    compositionConstructVehicleSelector
  );
  const dispatch = useDispatch();
  const handleSetCurrentVehicle = useCallback(async () => {
    dispatch(setLoadingKey(COMPOSITION_VEHICLE_LOADING));
    dispatch(setActiveFloorIdx(0));
    actions
      ? await actions.onVehicleChange(vehicle.orderNumber)
      : await dispatch(readVehicle(vehicle.vehicleId));
    dispatch(setCurrentCompositionVehicle(vehicle));
    dispatch(setLoadingKey(null));
  }, [actions, dispatch, vehicle]);
  const handleUpdateVehicle = useCallback(
    (params: Partial<CompositionConstructVehicleDto>) => {
      if (actions?.updateComposition) {
        const { vehicleCompositionDirection, compositionVehicles } =
          currentComposition!;
        const [[vehicleToUpdate], vehicles] = _partition(
          compositionVehicles,
          ({ vehicleId }) => vehicleId === vehicle.vehicleId
        );

        actions.updateComposition({
          tripRelationId: compositionId,
          vehicleCompositionDirectionId: vehicleCompositionDirection!.id,
          tripVehicleCompositionVehicles: [
            ...(params.orderNumber
              ? vehicles.map(({ orderNumber, ...vehicle }) => ({
                  ...vehicle,
                  orderNumber:
                    orderNumber === params.orderNumber
                      ? vehicleToUpdate.orderNumber
                      : orderNumber,
                }))
              : vehicles),
            { ...vehicleToUpdate, ...params },
          ],
        });
      } else {
        dispatch(
          updateCompositionVehicle({
            compositionId: `${compositionId}`,
            compositionVehicleId: `${vehicle.id}`,
            orderNumber: vehicle.orderNumber,
            number: vehicle.number!,
            salesOpeningPriority: vehicle!.salesOpeningPriority,
            salesOpeningThresholdPercentage:
              vehicle!.salesOpeningThresholdPercentage,
            isVehicleFlipped: vehicle.isVehicleFlipped,
            ...params,
          })
        );
      }
    },
    [actions, dispatch, compositionId, vehicle, currentComposition]
  );

  const handleChangeVehicleFlipped = useCallback(() => {
    handleUpdateVehicle({
      isVehicleFlipped: !vehicle.isVehicleFlipped,
    });
  }, [handleUpdateVehicle, vehicle]);

  const handleDuplicateVehicle = useCallback(() => {
    actions?.duplicateVehicle
      ? actions.duplicateVehicle(vehicle.vehicleId)
      : dispatch(
          duplicateVehicleComposition({
            compositionId: `${compositionId}`,
            compositionVehicleId: `${vehicle.id}`,
            orderNumber: vehicle.orderNumber + 1,
            number: vehicle.number!,
            isVehicleFlipped: vehicle.isVehicleFlipped,
          })
        );
  }, [actions, vehicle, dispatch, compositionId]);

  const handleDeleteVehicle = useCallback(() => {
    actions?.deleteVehicle
      ? actions.deleteVehicle(vehicle.id)
      : dispatch(
          deleteCompositionVehicle({
            compositionId: `${compositionId}`,
            compositionVehicleId: `${vehicle.id}`,
          })
        );
  }, [compositionId, vehicle.id, actions, dispatch]);

  const handleChangeVehicleOrder = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { orderNumber } = event.currentTarget.dataset;
      handleUpdateVehicle({
        orderNumber: Number(orderNumber!),
      });
    },
    [handleUpdateVehicle]
  );

  const isBlocked = Boolean(vehicle.blockingReason?.id);

  return (
    <div
      className={classNames(classes.root, {
        [classes.blocked]: isBlocked,
      })}
      onClick={handleSetCurrentVehicle}
    >
      <Tooltip
        theme="light"
        content={
          !readOnly && (
            <div className={classes.controlPopup}>
              <Button
                variant="text"
                onClick={handleChangeVehicleFlipped}
                icon="flip"
              />
              {!actions?.updateComposition && (
                <Button
                  variant="text"
                  onClick={handleDuplicateVehicle}
                  icon="clone"
                />
              )}
              <Button
                variant="text"
                onClick={handleDeleteVehicle}
                icon="trash"
              />
            </div>
          )
        }
      >
        <div
          className={classNames(classes.carriageIcon, {
            active: currentCompositionVehicle?.id === vehicle.id,
          })}
        >
          {isFirst && <TrainTop />}
          {isMid && <TrainMid />}
          {isLast && <TrainBot />}
          {isBlocked && (
            <Icon name="deactivate" className={classes.carriageBlockedIcon} />
          )}
        </div>
      </Tooltip>
      {!readOnly && (
        <Box
          sx={{
            position: 'absolute',
            left: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            display: 'flex',
            '& .MuiButton-text': {
              color: 'text.primary',
              minWidth: 0,
              padding: '4px',
            },
          }}
        >
          {(isLast || isMid) && (
            <Button
              variant="text"
              onClick={handleChangeVehicleOrder}
              label={<Icon name="direction-top" />}
              data-order-number={vehicle.orderNumber - 1}
            />
          )}
          {(isFirst || isMid) && (
            <Button
              variant="text"
              onClick={handleChangeVehicleOrder}
              label={<Icon name="direction-bottom" />}
              data-order-number={vehicle.orderNumber + 1}
            />
          )}
        </Box>
      )}
    </div>
  );
};
