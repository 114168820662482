import { useCallback, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import { PreparedPaletteElement } from '@fleet/widget/dto/element';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import _groupBy from 'lodash/groupBy';
import { Element } from 'components/elements/Element';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { useHistory } from 'react-router';
import { ElementTab } from 'dto/element';
import { TransTitle } from 'i18n/trans/title';
import { TransTab } from 'i18n/trans/tab';
import { CardHeader, Loadable } from '@fleet/shared';
import { useSelector } from 'store/utils';
import {
  classifiersSelector,
  floorElementsSelector,
} from 'features/classification/classificationSelectors';
import { makeStyles } from '@mui/styles';
import { ElementCategory } from '@fleet/widget/dto/element';
import _values from 'lodash/values';
import { Card, CardContent } from '@mui/material';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    divider: {
      margin: '0 -24px 16px -24px',
    },
    content: {
      flex: 1,
    },
  }),
  {
    name: 'Elements',
  }
);

const tabs = ['sign', 'internal', 'place'] as const;
const [defaultTab] = tabs;

export function Elements() {
  const classes = useStyles();

  const floorElements = useSelector(floorElementsSelector);
  const preparedPaletteFloorElements = useMemo(() => {
    return _values(floorElements).reduce<
      Record<ElementCategory, Array<PreparedPaletteElement>>
    >(
      (acc, el) => ({
        ...acc,
        [el.category.toLowerCase()]: [...acc[el.category], el],
      }),
      {
        compartment: [],
        sign: [],
        internal: [],
        place: [],
        amenity: [],
      }
    );
  }, [floorElements]);

  const {
    path,
    params: { category = defaultTab },
  } = useRouteMatch<{ category?: ElementTab }>();

  const history = useHistory();
  const handleChangeTab = useCallback(
    (e, value) => {
      history.replace(
        generatePath(path, {
          category: value,
        })
      );
    },
    [history, path]
  );
  const renderElement = useCallback(
    (element: PreparedPaletteElement) => (
      <Grid key={element.elementId} item xs={1}>
        <Element data={element} />
      </Grid>
    ),
    []
  );

  const renderElements = useCallback(() => {
    const elements = preparedPaletteFloorElements[category];
    switch (category) {
      case 'internal':
        const {
          walls = [],
          wallsAngled = [],
          wallsTemp = [],
          tables = [],
          doors = [],
        } = _groupBy(elements, ({ paletteCategory }) => paletteCategory);

        return (
          <>
            <Grid item xs={12}>
              <Typography variant={'subtitle'}>
                <TransTitle i18nKey="walls" />
              </Typography>
            </Grid>
            {[...walls, ...wallsAngled, ...wallsTemp].map(renderElement)}

            <Grid item xs={12}>
              <Typography variant={'subtitle'}>
                <TransTitle i18nKey="windowsAndDoors" />
              </Typography>
            </Grid>
            {doors.map(renderElement)}

            <Grid item xs={12}>
              <Typography variant={'subtitle'}>
                <TransTitle i18nKey="tables" />
              </Typography>
            </Grid>
            {tables.map(renderElement)}
          </>
        );
      default:
        return elements.map(renderElement);
    }
  }, [preparedPaletteFloorElements, category, renderElement]);

  const { COMFORT_LEVEL, SIGN_SUB_TYPE, INTERNAL_SUB_TYPE, PLACE_SUB_TYPE } =
    useSelector(classifiersSelector);
  const loading = [
    !COMFORT_LEVEL.length,
    !SIGN_SUB_TYPE.length,
    !INTERNAL_SUB_TYPE.length,
    !PLACE_SUB_TYPE.length,
  ].includes(true);

  return (
    <Loadable loading={loading}>
      <Card elevation={0} square className={classes.root}>
        <TabContext value={category}>
          <CardHeader title={<TransTitle i18nKey="floorElementSettings" />} />
          <CardContent className={classes.content}>
            <TabList
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeTab}
            >
              {tabs.map((key) => (
                <Tab key={key} value={key} label={<TransTab i18nKey={key} />} />
              ))}
            </TabList>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Switch>
                <Route
                  exact
                  path="/elements/:category/:id?"
                  render={renderElements}
                />
                <Redirect
                  to={generatePath(path, {
                    category: defaultTab,
                  })}
                />
              </Switch>
            </Grid>
          </CardContent>
        </TabContext>
      </Card>
    </Loadable>
  );
}
