import type { FC, FocusEvent, FocusEventHandler } from 'react';
import type { ManageableSpace } from '@fleet/widget/dto/floor';
import type { TripVehicle } from 'dto/trip';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { useTranslation } from 'react-i18next';
import {
  activeFloorSelector,
  floorListSelector,
} from 'features/floor/floorSelectors';
import {
  Input,
  ReadOnlyField as Field,
  ToggleButtonGroup,
} from '@fleet/shared/mui';
import {
  compositionConstructSelector,
  compositionConstructVehicleSelector,
} from 'features/composition/compositionSelectors';
import { currentVehicleSelector } from 'features/vehicle/vehicleSelector';
import _pickBy from 'lodash/pickBy';
import { ModificationHistory } from 'components/propertiesPanel/ModificationHistory';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { getAssignedComposition } from 'features/composition/compositionActions';
import { makeStyles } from '@mui/styles';
import { TransTitle } from 'i18n/trans/title';
import { TransLabel } from 'i18n/trans/label';
import { CarriageBlockingProperty } from 'components/propertiesPanel/CarriageBlockingProperty';
import { api, decimalToPercentage } from '@fleet/shared';
import { Divider, Typography } from '@mui/material';
import {
  SalesOpening,
  SalesOpeningProps,
} from 'components/propertiesPanel/SalesOpening';

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      '& .MuiFormControl-root': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& > label': {
          fontWeight: 700,
          marginRight: 10,
          overflow: 'inherit',
          color: theme.palette.text.primary,
          marginBottom: 0,
        },
        '& > *': {
          fontSize: 14,
        },
      },
    },
  }),
  {
    name: 'CarriageProperties',
  }
);

export interface CarriagePropertiesProps {
  updateCompositionData?: (
    fieldName: 'name' | 'code' | 'number',
    e: FocusEvent<HTMLInputElement>
  ) => void;
  onManageableSpaceChange?: (
    payload: Omit<ManageableSpace, 'type' | 'typeId '>
  ) => void;
  onDirectionChange?: () => void;
  onCarriageChange?: (payload: Partial<TripVehicle>) => void;
  allowStandingPlacesEdit?: boolean;
  allowBlocking?: boolean;
}

export const CarriageProperties: FC<CarriagePropertiesProps> = ({
  onManageableSpaceChange,
  onDirectionChange,
  onCarriageChange,
  updateCompositionData,
  allowStandingPlacesEdit,
  allowBlocking = true,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [processHistoryShown, setProcessHistoryShown] = useState(false);
  const compositionConstruct = useSelector(compositionConstructSelector);
  const isTrainComposition = useMemo(
    () =>
      compositionConstruct?.transportationTypeId ===
      'LINE_TRANSPORTATION_TYPE.TRAIN',
    [compositionConstruct?.transportationTypeId]
  );
  const compositionTotalCapacity = useMemo(
    () => compositionConstruct?.totalCapacity,
    [compositionConstruct?.totalCapacity]
  );
  const currentCompositionVehicle = useSelector(
    compositionConstructVehicleSelector
  )!;

  const currentVehicle = useSelector(currentVehicleSelector);
  const [
    maxAllowedStandingPassengerCount,
    setMaxAllowedStandingPassengerCount,
  ] = useState(currentVehicle?.maxAllowedStandingPassengerCount);

  useEffect(() => {
    currentVehicle &&
      setMaxAllowedStandingPassengerCount(
        currentVehicle.maxAllowedStandingPassengerCount
      );
  }, [currentVehicle, currentVehicle?.id]);
  const businessEntityId = useSelector(currentBusinessEntityIdSelector);
  const dispatch = useDispatch();
  const changeMaxAllowedStandingPassengerCount = useCallback(
    async (event: FocusEvent<HTMLInputElement>) => {
      const value = Number(event.target.value);
      setMaxAllowedStandingPassengerCount(value);
      await api.put(
        `/organizations/${businessEntityId}/trip-vehicle-compositions/${compositionConstruct?.id}/vehicle-composition-vehicle-settings`,
        {
          tripVehicleCompositionVehicles: [
            {
              id: currentVehicle?.id,
              maxNumberOfStandingPassengers: value,
            },
          ],
        }
      );
      await dispatch(
        getAssignedComposition({
          compositionId: `${compositionConstruct!.id}`,
          type: 'trip',
        })
      );
    },
    [compositionConstruct, businessEntityId, currentVehicle?.id, dispatch]
  );

  const floors = useSelector(floorListSelector);
  const currentFloor = useSelector(activeFloorSelector);
  const { totalPlaces, ...placesByClass } = useMemo(() => {
    return floors.reduce<{ [index: string]: number }>(
      (acc, cur) => {
        const { places = [] } = cur;
        acc.totalPlaces += places.length;
        places.forEach(({ inventoryClass }) => {
          if (!inventoryClass) return;

          if (acc[inventoryClass.name!]) {
            acc[inventoryClass.name!]++;
          } else {
            acc[inventoryClass.name!] = 1;
          }
        });

        return acc;
      },
      { totalPlaces: 0 }
    );
  }, [floors]);

  const presentPlacesByClass = useMemo(
    () => _pickBy(placesByClass, (val) => val > 0),
    [placesByClass]
  );

  const { totalManageableSpaces, ...spacesByType } = useMemo(() => {
    if (!currentFloor) return { totalManageableSpaces: 0 };
    return currentFloor.manageableSpaces.reduce<{ [index: string]: number }>(
      (acc, { count, type }) => {
        const typeName = type!.name!;
        const typeCount = Number(count);

        return {
          ...acc,
          totalManageableSpaces: acc.totalManageableSpaces + typeCount,
          [typeName]: (acc[typeName] || 0) + typeCount,
        };
      },
      { totalManageableSpaces: 0 }
    );
  }, [currentFloor]);

  const salesOpeningValues = useMemo(
    () => ({
      salesOpeningPriority:
        currentCompositionVehicle?.salesOpeningPriority ??
        currentVehicle?.salesOpeningPriority,
      salesOpeningThresholdPercentage:
        currentCompositionVehicle?.salesOpeningThresholdPercentage ??
        currentVehicle?.salesOpeningThresholdPercentage,
    }),
    [
      currentCompositionVehicle?.salesOpeningPriority,
      currentCompositionVehicle?.salesOpeningThresholdPercentage,
      currentVehicle?.salesOpeningPriority,
      currentVehicle?.salesOpeningThresholdPercentage,
    ]
  );

  const handleCarriageChange = useCallback<
    Required<SalesOpeningProps>['onChange']
  >(
    (values) => {
      onCarriageChange?.(values);
    },
    [onCarriageChange]
  );

  const handleCarriageNumberChange = useCallback<
    FocusEventHandler<HTMLInputElement>
  >(
    (e) => {
      onCarriageChange?.({
        number: e.target.value,
      });
    },
    [onCarriageChange]
  );

  if (!currentCompositionVehicle && updateCompositionData) {
    return (
      <div className="carriage-properties">
        <h3>
          <TransTitle i18nKey="properties" />
        </h3>
        <Input
          value={compositionConstruct!.name}
          label={<TransLabel i18nKey="name" />}
          onBlur={updateCompositionData.bind(null, 'name')}
          required
        />
      </div>
    );
  }

  return (
    <div className="carriage-properties">
      <h3>
        <TransTitle
          i18nKey={
            processHistoryShown ? 'modificationHistory' : 'vehicleComposition'
          }
        />
      </h3>
      <ModificationHistory
        open={processHistoryShown}
        onToggle={() => setProcessHistoryShown(!processHistoryShown)}
      />
      {updateCompositionData && (
        <>
          <Input
            value={compositionConstruct!.name}
            label={<TransLabel i18nKey="name" />}
            className="keep-current-style"
            onBlur={updateCompositionData.bind(null, 'name')}
            margin="dense"
            multiline
            required
          />
          <Input
            value={compositionConstruct!.code ?? ''}
            label={<TransLabel i18nKey="code" />}
            className="keep-current-style"
            onBlur={updateCompositionData.bind(null, 'code')}
            margin="dense"
          />
        </>
      )}
      <div className={classes.wrapper}>
        {!updateCompositionData && (
          <>
            <Field
              label={<TransLabel i18nKey="name" />}
              value={compositionConstruct!.name}
              margin="dense"
            />
            <Field
              label={<TransLabel i18nKey="code" />}
              value={compositionConstruct!.code}
              margin="dense"
            />
            {typeof compositionConstruct?.hasVehicleCompositionBeenChanged ===
              'boolean' && (
              <Field
                label={<TransLabel i18nKey="isCompositionModified" />}
                value={
                  <TransLabel
                    i18nKey={
                      compositionConstruct.hasVehicleCompositionBeenChanged
                        ? 'yes'
                        : 'no'
                    }
                  />
                }
                margin="dense"
              />
            )}
          </>
        )}
        {compositionTotalCapacity && (
          <>
            <div className="total-places">
              <Field
                label={<TransLabel i18nKey="totalNumberOfPlaces" />}
                value={compositionTotalCapacity.totalPlaces}
                margin="dense"
              />
              {Object.keys(compositionTotalCapacity.inventoryClasses).map(
                (className) => (
                  <div key={className} className="field sub">
                    <span>{className}</span>
                    <span>
                      {compositionTotalCapacity?.inventoryClasses[className]}
                    </span>
                  </div>
                )
              )}
            </div>
            <div className="manageable-spaces">
              <Field
                label={<TransLabel i18nKey="totalNumberOfManageablePlaces" />}
                value={compositionTotalCapacity.totalManageableSpaces}
                margin="dense"
              />
              {Object.keys(compositionTotalCapacity.manageableSpaces).map(
                (name, idx) => (
                  <div key={idx} className="field sub">
                    <span>{name}</span>
                    <span>
                      {compositionTotalCapacity.manageableSpaces[name]}
                    </span>
                  </div>
                )
              )}
            </div>
          </>
        )}

        {!onCarriageChange && (
          <>
            <Divider sx={{ my: 3 }} />

            <Typography variant="paragraph" fontWeight={700}>
              <TransTitle
                i18nKey="vehicleSalesOpening"
                {...(isTrainComposition && {
                  i18nKey: 'carriageSalesOpening',
                })}
              />
            </Typography>
            <Field
              label={<TransLabel i18nKey="priorityOrder" />}
              value={salesOpeningValues?.salesOpeningPriority ?? ''}
              margin="dense"
            />
            <Field
              label={<TransLabel i18nKey="threshold" />}
              value={
                (salesOpeningValues?.salesOpeningThresholdPercentage &&
                  decimalToPercentage(
                    salesOpeningValues?.salesOpeningThresholdPercentage
                  )) ??
                ''
              }
              margin="dense"
            />
          </>
        )}
      </div>

      {onCarriageChange && (
        <SalesOpening
          title="vehicleSalesOpening"
          {...(isTrainComposition && {
            title: 'carriageSalesOpening',
          })}
          initialValues={salesOpeningValues}
          onChange={handleCarriageChange}
        />
      )}

      <div className={classes.wrapper}>
        <Divider sx={{ my: 3 }} />

        <h3>
          <TransTitle
            i18nKey="vehicleProperties"
            {...(isTrainComposition && {
              i18nKey: 'carriageProperties',
            })}
          />
        </h3>
        {allowBlocking && <CarriageBlockingProperty />}
        <Field
          label={<TransLabel i18nKey="planName" />}
          value={currentVehicle?.name}
          margin="dense"
          // tooltip, TODO, revert prop in shared
        />
        {onCarriageChange && (
          <Input
            name="number"
            label={<TransLabel i18nKey="displayCode" />}
            value={
              currentCompositionVehicle?.number ?? currentVehicle?.number ?? ''
            }
            onBlur={handleCarriageNumberChange}
            margin="dense"
          />
        )}
        <Field
          label={<TransLabel i18nKey="code" />}
          value={currentVehicle?.code}
          margin="dense"
        />
        <Field
          label={<TransLabel i18nKey="floors" />}
          value={floors.length}
          margin="dense"
        />
        {isTrainComposition && (
          <>
            <Field
              label={<TransLabel i18nKey="carriageOrder" />}
              value={currentCompositionVehicle?.orderNumber}
              margin="dense"
            />
            <Field
              label={<TransLabel i18nKey="carriageDirection" />}
              margin="dense"
            >
              {onDirectionChange ? (
                <ToggleButtonGroup
                  color="secondary"
                  value={
                    currentCompositionVehicle.isVehicleFlipped ? 'down' : 'up'
                  }
                  options={[
                    {
                      value: 'up',
                      label: '',
                      icon: 'direction-top',
                      tooltip: t('label.up', 'Up')!,
                    },
                    {
                      value: 'down',
                      label: '',
                      icon: 'direction-bottom',
                      tooltip: t('label.down', 'Down')!,
                    },
                  ]}
                  onChange={onDirectionChange}
                />
              ) : (
                <TransLabel
                  i18nKey={
                    currentCompositionVehicle?.isVehicleFlipped
                      ? 'reverse'
                      : 'forward'
                  }
                />
              )}
            </Field>
          </>
        )}
        <div className="total-places">
          {allowStandingPlacesEdit ? (
            <Input
              type="number"
              label={<TransLabel i18nKey="numberOfStandingPlaces" />}
              value={maxAllowedStandingPassengerCount ?? 0}
              onBlur={changeMaxAllowedStandingPassengerCount}
              margin="dense"
            />
          ) : (
            <Field
              label={<TransLabel i18nKey="numberOfStandingPlaces" />}
              value={maxAllowedStandingPassengerCount ?? 0}
              margin="dense"
              fullWidth
            />
          )}

          <Field
            label={<TransLabel i18nKey="totalNumberOfPlaces" />}
            value={totalPlaces}
            margin="dense"
          />
          {Object.keys(presentPlacesByClass).map((className) => (
            <div key={className} className="field sub">
              <span>{className}</span>
              <span>{placesByClass[className]}</span>
            </div>
          ))}
        </div>
        {!!totalManageableSpaces && (
          <div className="manageable-spaces">
            <Field
              label={
                <TransLabel
                  i18nKey="manageableSpaceForFloor"
                  values={{ num: currentFloor?.number }}
                />
              }
              value={totalManageableSpaces}
              margin="dense"
            />
            {onManageableSpaceChange
              ? currentFloor?.manageableSpaces.map(
                  ({ count, type, ...space }, idx) => (
                    <Fragment key={idx}>
                      <Field
                        label={<TransLabel i18nKey="type" />}
                        value={type!.name}
                        margin="dense"
                      />
                      <Input
                        label={<TransLabel i18nKey="count" />}
                        value={count!}
                        onBlur={({ target }) =>
                          onManageableSpaceChange({
                            ...space,
                            count: +target.value,
                          })
                        }
                        type="number"
                        margin="dense"
                        resetOnFail
                      />
                    </Fragment>
                  )
                )
              : Object.keys(spacesByType).map((name, idx) => (
                  <div key={idx} className="field sub">
                    <span>{name}</span>
                    <span>{spacesByType[name]}</span>
                  </div>
                ))}
          </div>
        )}
      </div>
    </div>
  );
};
