import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Icon, Modal, useModal } from '@fleet/shared';
import {
  CheckboxGroup,
  CheckboxGroupProps,
} from '@fleet/shared/mui/Checkbox/CheckboxGroup';
import { TransTitle } from 'i18n/trans/title';
import { Button, Typography } from '@mui/material';
import { useSelector } from 'store/utils';
import { classifiersSelector } from 'features/classification/classificationSelectors';
import { ModalProps } from '@fleet/shared/mui/Modal';
import { InventoryBlockingLevel, InventoryType } from 'dto/organization';
import { Classifier } from '@fleet/shared/dto/classifier';
import { InventoryBlockingReasonSelect } from 'components/common/select/InventoryBlockingReason';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { makeStyles } from '@mui/styles';
import { TransButton } from 'i18n/trans/button';

const useStyles = makeStyles(
  (theme) => ({
    list: {
      paddingLeft: theme.spacing(3),
      listStyle: 'disc',
      '& .MuiListItem-root': { display: 'list-item', paddingLeft: 0 },
    },
    select: {
      marginTop: 0,
    },
  }),
  {
    name: 'Properties',
  }
);

interface ConfirmPayload {
  properties?: Array<{ id: string }>;
  blockingReason?: { id: string };
  isBlocked?: boolean;
}

type PropertiesValue = Array<string>;
export interface PropertiesProps {
  typeId?: InventoryType;
  properties?: Array<{ id: string }>;
  blockingReason?: { id: string };
  onConfirm: (payload: ConfirmPayload) => void;
  propertiesOnly?: boolean;
}

export const Properties: FC<PropertiesProps> = ({
  typeId,
  properties,
  blockingReason,
  onConfirm,
  propertiesOnly = false,
}) => {
  const { COMPARTMENT_PROPERTY, BED_PROPERTY, SEAT_PROPERTY } =
    useSelector(classifiersSelector);
  const [value, setValue] = useState<PropertiesValue>([]);
  const propertyIds = useMemo(
    () => (typeId && properties ? properties.map(({ id }) => id) : []),
    [properties, typeId]
  );
  useEffect(() => {
    setValue(propertyIds);
  }, [propertyIds]);

  const propertiesByTypeId = useMemo(
    () => ({
      [InventoryType.COMPARTMENT]: COMPARTMENT_PROPERTY,
      [InventoryType.BED]: BED_PROPERTY,
      [InventoryType.SEAT]: SEAT_PROPERTY,
    }),
    [BED_PROPERTY, COMPARTMENT_PROPERTY, SEAT_PROPERTY]
  );
  const options = useMemo(() => {
    if (typeId && typeId in propertiesByTypeId)
      return propertiesByTypeId[typeId as keyof typeof propertiesByTypeId].map(
        ({ id, name }) => ({
          label: name,
          value: id,
        })
      );
    return [];
  }, [typeId, propertiesByTypeId]);

  const modal = useModal();
  const handleClose = useCallback<Required<ModalProps>['onClose']>(() => {
    setValue(propertyIds);
    modal.onClose();
  }, [modal, propertyIds]);

  const propertiesMap = useMemo<Map<string, Classifier>>(
    () =>
      new Map(
        [...COMPARTMENT_PROPERTY, ...BED_PROPERTY, ...SEAT_PROPERTY].map(
          (prop) => [prop.id, prop],
          {}
        )
      ),
    [BED_PROPERTY, COMPARTMENT_PROPERTY, SEAT_PROPERTY]
  );
  const handleConfirm = useCallback(() => {
    onConfirm({
      properties: value.filter(Boolean).map((id) => propertiesMap.get(id)!),
    });
    modal.onClose();
  }, [modal, onConfirm, propertiesMap, value]);

  const handleChange = useCallback<
    Required<CheckboxGroupProps<PropertiesValue>>['onChange']
  >((_, propertyIds) => setValue(propertyIds), []);

  const classes = useStyles();
  return (
    <>
      <Typography variant="body1" gutterBottom>
        <TransTitle i18nKey="properties" />
      </Typography>
      {!propertiesOnly && (
        <InventoryBlockingReasonSelect
          className={classes.select}
          level={
            typeId === InventoryType.COMPARTMENT
              ? InventoryBlockingLevel.COMPARTMENT
              : InventoryBlockingLevel.PLACE
          }
          value={blockingReason?.id ?? null}
          onChange={(blockedReason) => {
            onConfirm({
              isBlocked: Boolean(blockedReason),
              blockingReason: { id: blockedReason },
            });
          }}
        />
      )}
      {Boolean(value.length) && (
        <List dense className={classes.list}>
          {value.map(
            (id) =>
              propertiesMap.has(id) && (
                <ListItem key={id}>{propertiesMap.get(id)!.name}</ListItem>
              )
          )}
        </List>
      )}

      <Button onClick={modal.onOpen} variant="text" size="small">
        <TransButton i18nKey="addEdit" />
      </Button>
      <Modal
        open={modal.open}
        onClose={handleClose}
        title={<TransTitle i18nKey="properties" />}
        actionButton={
          <Button
            variant="contained"
            startIcon={<Icon name="check" />}
            onClick={handleConfirm}
          >
            <TransButton i18nKey="confirm" />
          </Button>
        }
        sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}
        maxWidth="sm"
      >
        <CheckboxGroup<PropertiesValue>
          name="propertyIds"
          options={options}
          value={value}
          onChange={handleChange}
        />
      </Modal>
    </>
  );
};
