import type {
  CompartmentFloorElement,
  FloorElement,
  Snug,
} from '@fleet/widget/dto/floor';
import type { FC } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

export interface SelectionManagerState {
  compartment?: CompartmentFloorElement;
  nodes: Array<FloorElement>;
  snug?: Snug;
}

interface SelectionManagerActions {
  setCompartment(compartment?: CompartmentFloorElement): void;
  setNodes(nodes: Array<FloorElement>): void;
  setSnug(snug?: Snug): void;
}

const selectionManagerInitialState = { nodes: [] };
export const SelectionManagerStateContext =
  createContext<SelectionManagerState>(selectionManagerInitialState);
export const SelectionManagerActionsContext =
  createContext<SelectionManagerActions>({} as SelectionManagerActions);

export const SelectionManagerProvider: FC = ({ children }) => {
  const [state, setState] = useState<SelectionManagerState>(
    selectionManagerInitialState
  );
  const actions = useMemo<SelectionManagerActions>(
    () => ({
      setCompartment(compartment) {
        setState((old) => ({ ...old, compartment }));
      },
      setNodes(nodes) {
        setState((old) => ({ ...old, nodes }));
      },
      setSnug(snug) {
        setState((old) => ({ ...old, snug }));
      },
    }),
    []
  );

  return (
    <SelectionManagerActionsContext.Provider value={actions}>
      <SelectionManagerStateContext.Provider value={state}>
        {children}
      </SelectionManagerStateContext.Provider>
    </SelectionManagerActionsContext.Provider>
  );
};

export const useSelectionManager = () =>
  [
    useContext(SelectionManagerStateContext),
    useContext(SelectionManagerActionsContext),
  ] as const;
