import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransButton = createTrans({
  add: <Trans i18nKey="label.add" defaults="Add" />,
  addEdit: <Trans i18nKey="button.addEdit" defaults="Add/edit" />,
  addNew: <Trans i18nKey="button.addNew" defaults="Add new" />,
  addNewTranslation: (
    <Trans i18nKey="button.addNewTranslation" defaults="Add new translation" />
  ),
  assign: <Trans i18nKey="button.assign" defaults="Assign" />,
  back: <Trans i18nKey="button.back" defaults="Back" />,
  cancel: <Trans i18nKey="button.cancel" defaults="Cancel" />,
  close: <Trans i18nKey="button.close" defaults="Close" />,
  confirm: <Trans i18nKey="button.confirm" defaults="Confirm" />,
  copy: <Trans i18nKey="button.copy" defaults="Copy" />,
  create: <Trans i18nKey="button.create" defaults="Create" />,
  delete: <Trans i18nKey="button.delete" defaults="Delete" />,
  deleteFile: <Trans i18nKey="button.deleteFile" defaults="Delete file" />,
  deleteSelected: (
    <Trans i18nKey="button.deleteSelected" defaults="Delete selected" />
  ),
  duplicate: <Trans i18nKey="button.duplicate" defaults="Duplicate" />,
  edit: <Trans i18nKey="button.edit" defaults="Edit" />,
  editContents: (
    <Trans i18nKey="button.editContents" defaults="Edit contents" />
  ),
  editContentsStop: (
    <Trans i18nKey="button.editContentsStop" defaults="Stop edit contents" />
  ),
  editStop: <Trans i18nKey="button.stopEditing" defaults="Stop editing" />,
  lineTemplateAssignment: (
    <Trans
      i18nKey="button.lineTemplateAssignment"
      defaults="Line template assignment"
    />
  ),
  manage: <Trans i18nKey="button.manage" defaults="Manage" />,
  newTrainConstruction: (
    <Trans
      i18nKey="button.newTrainConstruction"
      defaults="New train construction"
    />
  ),
  newVehicleName: (
    <Trans i18nKey="button.newVehicleName" defaults="New {{vehicleName}}" />
  ),
  replace: <Trans i18nKey="label.replace" defaults="Replace" />,
  reset: <Trans i18nKey="button.reset" defaults="Reset" />,
  resetChanges: (
    <Trans i18nKey="button.resetChanges" defaults="Reset changes" />
  ),
  resetFilters: (
    <Trans i18nKey="button.resetFilters" defaults="Reset filters" />
  ),
  save: <Trans i18nKey="button.save" defaults="Save" />,
  search: <Trans i18nKey="button.search" defaults="Search" />,
  snugAddNew: <Trans i18nKey="button.snugAddNew" defaults="Add new snug" />,
  snugsHide: <Trans i18nKey="button.snugsHide" defaults="Hide snugs" />,
  snugsShow: <Trans i18nKey="button.snugsShow" defaults="Show snugs" />,
  tripAssignment: (
    <Trans i18nKey="button.tripAssignment" defaults="Trip assignment" />
  ),
  update: <Trans i18nKey="button.update" defaults="Update" />,
  uploadNewFile: (
    <Trans i18nKey="button.uploadNewFile" defaults="Upload new file" />
  ),
});
