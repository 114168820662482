import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { AddButton, CardHeader, Layout, Loadable } from '@fleet/shared';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { Route, Switch, useHistory } from 'react-router-dom';
import { SiServiceAllocationRulesetTable } from 'routes/siServiceAllocationRuleset/SiServiceAllocationRulesetTable';
import { SiServiceAllocationRulesetDetails } from 'routes/siServiceAllocationRuleset/SiServiceAllocationRulesetDetails';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { getSiAllocationRulesets } from 'features/siAllocationRuleset/siAllocationRulesetActions';
import { serviceSiAllocationRulesetsSelector } from 'features/loading/loadingSelectors';

const useStyles = makeStyles(
  () => ({
    layout: { padding: 0 },
  }),
  {
    name: 'SiServiceAllocationRulesetList',
  }
);

interface SiServiceAllocationRulesetListProps {}

export const SiServiceAllocationRulesetList: FC<SiServiceAllocationRulesetListProps> =
  () => {
    const dispatch = useDispatch();
    const loading = useSelector(serviceSiAllocationRulesetsSelector);

    useEffect(() => {
      dispatch(getSiAllocationRulesets());
    }, [dispatch]);

    const history = useHistory();

    const classes = useStyles();
    return (
      <Loadable loading={loading}>
        <Layout
          componentClasses={{ root: classes.layout }}
          header={
            <CardHeader
              title={<TransTitle i18nKey="serviceSiAllocationRuleset" />}
            >
              <AddButton
                onClick={() =>
                  history.push(`/service-allocation-rulesets/create`)
                }
              >
                <TransButton i18nKey="add" />
              </AddButton>
            </CardHeader>
          }
          drawer={
            <Switch>
              <Route
                path={`/service-allocation-rulesets/:action(create|edit)/:id?`}
                component={SiServiceAllocationRulesetDetails}
                exact
              />
            </Switch>
          }
        >
          <SiServiceAllocationRulesetTable />
        </Layout>
      </Loadable>
    );
  };
