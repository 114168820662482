import { FC, useEffect, useState } from 'react';
import { getCompositions } from 'features/composition/compositionActions';
import {
  getLines,
  getSubContractors,
} from 'features/classification/classificationActions';
import { useDispatch } from 'store/utils';
import { Loader } from '@fleet/shared/mui';
import classNames from 'classnames';

interface RelationsSearchWrapperProps {
  className?: string;
  onMount?: () => void;
  entity: 'lineTemplate' | 'trip';
}

export const RelationsSearchWrapper: FC<RelationsSearchWrapperProps> = ({
  className,
  entity,
  onMount,
  children,
}) => {
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await Promise.all([
        onMount && onMount(),
        dispatch(getCompositions()),
        dispatch(getLines()),
        dispatch(getSubContractors()),
      ]);

      setInitialLoading(false);
    })();
  }, [dispatch, entity, onMount]);

  if (initialLoading) return <Loader active size="fullscreen" />;

  return (
    <div className={classNames('relations-search-wrapper', className)}>
      {children}
    </div>
  );
};
