import { api, getErrHandler } from '@fleet/shared';
import { FC, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useAuth } from 'react-oidc-context';

const AxiosErrorsInterceptorWrapper: FC = ({ children }) => {
  const auth = useAuth();
  const alert = useAlert();
  const [errInterceptorLoaded, setErrInterceptorLoaded] = useState<number>();

  useEffect(() => {
    if (auth.isLoading) {
      return;
    }

    const errInterceptorLoaded = api.interceptors.response.use(
      (res) => res,
      getErrHandler(alert, () => auth.signinRedirect())
    );
    setErrInterceptorLoaded(errInterceptorLoaded);

    return () => {
      setErrInterceptorLoaded(undefined);
      api.interceptors.response.eject(errInterceptorLoaded);
    };
  }, [auth, alert]);

  if (!errInterceptorLoaded) {
    return null;
  }

  return <>{children}</>;
};

export default AxiosErrorsInterceptorWrapper;
