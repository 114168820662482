import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { currentSiAllocationRulesetRulesSelector } from 'features/siAllocationRuleset/siAllocationRulesetSelectors';
import { inventoryClassCompartmentBedOptionsSelector } from 'features/classification/classificationSelectors';
import {
  Button,
  ConfirmDeleteModal,
  formatToPercentage,
  FormProvider,
  Icon,
  parseToDecimal,
  Table,
  TableColumns,
  TextField,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useModal,
  useRowEditActions,
} from '@fleet/shared';
import { SiAllocationRulesetRule } from 'dto/siAllocationRuleset';
import { Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';
import { TransModal } from 'i18n/trans/modal';
import { TransTableHead } from 'i18n/trans/table';
import { useRowSelect } from 'react-table';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { FormTableRowUpdate } from '@fleet/shared/hooks/useFormTable';
import Grid from '@mui/material/Grid';
import {
  createUpdateSiAllocationRulesetRule,
  deleteSiAllocationRulesetRule,
} from 'features/siAllocationRuleset/siAllocationRulesetActions';

export const SiAllocationRulesetRules: FC = () => {
  const allocationRulesetRules = useSelector(
    currentSiAllocationRulesetRulesSelector
  );

  const ruleDeletionModal = useModal();

  const inventoryClassOptions = useSelector(
    inventoryClassCompartmentBedOptionsSelector
  );
  const genderizationRuleOptions = useClassificationOptions(
    ClassificationGroup.GENDERIZATION_RULE
  );
  const columns = useMemo<TableColumns<SiAllocationRulesetRule>>(
    () => [
      {
        id: 'inventoryClass.id',
        accessor: ({ inventoryClass }) => inventoryClass?.id,
        Header: <TransTableHead i18nKey="inventoryClass" />,
        type: 'select',
        editableProps: {
          options: inventoryClassOptions,
          filterDisabledOptions: true,
        },
      },
      {
        id: 'genderizationRule.id',
        accessor: ({ genderizationRule }) => genderizationRule?.id,
        Header: <TransTableHead i18nKey="genderizationRule" />,
        type: 'select',
        editableProps: {
          options: genderizationRuleOptions,
          required: false,
          showEmptyOption: true,
        },
      },
      {
        accessor: 'allocatableInventoryUpperLimitPercentage',
        Header: <TransTableHead i18nKey="allocatableInventoryUpperLimit" />,
        editableProps: () => ({
          parse: parseToDecimal,
          format: formatToPercentage,
          formatOnBlur: false,
        }),
        wrapper: (field, cell) =>
          Boolean(cell.row.state.editable)
            ? field
            : formatToPercentage(cell.value),
      },
      {
        accessor: 'id',
        Header: <TransTableHead i18nKey="daysBeforeDeparture" />,
        Cell: ({
          row: {
            index,
            state: { editable },
            original,
          },
        }) =>
          editable ? (
            <Grid container>
              <Grid item xs>
                <TextField
                  name={`rows[${index}].daysPriorToDepartureFrom`}
                  required
                />
              </Grid>
              <Grid item xs={1} sx={{ textAlign: 'center', pt: 1 }}>
                -
              </Grid>
              <Grid item xs>
                <TextField
                  name={`rows[${index}].daysPriorToDepartureTo`}
                  required
                />
              </Grid>
            </Grid>
          ) : (
            [
              original.daysPriorToDepartureFrom,
              original.daysPriorToDepartureTo,
            ].join('-')
          ),
      },
    ],
    [genderizationRuleOptions, inventoryClassOptions]
  );
  const data = useMemo(
    () => allocationRulesetRules ?? [],
    [allocationRulesetRules]
  );
  const { form } = useForm<{ rows: Array<SiAllocationRulesetRule> }>({
    initialValues: { rows: data },
  });

  const dispatch = useDispatch();
  const onRowUpdate = useCallback<FormTableRowUpdate<SiAllocationRulesetRule>>(
    async ({ inventoryClass, genderizationRule, ...values }) =>
      dispatch(
        createUpdateSiAllocationRulesetRule({
          ...values,
          inventoryClassId: inventoryClass.id,
          genderizationRuleId: genderizationRule?.id,
        })
      ).unwrap(),
    [dispatch]
  );

  const table = useFormTable<SiAllocationRulesetRule>(
    {
      columns,
      data,
      form,
      onRowUpdate,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );
  const onRowsRemove = useCallback(
    async (rows: Array<SiAllocationRulesetRule>) => {
      await dispatch(deleteSiAllocationRulesetRule(rows.map((row) => row.id)));
      ruleDeletionModal.onClose();
    },
    [dispatch, ruleDeletionModal]
  );

  const { addRow, removeSelectedRows } =
    useFormTableControls<SiAllocationRulesetRule>({
      table,
      form,
      removeQuery: onRowsRemove,
    });

  return (
    <FormProvider {...form}>
      <Table
        caption={
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ px: 3, py: 1, pr: 1 }}
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle" fontWeight="700">
                <TransSubtitle i18nKey="rules" />
              </Typography>

              <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                <TransSubtitle
                  i18nKey="rulesQty"
                  values={{ count: allocationRulesetRules?.length }}
                  tOptions={{ postProcess: 'interval' }}
                />
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Button
                variant="text"
                onClick={ruleDeletionModal.onOpen}
                startIcon={<Icon name="delete" />}
                disabled={!Object.keys(table.state.selectedRowIds).length}
                color="error"
              >
                <TransButton i18nKey="deleteSelected" />
              </Button>
              <ConfirmDeleteModal
                handleDelete={removeSelectedRows}
                title={
                  <TransModal i18nKey="siAllocationRulesetRuleDeletionTitle" />
                }
                description={
                  <TransModal i18nKey="siAllocationRulesetRuleDeletionDescription" />
                }
                isOpen={ruleDeletionModal.open}
                onClose={ruleDeletionModal.onClose}
              />
              <Button
                variant="text"
                onClick={addRow}
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="addNew" />
              </Button>
            </Stack>
          </Stack>
        }
        table={table}
      />
    </FormProvider>
  );
};
