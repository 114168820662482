import { FC, useCallback, useMemo } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { RelationsSearchWrapper } from 'components/relationsSearchWrapper/RelationsSearchWrapper';
import { TripsTable } from 'components/trip/TripsTable';
import { TripDto } from 'dto/trip';
import { TripSearch } from 'components/trip/TripSearch';
import { Row } from 'react-table';
import { TripDetails } from 'components/trip/TripDetails';
import { TripCompositionEdit } from 'routes/tripManagement/TripCompositionEdit';
import { useCompositionManage } from 'hooks/useCompositionManage';
import { TripEditModal } from 'routes/tripAssignment/TripEditModal';
import qs from 'qs';
import { Typography } from '@mui/material';
import { Button, Icon } from '@fleet/shared/mui';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';

interface TripManagementProps
  extends RouteComponentProps<{ compositionId: string }> {}

export const TripManagement: FC<TripManagementProps> = ({
  match,
  location,
}) => {
  const { path } = match;
  const currentCompositionId = useMemo(
    () => qs.parse(location.search.substring(1)).compositionId as string,
    [location.search]
  );
  const {
    tripState: state,
    onModalClose,
    getModalHandler,
  } = useCompositionManage('trip');

  const addCompositionControl = useCallback(
    (trips: TripDto[]) => {
      return (
        <Button
          variant="text"
          startIcon={<Icon name="plus" />}
          onClick={getModalHandler!({
            trips,
            vehicleComposition: undefined,
            action: 'addComposition',
          })}
          label={
            <Typography variant="body2">
              <TransLabel i18nKey="addComposition" />
            </Typography>
          }
        />
      );
    },
    [getModalHandler]
  );
  const updateCompositionControl = useCallback(
    (trips: TripDto[]) => {
      const [trip] = trips;

      return (
        <Button
          variant="text"
          startIcon={<Icon name="replace" />}
          onClick={getModalHandler!({
            trips: [trip],
            vehicleComposition: trip.vehicleCompositions.find(
              ({ tripVehicleCompositionId }) =>
                tripVehicleCompositionId === +currentCompositionId
            )!,
            action: 'replace',
          })}
          label={
            <Typography variant="body2">
              <TransButton i18nKey="update" />
            </Typography>
          }
        />
      );
    },
    [currentCompositionId, getModalHandler]
  );

  return (
    <RelationsSearchWrapper entity="trip" className="trip-management">
      <Switch>
        <Route exact path={path}>
          <TripSearch
            title={<TransTitle i18nKey="trips" />}
            vehicleCompositionRelation="has_compositions"
            refreshSearch={state.refreshSearch}
            filterByCompositionRelation
          >
            {({ data, loading, totalCount, offset, onPageChange }) => (
              <TripsTable
                className="trip-search"
                data={data}
                totalCount={totalCount}
                offset={offset}
                loading={loading}
                onPageChange={onPageChange}
                controlsAccessor={addCompositionControl}
                selectableRows
                renderRowSubComponent={(
                  row: Row<TripDto>,
                  selectedRows: TripDto[]
                ) => (
                  <TripDetails
                    {...row}
                    selectedRows={selectedRows}
                    getModalHandler={getModalHandler}
                  />
                )}
              />
            )}
          </TripSearch>
        </Route>

        <Route path={`${path}/:tripId`}>
          {(
            routerProps: RouteComponentProps<
              { tripId: string },
              {},
              { trip?: TripDto }
            >
          ) => (
            <TripCompositionEdit
              updateCompositionControl={updateCompositionControl}
              compositionId={currentCompositionId}
              refreshComposition={state.refreshSearch}
              {...routerProps}
            />
          )}
        </Route>
      </Switch>
      {!!state.trips?.length && (
        <TripEditModal
          compositionId={currentCompositionId}
          trips={state.trips}
          vehicleComposition={state.vehicleComposition!}
          action={state.action}
          onClose={onModalClose}
        />
      )}
    </RelationsSearchWrapper>
  );
};
