import { FC, useMemo, useState } from 'react';
import {
  Modal,
  ReadOnlyField as Field,
  Select,
  ToggleButtonGroup,
} from '@fleet/shared/mui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'store/utils';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { assignEntityFilterOptionsSelector } from 'features/classification/classificationSelectors';
import {
  assignCompositionToTrips,
  removeTripRelation,
  replaceTripComposition,
  TripEditPayload,
} from 'features/composition/compositionActions';
import { TripDto, TripVehicleComposition } from 'dto/trip';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { ModalProps } from '@fleet/shared/mui/Modal';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';

const useStyles = makeStyles(
  () => ({
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
      marginBottom: '8px',
    },
    compositionSelect: {
      '&.MuiFormControl-root': {
        width: 244,
      },

      '& .MuiTypography-root': {
        color: 'var(--mid-gray)',
      },
    },
  }),
  {
    name: 'TripEditModal',
  }
);

interface TripInfoProps {
  departureDateTime: string;
  name: string;
  serviceCode: string;
  lineNumber: string;
}

export const TripEditModal: FC<{
  compositionId?: string;
  trips: TripDto[];
  vehicleComposition?: TripVehicleComposition;
  action?: 'add' | 'replace' | 'delete' | 'addComposition' | 'copyComposition';
  onClose: Required<ModalProps>['onClose'];
}> = ({ trips, vehicleComposition, action, onClose, compositionId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const {
    vehicleCompositions: vehicleCompositionsOptions,
    vehicleCompositionDirection: vehicleCompositionDirectionOptions,
  } = useSelector(assignEntityFilterOptionsSelector);
  const [params, setParams] = useState({
    tripIds: trips.map(({ id }) => id),
    tripRelationId: vehicleComposition?.tripVehicleCompositionId,
    vehicleCompositionId:
      vehicleComposition?.originatesFromVehicleCompositionId,
    vehicleCompositionDirectionId:
      vehicleComposition?.vehicleCompositionDirectionId ||
      'VEHICLE_COMPOSITION_DIRECTION.FORWARD',
  });
  const setParam = (param: Partial<TripEditPayload>) =>
    setParams((prevParams) => ({ ...prevParams, ...param }));

  const tripInfo = useMemo<TripInfoProps | null>(() => {
    if (trips.length > 1) return null;

    const { departureDateTime, name, serviceCode, lineNumber } = trips[0];

    return {
      departureDateTime: formatDate(departureDateTime, currentDateTimeFormat),
      name,
      serviceCode,
      lineNumber,
    };
  }, [trips]);

  const actionButton = useMemo(() => {
    const { tripRelationId, tripIds, ...payload } = params;

    if (['add', 'addComposition'].includes(action!)) {
      return {
        label: <TransButton i18nKey="assign" />,
        onClick: async () => {
          await dispatch(
            assignCompositionToTrips({
              targetTripIds: tripIds,
              ...payload,
            })
          );
        },
      };
    }
    if (action === 'copyComposition') {
      return {
        label: <TransButton i18nKey="copy" />,
        onClick: async () => {
          const { vehicleCompositionDirectionId } = payload;
          await dispatch(
            assignCompositionToTrips({
              targetTripIds: tripIds,
              tripVehicleCompositionId:
                vehicleComposition!.tripVehicleCompositionId,
              vehicleCompositionDirectionId,
            })
          );
        },
      };
    }
    if (action === 'delete') {
      return {
        label: <TransButton i18nKey="delete" />,
        className: 'delete',
        onClick: async () => {
          await dispatch(removeTripRelation(tripRelationId!));
        },
      };
    }
    return {
      label: <TransButton i18nKey="update" />,
      onClick: async () => {
        const { newCompositionId } = await dispatch(
          replaceTripComposition({
            tripRelationId,
            ...payload,
          })
        ).unwrap();
        if (compositionId) {
          history.replace({
            state,
            search: `compositionId=${newCompositionId}`,
          });
        }
      },
    };
  }, [
    action,
    compositionId,
    dispatch,
    history,
    params,
    state,
    vehicleComposition,
  ]);

  return (
    <Modal
      open
      maxWidth="md"
      fullWidth
      title={action && <TransTitle i18nKey={`trip.${action}`} />}
      message={
        action === 'delete'
          ? t('message.deleteVehicleCompositionFromTrip', {
              defaultValue:
                'Are you sure you want to remove vehicle composition {{compositionName}} from trip {{tripName}}?',
              tripName: tripInfo!.name,
              compositionName: vehicleComposition!.name,
            })!
          : undefined
      }
      actionButton={actionButton}
      onClose={onClose}
    >
      {action !== 'delete' && tripInfo && (
        <>
          <h3>
            <TransTitle i18nKey="tripString" />
          </h3>
          <div className={classes.row}>
            {Object.entries(tripInfo).map(([key, value]) => (
              <Field
                key={key}
                label={<TransLabel i18nKey={key as keyof TripInfoProps} />}
                value={value}
              />
            ))}
          </div>
        </>
      )}
      {action === 'add' && (
        <div className={classes.row}>
          <Field
            label={<TransLabel i18nKey="vehicle" />}
            value={vehicleComposition!.name}
          />
          <Field label={<TransLabel i18nKey="direction" />}>
            <ToggleButtonGroup
              value={params.vehicleCompositionDirectionId}
              onChange={(directionId) =>
                setParam({ vehicleCompositionDirectionId: directionId })
              }
              color="secondary"
              options={vehicleCompositionDirectionOptions}
            />
          </Field>
        </div>
      )}
      {['addComposition', 'replace', 'copyComposition'].includes(action!) && (
        <>
          <div className={classes.row}>
            {action === 'copyComposition' ? (
              <Field
                label={<TransLabel i18nKey="vehicle" />}
                value={vehicleComposition!.name}
              />
            ) : (
              <Select
                className={classes.compositionSelect}
                label={<TransLabel i18nKey="vehicle" />}
                labelPosition="top"
                options={vehicleCompositionsOptions}
                value={params.vehicleCompositionId ?? null}
                onChange={(value) =>
                  setParam({ vehicleCompositionId: +value! })
                }
              />
            )}

            <Field label={<TransLabel i18nKey="direction" />}>
              <ToggleButtonGroup
                value={params.vehicleCompositionDirectionId}
                onChange={(directionId) =>
                  setParam({ vehicleCompositionDirectionId: directionId })
                }
                color="secondary"
                options={vehicleCompositionDirectionOptions}
              />
            </Field>
          </div>
        </>
      )}
    </Modal>
  );
};
