import 'components/search/SearchTable.scss';
import { FC, ReactNode } from 'react';
import { Dropdown, Input, InputProps, Paper } from '@fleet/shared/mui';
import { useTranslation } from 'react-i18next';
import { TransLabel } from 'i18n/trans/label';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/material';

export type SortBy = {
  id: 'name' | 'created' | 'modified';
  desc: boolean;
};
interface SearchTableProps {
  title: ReactNode;
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  action: ReactNode;
  filter?: ReactNode;
  search?: {
    value: InputProps['value'];
    onBlur: InputProps['onBlur'];
  };
}

const useStyles = makeStyles(
  (theme) => ({
    header: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      padding: '0 24px',
      height: '64px',
      display: 'flex',
      alignItems: 'center',
      '& > .MuiButton-root:not(:first-of-type)': {
        marginLeft: '8px',
      },
    },
    dropdown: {
      '& .MuiButton-text, & .MuiButton-endIcon': {
        color: theme.palette.text.warning,
      },
      margin: '4px 16px 0 16px',
      '& + button': {
        marginLeft: 'auto',
      },
    },
  }),
  {
    name: 'SearchTable',
  }
);

export const SearchTable: FC<SearchTableProps> = (props) => {
  const classes = useStyles();
  const {
    title,
    sortBy,
    setSortBy,
    action,
    search = {},
    filter,
    children,
  } = props;
  const { t } = useTranslation();

  return (
    <Paper className="search-table">
      <div className={classes.header}>
        <h2>{title}</h2>
        <Dropdown
          label={<TransLabel i18nKey={`sort.${sortBy.id}`} />}
          className={classes.dropdown}
          options={[
            {
              label: <TransLabel i18nKey="sortString" />,
              options: (['name', 'created', 'modified'] as const).map((id) => ({
                label: <TransLabel i18nKey={`sort.${id}`} />,
                onClick: () => setSortBy({ ...sortBy, id }),
                selected: sortBy.id === id,
              })),
            },
            {
              label: <TransLabel i18nKey="order" />,
              options: (['asc', 'desc'] as const).map((order) => ({
                label: <TransLabel i18nKey={order} />,
                onClick: () => setSortBy({ ...sortBy, desc: order === 'desc' }),
                selected: order === (sortBy.desc ? 'desc' : 'asc'),
              })),
            },
          ]}
        />
        {action}
      </div>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          margin: '16px 0 8px 24px',
        }}
      >
        <Input
          placeholder={t('label.enterLink', 'Enter link...')!}
          icon="search"
          fullWidth={false}
          sx={{
            width: 220,
          }}
          {...search}
        />
        {filter}
      </Stack>
      {children}
    </Paper>
  );
};
