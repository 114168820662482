import {
  ElementCoords,
  FloorElement,
  FloorElementOrientation,
  ORIENTATION_ID,
} from '@fleet/widget/dto/floor';
import _pick from 'lodash/pick';

const CELL_SIZE = 8;
export const WEEK_DAYS = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
] as const;

export const GENDER_RESTRICTIONS = [
  'MALE',
  'FEMALE',
  'BASED_ON_FIRST',
] as const;

export type WeekDays = typeof WEEK_DAYS;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

interface Position {
  x: number;
  y: number;
}
// get centered coordinates inside 8x8 grid cells
export const getGridCoords = (
  { x, y }: Position,
  scale = 1,
  gridSize = CELL_SIZE
): ElementCoords => {
  const coordsConsideringScale = {
    x: x / scale,
    y: y / scale,
  };

  return {
    x: Math.round(coordsConsideringScale.x / gridSize) * gridSize,
    y: Math.round(coordsConsideringScale.y / gridSize) * gridSize,
  };
};

const elementActualHeight = <T extends Partial<FloorElement>>({
  height = 0,
  nested,
  offset = 0,
}: T) => height + (nested?.length ? nested.length * offset : 0);

export const findCollisionEl = (
  els: Array<FloorElement>,
  selection: Array<Partial<FloorElement>>,
  filterIgnoredEls = false
): boolean => {
  const preparedSelection = filterIgnoredEls
    ? selection.filter(
        ({ collisionDetectionDisabled }) => !collisionDetectionDisabled
      )
    : selection;
  if (!preparedSelection.length) return false;

  const filterNested = <T extends Partial<FloorElement>>(elements: Array<T>) =>
    elements.filter(({ id, nestedInElementId }) =>
      nestedInElementId ? nestedInElementId === id : true
    );

  const preparedElements = filterNested(
    els.filter((el) => !filterIgnoredEls || !el.collisionDetectionDisabled)
  );

  return filterNested(selection).some((selectedEl) => {
    const { coordinates, orientation } = selectedEl;
    const { x: selectedElX, y: selectedElY } = coordinates!;
    const [selectedElWidth, selectedElHeight] =
      orientation?.id &&
      [ORIENTATION_ID.LEFT, ORIENTATION_ID.RIGHT].includes(orientation.id)
        ? [elementActualHeight(selectedEl), selectedEl.width]
        : [selectedEl.width, elementActualHeight(selectedEl)];

    return preparedElements.find((el) => {
      const [elWidth, elHeight] = [
        ORIENTATION_ID.LEFT,
        ORIENTATION_ID.RIGHT,
      ].includes(el.orientation?.id)
        ? [elementActualHeight(el), el.width]
        : [el.width, elementActualHeight(el)];

      return (
        selectedElX < el.coordinates.x + elWidth &&
        selectedElX + selectedElWidth! > el.coordinates.x &&
        selectedElY < el.coordinates.y + elHeight &&
        selectedElY + selectedElHeight! > el.coordinates.y
      );
    });
  });
};

export const calculateAvailableCoords = (
  allElements: Array<FloorElement>,
  elementPayload: Array<FloorElement>,
  filterIgnoredEls = false
): Array<FloorElement> => {
  const Y_OFFSET = 12;
  let yOffset = Y_OFFSET;
  const getOffset = (el: FloorElement) => {
    const { orientation, width } = el;

    return orientation &&
      [ORIENTATION_ID.RIGHT, ORIENTATION_ID.LEFT].includes(orientation.id)
      ? width
      : elementActualHeight(el);
  };

  const preparedElementPayload = elementPayload.map((el) => ({
    ...el,
    coordinates: {
      ...el.coordinates,
      y: el.coordinates.y + getOffset(el),
    },
  }));

  /* eslint-disable no-loop-func*/
  let isCollisionPresent = findCollisionEl(
    allElements,
    preparedElementPayload,
    filterIgnoredEls
  );

  while (isCollisionPresent) {
    yOffset += Y_OFFSET * 2;
    isCollisionPresent = findCollisionEl(
      allElements,
      preparedElementPayload.map((el) => ({
        ...el,
        coordinates: { ...el.coordinates, y: el.coordinates.y + yOffset },
      })),
      filterIgnoredEls
    );
  }

  return preparedElementPayload.map((el) => ({
    ...el,
    coordinates: { ...el.coordinates, y: el.coordinates.y + yOffset },
  }));
};

export const getNextOrientationId = (
  orientation: FloorElementOrientation
): { orientation: { id: ORIENTATION_ID } } => {
  const nextOrientationMap: Record<ORIENTATION_ID, ORIENTATION_ID> = {
    [ORIENTATION_ID.UP]: ORIENTATION_ID.RIGHT,
    [ORIENTATION_ID.RIGHT]: ORIENTATION_ID.DOWN,
    [ORIENTATION_ID.DOWN]: ORIENTATION_ID.LEFT,
    [ORIENTATION_ID.LEFT]: ORIENTATION_ID.UP,
  };
  return { orientation: { id: nextOrientationMap[orientation.id] } };
};

export const BASE_PROPERTIES = [
  'isBlocked',
  'isForOnBoardSalesOnly',
  'isInPetFriendlyArea',
  'hasChildSeatEquipment',
  'isSuitableForDisabledPassenger',
  'hasTable',
  'isByWheelchairSpace',
  'isByLuggageRack',
  'hasPowerSocket',
  'hasUsbSocket',
] as const;

export const SEAT_PROPERTIES = [
  'isByAisle',
  'isBlocked',
  'blockingReason',
  'isByWindow',
  'isReserved',
] as const;

export const COMPARTMENT_PROPERTIES = [
  'hasShower',
  'hasToilet',
  'isForOnBoardSalesOnly',
  'isBlocked',
  'blockingReason',
] as const;

export const prepareFloorElementPayload = (element: Partial<FloorElement>) => {
  const {
    id,
    elementId,
    category,
    inventoryClass,
    coordinates,
    mounting,
    orientation,
    compartmentId,
    genderRestriction,
    blockingReason,
    properties = [],
  } = element;
  const placePayload = _pick(element, ['number', 'rank', ...SEAT_PROPERTIES]);

  const compartmentPayload = _pick(element, [
    'number',
    ...COMPARTMENT_PROPERTIES,
  ]);

  const propertyIds = properties.map(({ id }) => id);

  return {
    ...(id ? { id } : {}),
    elementId,
    category,
    coordinates,
    ...(category === 'place' && {
      compartmentId,
      place: {
        ...placePayload,
        inventoryClassId: inventoryClass?.id,
        blockingReasonId: blockingReason?.id,
        orientationId: orientation?.id,
        propertyIds,
      },
    }),
    ...(category === 'compartment' && {
      compartment: {
        ...compartmentPayload,
        genderRestriction,
        inventoryClassId: inventoryClass?.id,
        blockingReasonId: blockingReason?.id,
        orientationId: orientation?.id,
        propertyIds,
      },
    }),
    internal: {
      mountingId: mounting?.id,
      orientationId: orientation?.id,
    },
  };
};

export const sortWeekDays = (days: string[]) => {
  const sort: Record<string, number> = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sun: 6,
    Sat: 7,
  };

  return days.sort((a: string, b: string) => sort[a] - sort[b]);
};

export const getOrdinalNumber = (d: number) => {
  const withEnding = (str: string) => `${d}${str}`;

  if (d > 3 && d < 21) return withEnding('th');
  switch (d % 10) {
    case 1:
      return withEnding('st');
    case 2:
      return withEnding('nd');
    case 3:
      return withEnding('rd');
    default:
      return withEnding('th');
  }
};
