import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  clearCurrentComposition,
  getComposition,
  getTrips,
  setCurrentCompositionTrips,
} from 'features/composition/compositionActions';
import { useDispatch, useSelector } from 'store/utils';
import { compositionConstructSelector } from 'features/composition/compositionSelectors';
import { Row } from 'react-table';
import { RelationsSearchWrapper } from 'components/relationsSearchWrapper/RelationsSearchWrapper';
import { TripsTable } from 'components/trip/TripsTable';
import { TripDto } from 'dto/trip';
import { TripSearch } from 'components/trip/TripSearch';
import { TripEditModal } from 'routes/tripAssignment/TripEditModal';
import { TripDetails } from 'components/trip/TripDetails';
import { useCompositionManage } from 'hooks/useCompositionManage';
import { Button, Icon, RadioGroup } from '@fleet/shared/mui';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ModalProps } from '@fleet/shared/mui/Modal';
import { RadioGroupProps } from '@fleet/shared';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TransButton } from 'i18n/trans/button';

interface LineAssignmentProps
  extends RouteComponentProps<{ compositionId: string }> {}

const useStyles = makeStyles(() => ({
  activeFilter: {
    '& .MuiFormControl-root': {
      flexDirection: 'row',
      alignItems: 'center',

      '& .MuiTypography-root': {
        paddingRight: '16px',
      },
    },
  },
}));

export const TripAssignment: FC<LineAssignmentProps> = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearCurrentComposition());
    };
  }, [dispatch]);

  const { compositionId } = match.params;
  const {
    tripState: state,
    onModalClose,
    getModalHandler,
  } = useCompositionManage('trip');
  const [connectedTripsStatus, setConnectedTripsStatus] = useState(true);
  const compositionConstruct = useSelector(compositionConstructSelector);
  const getCurrentCompositionTrips = useCallback(
    async (params?: PaginationParams) => {
      const { items, totalCount, offset } = await dispatch(
        getTrips({
          vehicleCompositionId: +compositionId,
          isTripActive: connectedTripsStatus,
          ...params,
        })
      ).unwrap();
      return dispatch(
        setCurrentCompositionTrips({ data: items, totalCount, offset })
      );
    },
    [compositionId, connectedTripsStatus, dispatch]
  );
  const tripActiveHandler = useCallback<Required<RadioGroupProps>['onChange']>(
    (val) => setConnectedTripsStatus(Boolean(+val)),
    []
  );

  const getCompositionWithTrips = useCallback(async () => {
    await dispatch(getComposition(compositionId));
    getCurrentCompositionTrips();
  }, [compositionId, dispatch, getCurrentCompositionTrips]);

  const getCurrentVehicleComposition = useCallback(
    (trip: TripDto) =>
      trip.vehicleCompositions.find(
        (comp) =>
          comp.originatesFromVehicleCompositionId === compositionConstruct?.id
      ),
    [compositionConstruct]
  );

  const onModalCloseHandler = useCallback<Required<ModalProps>['onClose']>(
    async (event, reason) => {
      reason === 'action' && getCurrentCompositionTrips();
      onModalClose(event, reason);
    },
    [getCurrentCompositionTrips, onModalClose]
  );

  const connectedTripsControls = useCallback(
    (trips: TripDto[]) => {
      const vehicleComposition = getCurrentVehicleComposition(trips[0])!;
      return (
        <>
          <Button
            variant="text"
            startIcon={<Icon name="replace" />}
            onClick={getModalHandler({
              trips,
              vehicleComposition,
              action: 'replace',
            })}
            label={
              <Typography variant="body2">
                <TransButton i18nKey="update" />
              </Typography>
            }
          />
          <Button
            variant="text"
            startIcon={<Icon name="close" />}
            onClick={getModalHandler({
              trips,
              vehicleComposition,
              action: 'delete',
            })}
            label={
              <Typography variant="body2">
                <TransButton i18nKey="delete" />
              </Typography>
            }
          />
        </>
      );
    },
    [getCurrentVehicleComposition, getModalHandler]
  );

  const searchTripsControl = useCallback(
    (trips: TripDto[]) => {
      return (
        <Button
          variant="text"
          startIcon={<Icon name="plus" />}
          onClick={getModalHandler({
            trips,
            vehicleComposition: {
              name: compositionConstruct!.name,
              originatesFromVehicleCompositionId: compositionConstruct!.id,
            },
            action: 'add',
          })}
          label={
            <Typography variant="body2">
              <TransLabel i18nKey="addComposition" />
            </Typography>
          }
        />
      );
    },
    [compositionConstruct, getModalHandler]
  );

  const [currentAssignedTrips, totalAssignedTrips] = useMemo(() => {
    if (!compositionConstruct || !compositionConstruct.trips) return [[], 0];

    return [
      compositionConstruct.trips.data,
      compositionConstruct.trips.totalCount,
    ];
  }, [compositionConstruct]);
  const emptyAssignedTrips = !!currentAssignedTrips?.length;
  const title = useMemo(() => {
    if (!compositionConstruct) return '';
    const { name, code } = compositionConstruct;

    return [name, code].filter(Boolean).join('/');
  }, [compositionConstruct]);

  return (
    <RelationsSearchWrapper entity="trip" onMount={getCompositionWithTrips}>
      <>
        <div className="composition-relations panel">
          <h2>{title}</h2>
          <div className="heading">
            <div className="heading-title">
              <TransTitle i18nKey="tripsConnected" />
            </div>
            {emptyAssignedTrips && (
              <div className="connected-number">
                <TransLabel
                  i18nKey="countTripsOfTotal"
                  values={{
                    count: currentAssignedTrips!.length,
                    totalCount: totalAssignedTrips!,
                  }}
                />
              </div>
            )}
            <RadioGroup
              name="isConnectedTripActive"
              label={<TransLabel i18nKey="active" />}
              className={classes.activeFilter}
              defaultValue={connectedTripsStatus ? 1 : 0}
              onChange={tripActiveHandler}
              inline
              options={[
                { label: <TransLabel i18nKey="yes" />, value: 1 },
                { label: <TransLabel i18nKey="no" />, value: 0 },
              ]}
            />
          </div>
          {emptyAssignedTrips ? (
            <TripsTable
              className="connected-trips"
              vehicleConstructId={compositionConstruct!.id}
              data={currentAssignedTrips!}
              totalCount={totalAssignedTrips}
              onPageChange={getCurrentCompositionTrips}
              controlsAccessor={connectedTripsControls}
              autoResetExpanded={state.autoResetExpanded}
              renderRowSubComponent={(row: Row<TripDto>) => (
                <TripDetails {...row} vehicleConstructId={+compositionId} />
              )}
            />
          ) : (
            <div className="assigned-none-message">
              <TransLabel i18nKey="noLineTemplatesAssigned" />
            </div>
          )}
        </div>
        <TripSearch
          title={<TransTitle i18nKey="trips" />}
          refreshSearch={state.refreshSearch}
          vehicleCompositionRelation="all"
        >
          {({ data, loading, totalCount, offset, onPageChange }) => (
            <TripsTable
              className="trip-search"
              data={data}
              totalCount={totalCount}
              offset={offset}
              loading={loading}
              onPageChange={onPageChange}
              controlsAccessor={searchTripsControl}
              selectableRows
              renderRowSubComponent={(row: Row<TripDto>) => (
                <TripDetails {...row} getModalHandler={getModalHandler} />
              )}
            />
          )}
        </TripSearch>
        {!!state.trips?.length && (
          <TripEditModal
            trips={state.trips}
            vehicleComposition={state.vehicleComposition!}
            action={state.action}
            onClose={onModalCloseHandler}
          />
        )}
      </>
    </RelationsSearchWrapper>
  );
};
