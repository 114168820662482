import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import _mapValues from 'lodash/mapValues';
import _values from 'lodash/values';
import { sortBy } from '@fleet/shared/utils/array';
import { useSelector } from 'store/utils';
import { Collapsible, Input, Tooltip } from '@fleet/shared/mui';
import { floorElementsSelector } from 'features/classification/classificationSelectors';
import { ViewerContext } from '@fleet/widget/components/viewer/Context';
import getScrollbarSize from '@fleet/shared/utils/getScrollbarSize';
import {
  ElementCategory,
  PaletteElementCategory,
  PreparedPaletteElement,
} from '@fleet/widget/dto/element';
import { TransTitle } from 'i18n/trans/title';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const PalettePanel = () => {
  const { scale, currentFloor } = useContext(ViewerContext);
  const [searchStr, setSearchStr] = useState<string>('');
  const collapsibleOpen = useMemo(() => !!searchStr, [searchStr]);
  const { t } = useTranslation();
  const floorElements = useSelector(floorElementsSelector);
  const floorElementsByCategory = useMemo(() => {
    if (!floorElements) return null;
    return _values(floorElements).reduce(
      (acc, { paletteCategory, ...el }) => {
        if (
          paletteCategory === 'seats' &&
          el.subTypeId !== 'PLACE_SUB_TYPE.AVAILABLE_SEAT'
        )
          return acc;
        if (!acc[paletteCategory]) return acc;
        return {
          ...acc,
          [paletteCategory]: [...acc[paletteCategory], el],
        };
      },
      {
        compartments: [],
        walls: [],
        wallsAngled: [],
        wallsTemp: [],
        doors: [],
        seats: [],
        tables: [],
        signs: [],
        beds: [],
      }
    ) as Record<PaletteElementCategory, PreparedPaletteElement[]>;
  }, [floorElements]);

  const filteredEls = useMemo(
    () =>
      _mapValues(floorElementsByCategory, (els, category) => {
        const filtered = els.filter((el) =>
          el?.name?.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase())
        );
        if (category === PaletteElementCategory.beds)
          return sortBy(filtered, 'subTypeId');
        return filtered;
      }),
    [floorElementsByCategory, searchStr]
  );
  if (!floorElementsByCategory) return <div className="palette-panel" />;
  const {
    walls,
    wallsAngled,
    wallsTemp,
    doors,
    compartments,
    seats,
    beds,
    tables,
    signs,
  } = filteredEls;
  return (
    <div
      className={classNames('palette-panel elements scrollable', {
        disabled: !currentFloor,
      })}
    >
      <Typography variant="h2">
        <TransTitle i18nKey="inventory" />
      </Typography>
      <Input
        className="search-input"
        placeholder={t('label.find', 'Find')!}
        icon="search"
        value={searchStr}
        onChange={(event) => setSearchStr(event.target.value)}
        margin="normal"
      />
      <div className="palette-elements">
        <Collapsible title={<TransTitle i18nKey="walls" />} open={!!searchStr}>
          <GroupHeader
            title={<TransTitle i18nKey="straight" />}
            hidden={!walls.length}
          />
          <div className="group-wrapper">
            {walls.map((wall, idx) => (
              <PaletteElement key={idx} scale={scale} {...wall} />
            ))}
          </div>
          <GroupHeader
            title={<TransTitle i18nKey="angled" />}
            hidden={!wallsAngled.length}
          />
          <div
            className={classNames('group-wrapper', {
              mosaic: wallsAngled.length === 6,
            })}
          >
            {wallsAngled.map((wall, idx) => (
              <PaletteElement key={idx} scale={scale} {...wall} />
            ))}
          </div>
          <GroupHeader
            title={<TransTitle i18nKey="temporary" />}
            hidden={!wallsTemp.length}
          />
          {wallsTemp.map((wall, idx) => (
            <PaletteElement key={idx} scale={scale} {...wall} />
          ))}
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="windowsAndDoors" />}
          open={collapsibleOpen}
        >
          <div className="group-wrapper">
            {doors.map((door, idx) => (
              <PaletteElement key={idx} scale={scale} {...door} />
            ))}
          </div>
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="compartment" />}
          open={collapsibleOpen}
        >
          <div className="group-wrapper">
            {compartments.map((compartment, idx) => (
              <PaletteElement key={idx} scale={scale} {...compartment} />
            ))}
          </div>
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="seating" />}
          open={collapsibleOpen}
        >
          <div className="group-wrapper">
            {seats.map((seat, idx) => (
              <PaletteElement key={idx} scale={scale} {...seat} />
            ))}
          </div>
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="beds" />}
          open={collapsibleOpen}
        >
          <div className="group-wrapper">
            {beds.map((bed, idx) => (
              <PaletteElement key={idx} scale={scale} {...bed} />
            ))}
          </div>
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="tables" />}
          open={collapsibleOpen}
        >
          <div className="group-wrapper">
            {tables.map((table, idx) => (
              <PaletteElement key={idx} scale={scale} {...table} />
            ))}
          </div>
        </Collapsible>

        <Collapsible
          title={<TransTitle i18nKey="signs" />}
          open={collapsibleOpen}
        >
          <div className="group-wrapper signs">
            {signs.map((sign, idx) => (
              <PaletteElement key={idx} scale={scale} {...sign} />
            ))}
          </div>
        </Collapsible>
      </div>
    </div>
  );

  function GroupHeader({
    title,
    hidden,
  }: {
    title: ReactNode;
    hidden: boolean;
  }) {
    return hidden ? null : <h4>{title}</h4>;
  }
};

interface PaletteElementProps {
  className?: string;
  scale: number;
  icon: string;
  name: string;
  elementId: string;
  category: ElementCategory;
}

const PaletteElement: FC<PaletteElementProps> = ({
  className,
  scale,
  icon,
  name,
  elementId,
  category,
}) => {
  const { selectBoxRef } = useContext(ViewerContext);
  const [isDragged, setDragged] = useState(false);
  useEffect(() => {
    const htmlNode = document.documentElement;

    htmlNode.style.paddingRight = `${isDragged ? getScrollbarSize() : 0}px`;
    htmlNode.style.overflow = isDragged ? 'hidden' : '';
    htmlNode.style.userSelect = isDragged ? 'none' : '';
  }, [isDragged]);

  const handleDragStart = useCallback(
    (event) => {
      setDragged(true);
      const {
        firstElementChild: image,
        dataset: { elementId, category },
      } = event.currentTarget;
      const { width, height } = image.getBoundingClientRect();
      image.style.width = `${width * scale}px`;
      image.style.height = `${height * scale}px`;

      selectBoxRef.current?.setAttrs({ width, height });
      event.dataTransfer.setDragImage(image, 0, 0);
      event.dataTransfer.setData('elementId', elementId);
      event.dataTransfer.setData('category', category);
    },
    [scale, selectBoxRef]
  );

  const handleDragEnd = useCallback((event) => {
    setDragged(false);
    const { firstElementChild: image } = event.currentTarget;
    image.style.width = `auto`;
    image.style.height = `auto`;
    event.dataTransfer.clearData();
  }, []);

  const imageProps = useMemo(
    () => ({
      alt: name,
      src: icon,
    }),
    [icon, name]
  );
  return (
    <Tooltip content={name} placement="right" disableInteractive>
      <div
        className={classNames('palette-item', className)}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        data-element-id={elementId}
        data-category={category}
      >
        <img className="draggable" {...imageProps} />
        <img {...imageProps} />
      </div>
    </Tooltip>
  );
};

export default PalettePanel;
