import { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { CardHeader, Dropdown, Icon, Layout, Loadable } from '@fleet/shared';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'store/utils';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { InventoryClassDetails } from 'routes/inventoryClass/InventoryClassDetails';
import { InventoryClassTable } from 'routes/inventoryClass/InventoryClassTable';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { dataLoadingSelector } from 'features/common/commonSelectors';

const useStyles = makeStyles(
  (theme) => ({
    layout: { padding: 0 },
    dropDown: {
      '& .MuiButton-endIcon, & .Icon-root': {
        color: theme.palette.text.warning,
      },
    },
    dropDownLabel: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.warning,
    },
  }),
  {
    name: 'InventoryClass',
  }
);

interface InventoryClassProps {}

export const InventoryClass: FC<InventoryClassProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const loading = useSelector(dataLoadingSelector);

  const inventoryTypeOptions = useClassificationOptions(
    ClassificationGroup.INVENTORY_TYPE
  );

  return (
    <Loadable loading={loading}>
      <Layout
        componentClasses={{ root: classes.layout }}
        header={
          <CardHeader title={<TransTitle i18nKey="inventoryClass" />}>
            <Grid container spacing={1} rowSpacing={1}>
              <Grid item xs={1}>
                <Dropdown
                  className={classes.dropDown}
                  label={
                    <div className={classes.dropDownLabel}>
                      <Icon name="add" sx={{ mr: 1 }} />
                      <TransButton i18nKey="add" />
                    </div>
                  }
                  options={inventoryTypeOptions.map(({ label, value }) => ({
                    value: String(value),
                    label: label,
                    onClick: () =>
                      history.replace({
                        pathname: '/inventory-class/create',
                        state: {
                          inventoryType: { id: value, name: label },
                        },
                      }),
                    content: (
                      <Icon name={label.toLowerCase()} color="default" />
                    ),
                  }))}
                />
              </Grid>
            </Grid>
          </CardHeader>
        }
        drawer={
          <Switch>
            <Route
              path="/inventory-class/:action(create|edit)/:id?"
              component={InventoryClassDetails}
              exact
            />
          </Switch>
        }
      >
        <InventoryClassTable />
      </Layout>
    </Loadable>
  );
};
