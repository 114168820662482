import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  RadioGroupField,
  SearchForm,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import {
  getInventoryClasses,
  setInventoryClassFilter,
} from 'features/inventoryClass/inventoryClassActions';
import { InventoryClassFilter } from 'dto/inventoryClass';
import { TransLabel } from 'i18n/trans/label';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { inventoryClassFilterSelector } from 'features/inventoryClass/inventoryClassSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';

const useStyles = makeStyles(
  () => ({
    searchContent: {
      padding: '4px 8px',
    },
  }),
  {
    name: 'InventoryClassSearchForm',
  }
);

interface InventoryClassSearchFormProps {}

export const InventoryClassSearchForm: FC<InventoryClassSearchFormProps> =
  () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const inventoryTypeOptions = useClassificationOptions(
      ClassificationGroup.INVENTORY_TYPE
    );
    const comfortLevelOptions = useClassificationOptions(
      ClassificationGroup.COMFORT_LEVEL
    );
    const serviceClassOptions = useClassificationOptions(
      ClassificationGroup.SERVICE_CLASS
    );
    const splitsOptions = useMemo(
      () => [
        { value: 0, label: '0' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
      ],
      []
    );
    const filter = useSelector(inventoryClassFilterSelector);

    const onSubmit = useCallback(
      (values: Partial<InventoryClassFilter>) => {
        formSubmit(async () => {
          dispatch(getInventoryClasses(values)).unwrap();
        });
      },
      [dispatch]
    );

    const initialValues = useMemo(() => filter, [filter]);

    useEffect(() => {
      if (Object.keys(filter).length === 0) {
        dispatch(setInventoryClassFilter(initialValues));
      }
    }, [dispatch, filter, initialValues]);

    const { form, handleSubmit } = useForm<InventoryClassFilter>({
      onSubmit,
      initialValues,
    });

    const handleReset = useCallback(() => {
      form.reset();
      dispatch(setInventoryClassFilter({}));
    }, [dispatch, form]);

    return (
      <SearchForm
        className={classes.searchContent}
        title={<TransTitle i18nKey="search" />}
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <Grid container columns={5} spacing={2}>
              <Grid item xs={1}>
                <TextField name="name" label={<TransLabel i18nKey="name" />} />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="comfortLevel"
                  label={<TransLabel i18nKey="comfortClass" />}
                  options={comfortLevelOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="serviceClass"
                  label={<TransLabel i18nKey="serviceLevel" />}
                  options={serviceClassOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="inventoryType"
                  label={<TransLabel i18nKey="inventoryType" />}
                  options={inventoryTypeOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="minOccupancy"
                  label={<TransLabel i18nKey="minOccupancy" />}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="maxOccupancy"
                  label={<TransLabel i18nKey="maxOccupancy" />}
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="allowedNumberOfSplits"
                  label={<TransLabel i18nKey="splits" />}
                  options={splitsOptions}
                  showEmptyOption
                />
              </Grid>
              <Grid item xs={1}>
                <RadioGroupField
                  name="isSoldOnce"
                  label={<TransLabel i18nKey="soldOnce" />}
                  options="BOOL"
                  inline
                />
              </Grid>
              <Grid item xs={1}>
                <RadioGroupField
                  name="isAssignmentEnabled"
                  label={<TransLabel i18nKey="seatAssignment" />}
                  options="BOOL"
                  inline
                />
              </Grid>
              <Grid item xs={1}>
                <RadioGroupField
                  name="isActive"
                  label={<TransLabel i18nKey="isActive" />}
                  options="BOOL"
                  inline
                />
              </Grid>
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={2}>
                  <FormControl label="&nbsp;">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      onClick={handleReset}
                    >
                      <TransButton i18nKey="resetFilters" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button variant="contained" type="submit" icon="search">
                      <TransButton i18nKey="search" />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </SearchForm>
    );
  };
