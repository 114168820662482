import React, { FC, useCallback } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { RelationsSearchWrapper } from 'components/relationsSearchWrapper/RelationsSearchWrapper';
import { Row } from 'react-table';
import { LineTemplateSearch } from 'components/lineTemplate/LineTemplateSearch';
import { LineTemplatesTable } from 'components/lineTemplate/LineTemplatesTable';
import { LineTemplateDto } from 'dto/lineTemplate';
import { LineTemplateDetails } from 'components/lineTemplate/LineTemplateDetails';
import { LineTemplateCompositionEdit } from 'routes/lineTemplateManagement/LineTemplateCompositionEdit';
import { useCompositionManage } from 'hooks/useCompositionManage';
import { LineTemplateEditModal } from 'routes/lineAssignment/LineTemplateEditModal';
import qs from 'qs';
import { Button, Icon } from '@fleet/shared/mui';
import { Typography } from '@mui/material';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';

interface LineTemplateManagementProps
  extends RouteComponentProps<{ compositionId: string }> {}

export const LineTemplateManagement: FC<LineTemplateManagementProps> = ({
  match,
  location,
}) => {
  const { path } = match;
  const currentCompositionId = qs.parse(location.search.substring(1))
    .compositionId as string;
  const {
    lineTemplateState: state,
    onModalClose,
    getModalHandler,
  } = useCompositionManage('lineTemplate');

  const addCompositionControl = useCallback(
    (lineTemplates: LineTemplateDto[]) => {
      return (
        <Button
          variant="text"
          startIcon={<Icon name="plus" />}
          onClick={getModalHandler!({
            lineTemplates,
            vehicleComposition: {},
            action: 'addComposition',
          })}
          label={
            <Typography variant="body2">
              <TransLabel i18nKey="addComposition" />
            </Typography>
          }
        />
      );
    },
    [getModalHandler]
  );

  const updateCompositionControl = useCallback(
    ([lineTemplate]: LineTemplateDto[]) => {
      return (
        <Button
          variant="text"
          startIcon={<Icon name="replace" />}
          onClick={getModalHandler!({
            lineTemplates: [lineTemplate],
            vehicleComposition: lineTemplate.vehicleCompositions.find(
              ({ lineTemplateVehicleCompositionId }) =>
                lineTemplateVehicleCompositionId === +currentCompositionId
            )!,
            action: 'replace',
          })}
          label={
            <Typography variant="body2">
              <TransButton i18nKey="update" />
            </Typography>
          }
        />
      );
    },
    [currentCompositionId, getModalHandler]
  );

  return (
    <RelationsSearchWrapper
      entity="lineTemplate"
      className="line-template-management"
    >
      <Switch>
        <Route exact path={path}>
          <LineTemplateSearch
            title={<TransTitle i18nKey="lineTemplates" />}
            vehicleCompositionRelation="has_compositions"
            refreshSearch={state.refreshSearch}
            filterByCompositionRelation
          >
            {({ data, loading, totalCount, offset, onPageChange }) => (
              <LineTemplatesTable
                data={data}
                totalCount={totalCount}
                offset={offset}
                loading={loading}
                onPageChange={onPageChange}
                controlsAccessor={addCompositionControl}
                selectableRows
                renderRowSubComponent={(row: Row<LineTemplateDto>) => (
                  <LineTemplateDetails
                    {...row}
                    getModalHandler={getModalHandler}
                  />
                )}
              />
            )}
          </LineTemplateSearch>
        </Route>

        <Route path={`${path}/:lineTemplateId`}>
          {(
            routerProps: RouteComponentProps<
              { lineTemplateId: string },
              {},
              { lineTemplate?: LineTemplateDto }
            >
          ) => (
            <LineTemplateCompositionEdit
              updateCompositionControl={updateCompositionControl}
              compositionId={currentCompositionId}
              refreshComposition={state.refreshSearch}
              {...routerProps}
            />
          )}
        </Route>
      </Switch>
      {!!state.lineTemplates?.length && (
        <LineTemplateEditModal
          lineTemplates={state.lineTemplates}
          vehicleComposition={state.vehicleComposition!}
          action={state.action}
          onClose={onModalClose}
        />
      )}
    </RelationsSearchWrapper>
  );
};
