import { MouseEvent, PropsWithChildren, ReactNode, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Drawer, DrawerProps } from '@fleet/shared';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  Typography,
} from '@mui/material';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { Icon, Tooltip } from '@fleet/shared/mui';
import { TransLabel } from 'i18n/trans/label';
import { TransButton } from 'i18n/trans/button';

const useStyles = makeStyles(
  () => ({
    card: {
      width: 480,
      minHeight: '100%',
      height: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    cardContent: {
      flex: 1,
      overflow: 'auto',
    },
    cardActions: {
      position: 'relative',
      zIndex: 1,
      padding: 16,
      justifyContent: 'flex-end',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    },
    tableCell: {
      borderBottom: 0,
    },
  }),
  {
    name: 'ElementDetails',
  }
);

interface ElementDetailsProps<TValues extends FieldValues>
  extends ElementDetailsFormProps<TValues> {
  title: ReactNode;
}

interface ElementDetailsFormProps<TValues extends FieldValues>
  extends Omit<DrawerProps, 'onSubmit' | 'title'> {
  title: ReactNode;
  defaultValues: DefaultValues<TValues>;
  onSubmit: SubmitHandler<TValues>;
}

const ElementDetailsForm = <TValues extends FieldValues>({
  defaultValues,
  onSubmit,
  title,
  children,
  onClose,
}: PropsWithChildren<ElementDetailsFormProps<TValues>>) => {
  const form = useForm<TValues>({
    mode: 'onChange',
    defaultValues,
  });
  const handleClose = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onClose && onClose(event, 'escapeKeyDown');
    },
    [onClose]
  );
  const classes = useStyles();
  return (
    <FormProvider {...form}>
      <Card
        className={classes.card}
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <CardHeader
          title={<Typography variant="subtitle">{title}</Typography>}
          action={
            <Tooltip content={<TransLabel i18nKey="close" />} delay={500}>
              <IconButton aria-label="close" onClick={handleClose}>
                <Icon name="close" size={24} />
              </IconButton>
            </Tooltip>
          }
        />
        <CardContent className={classes.cardContent} sx={{ p: 0 }}>
          <Table size="small">
            <TableBody>{children}</TableBody>
          </Table>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button variant="text" color="primary" onClick={handleClose}>
            <TransButton i18nKey="cancel" />
          </Button>
          <Button type="submit" variant="contained" color="primary">
            <TransButton i18nKey="save" />
          </Button>
        </CardActions>
      </Card>
    </FormProvider>
  );
};

export const ElementDetails = <TValues extends FieldValues>({
  title,
  children,
  onClose,
  defaultValues,
  onSubmit,
  ...props
}: PropsWithChildren<ElementDetailsProps<TValues>>) => {
  const formProps = { title, children, onClose, defaultValues, onSubmit };
  return (
    <Drawer
      anchor="right"
      elevation={0}
      keepMounted={false}
      onClose={onClose}
      {...props}
    >
      <ElementDetailsForm<TValues> {...formProps} />
    </Drawer>
  );
};
