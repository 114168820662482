import { FC, useCallback, useMemo } from 'react';
import { Group } from 'react-konva';
import { Html } from 'react-konva-utils';
import { CacheProvider } from '@emotion/react';
import { cache } from '@fleet/shared/mui/provider';
import { ThemeProvider } from '@mui/material/styles';
import { Tooltip, theme } from '@fleet/shared/mui';
import { FloorElement } from '@fleet/widget/dto/floor';

import 'components/viewer/viewerControlsTooltip.scss';

export interface SelectedState {
  x: number;
  y: number;
  width: number;
  height: number;
  elements: Array<FloorElement>;
}
interface ViewerControlsTooltipProps extends SelectedState {
  onRotate(): void;
  onPaste(): void;
  onDelete(): void;
}
export const ViewerControlsTooltip: FC<ViewerControlsTooltipProps> = ({
  x,
  y,
  elements,
  width,
  height,
  onRotate,
  onPaste,
  onDelete,
}) => {
  const hideRotateControl = useMemo(
    () => elements[0].category === 'sign' || !elements[0].orientation,
    [elements]
  );
  const container = useCallback(() => document.getElementById('designer'), []);
  return (
    <Group x={x} y={y} id="element-controls-tooltip">
      <Html divProps={{ className: 'tip' }}>
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme}>
            <Tooltip
              className="element-controls-popup-wrapper"
              theme="light"
              visible={true}
              content={
                <div className="element-controls-popup">
                  {!hideRotateControl && (
                    <div className="rotate-control" onClick={onRotate} />
                  )}
                  <div className="paste-control" onClick={onPaste} />
                  <div className="delete-control" onClick={onDelete} />
                </div>
              }
              PopperProps={{ container }}
            >
              <span className="tip-ref" style={{ width, height }} />
            </Tooltip>
          </ThemeProvider>
        </CacheProvider>
      </Html>
    </Group>
  );
};
