import { selector } from 'store/utils';

export const compositionsSelector = selector((state) => state.composition.list);
export const compositionsTypeFilterSelector = selector(
  (state) => state.composition.transportationTypeId
);
export const compositionConstructSelector = selector(
  (state) => state.composition.construct
);
export const compositionConstructVehicleSelector = selector(
  (state) => state.composition.construct?.vehicle
);
