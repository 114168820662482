import type { SiServiceAllocationRuleset } from 'dto/siServiceAllocationRuleset';
import type { SiServiceAllocationRulesetQueryParams } from 'dto/siServiceAllocationRuleset';
import { createSlice } from '@reduxjs/toolkit';
import {
  getSiServiceAllocationRulesets,
  setServiceAllocationRulesetsFilter,
  setSiServiceAllocationRuleset,
} from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetActions';

export interface SiAllocationServiceState {
  filter: Partial<SiServiceAllocationRulesetQueryParams>;
  list: Array<SiServiceAllocationRuleset>;
  current?: SiServiceAllocationRuleset;
}

const initialState: SiAllocationServiceState = {
  filter: {},
  list: [],
};

export const { reducer: siServiceAllocationRulesetReducer } = createSlice({
  name: 'siServiceAllocationRuleset',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setServiceAllocationRulesetsFilter, (state, action) => {
        state.filter = action.payload;
      })
      .addCase(getSiServiceAllocationRulesets.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(setSiServiceAllocationRuleset, (state, action) => {
        state.current = action.payload;
      });
  },
});
