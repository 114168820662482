import {
  Checkbox,
  Icon,
  SearchResult,
  Table,
  TableColumns,
} from '@fleet/shared';
import {
  CardHeader,
  Divider,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { useFilters, usePagination, useTable } from 'react-table';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { InventoryClass } from 'dto/inventoryClass';
import { useDispatch, useSelector } from 'store/utils';
import {
  inventoryClassesSelector,
  inventoryClassFilterSelector,
} from 'features/inventoryClass/inventoryClassSelectors';
import { Link } from 'react-router-dom';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { getInventoryClasses } from 'features/inventoryClass/inventoryClassActions';
import { TransLabel } from 'i18n/trans/label';
import { InventoryClassSearchForm } from 'routes/inventoryClass/InventoryClassSearchForm';
import { dataLoadingSelector } from 'features/common/commonSelectors';

interface InventoryClassTableProps {}

export const InventoryClassTable: FC<InventoryClassTableProps> = () => {
  const dispatch = useDispatch();
  const loading = useSelector(dataLoadingSelector);
  const inventoryClasses = useSelector(inventoryClassesSelector);
  const filter = useSelector(inventoryClassFilterSelector);
  const data = useMemo(
    () => inventoryClasses?.items ?? [],
    [inventoryClasses?.items]
  );

  const columns: TableColumns<InventoryClass> = useMemo(
    () => [
      {
        accessor: 'name',
        Header: <TransLabel i18nKey="name" />,
        Cell: ({ row: { original }, value }) => (
          <MuiLink
            underline="none"
            component={Link}
            to={{
              pathname: `/inventory-class/edit/${original.id}`,
              state: {
                inventoryType: {
                  id: original.inventoryType?.id,
                  name: original.inventoryType?.name,
                },
              },
            }}
          >
            {value}
          </MuiLink>
        ),
      },
      {
        accessor: 'description',
        Header: <TransLabel i18nKey="description" />,
      },
      {
        id: 'inventoryType',
        accessor: ({ inventoryType }) => inventoryType?.name,
        Header: <TransLabel i18nKey="inventoryType" />,
      },
      {
        id: 'comfortLevel',
        accessor: ({ comfortLevel }) => comfortLevel?.name,
        Header: <TransLabel i18nKey="comfortClass" />,
      },
      {
        id: 'serviceClass',
        accessor: ({ serviceClass }) => serviceClass?.name,
        Header: <TransLabel i18nKey="serviceLevel" />,
      },
      {
        accessor: 'minOccupancy',
        Header: <TransLabel i18nKey="minOccupancy" />,
      },
      {
        accessor: 'maxOccupancy',
        Header: <TransLabel i18nKey="maxOccupancy" />,
      },
      {
        accessor: 'isSoldOnce',
        Header: <TransLabel i18nKey="soldOnce" />,
        Cell: ({
          row: {
            original: {
              isSoldOnce,
              inventoryType: { id },
            },
          },
        }) => {
          if (
            id === 'INVENTORY_TYPE.BED' ||
            id === 'INVENTORY_TYPE.COMPARTMENT'
          ) {
            return isSoldOnce ? (
              <Icon name="check" color="success" />
            ) : (
              <Icon name="close" color="error" />
            );
          }
          return null;
        },
      },
      {
        accessor: 'isAssignmentEnabled',
        Header: <TransLabel i18nKey="seatAssignmentEnabled" />,
        Cell: ({
          row: {
            original: {
              isAssignmentEnabled,
              inventoryType: { id },
            },
          },
        }) => {
          if (id === 'INVENTORY_TYPE.SEAT' || id === 'INVENTORY_TYPE.BED') {
            return isAssignmentEnabled ? (
              <Icon name="check" color="success" />
            ) : (
              <Icon name="close" color="error" />
            );
          }
          return null;
        },
      },
      {
        accessor: 'allowedNumberOfSplits',
        Header: <TransLabel i18nKey="splitsEnabled" />,
        Cell: ({
          row: {
            original: {
              allowedNumberOfSplits,
              inventoryType: { id },
            },
          },
        }) => <>{id === 'INVENTORY_TYPE.SEAT' && allowedNumberOfSplits}</>,
      },
      {
        accessor: 'isActive',
        Header: <TransLabel i18nKey="isActive" />,
        Cell: (cell) =>
          cell.value ? (
            <Icon name="check" color="success" />
          ) : (
            <Icon name="close" color="error" />
          ),
      },
      {
        accessor: 'isDefault',
        Header: <TransLabel i18nKey="isDefault" />,
        Cell: (cell) =>
          cell.value ? (
            <Icon name="check" color="success" />
          ) : (
            <Icon name="close" color="error" />
          ),
      },
    ],
    []
  );

  const getRowId = useCallback((row) => `${row.id!}`, []);

  const getPage = useCallback(
    (pageSize: number) => {
      if (inventoryClasses) {
        const { limit = pageSize, offset } = inventoryClasses;
        return offset / limit;
      }
      return 0;
    },
    [inventoryClasses]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      dispatch(getInventoryClasses({ ...filter, ...paginationParams }));
    },
    [filter, dispatch]
  );

  const table = useTable<InventoryClass>(
    {
      data,
      columns,
      getRowId,
      pageCount: -1,
      total: inventoryClasses?.totalCount,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
    },
    useFilters,
    usePagination
  );

  const handleActiveFilterToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      table.setFilter('isActive', e.target.checked || undefined),
    [table]
  );

  return (
    <>
      <InventoryClassSearchForm />
      <Divider />
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <CardHeader
              subheader={
                <Stack
                  direction="row"
                  alignItems="baseline"
                  sx={{ gap: '20px' }}
                >
                  <Typography
                    variant="subtitle"
                    fontWeight="700"
                    color="common.black"
                  >
                    <TransLabel i18nKey="searchResults" />
                  </Typography>
                  {inventoryClasses?.totalCount && (
                    <Typography variant="body2" color="text.secondary">
                      <TransLabel
                        i18nKey="inventoryClassQty"
                        values={{ num: data.length }}
                      />
                    </Typography>
                  )}
                  <Checkbox
                    size="small"
                    label={
                      <Typography
                        variant="body2"
                        color="text.primary"
                        component="span"
                      >
                        <TransLabel i18nKey="activeInventoryClasses" />
                      </Typography>
                    }
                    onChange={handleActiveFilterToggle}
                    inline
                  />
                </Stack>
              }
            />
          }
          table={table}
        />
      </SearchResult>
    </>
  );
};
