import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LineTemplatesTable } from 'components/lineTemplate/LineTemplatesTable';
import {
  clearCurrentComposition,
  getAssignedComposition,
  getLineTemplates,
  updateLineTemplate,
} from 'features/composition/compositionActions';
import {
  COMPOSITION_VEHICLE_LOADING,
  CompositionVehicle,
} from 'routes/сomposition/CompositionVehicle';
import { useDispatch, useSelector } from 'store/utils';
import { CompositionManage } from 'components/compositionManage/CompositionManage';
import { CompositionsPanel } from 'components/palettePanel/CompositionsPanel';
import { setLoadingKey } from 'features/common/commonActions';
import { LineTemplateDto } from 'dto/lineTemplate';
import { setCurrentVehicle } from 'features/vehicle/vehicleActions';
import {
  compositionConstructSelector,
  compositionsSelector,
} from 'features/composition/compositionSelectors';
import { Modal } from '@fleet/shared/mui';
import { CompositionRowDto } from 'dto/composition';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';

interface Props
  extends RouteComponentProps<
    { lineTemplateId: string },
    {},
    { lineTemplate?: LineTemplateDto }
  > {
  updateCompositionControl: (payload: LineTemplateDto[]) => JSX.Element;
  compositionId: string;
  refreshComposition: boolean;
}

export const LineTemplateCompositionEdit: FC<Props> = (props) => {
  const {
    location,
    history,
    match,
    compositionId,
    updateCompositionControl,
    refreshComposition,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state = {}, search, pathname } = location;
  const { lineTemplate } = state;
  const { params } = match;
  const [replaceComposition, setReplaceComposition] =
    useState<CompositionRowDto | null>(null);
  const compositionOptions = useSelector(compositionsSelector);
  const readCurrentLineTemplate = useCallback(
    async (cb?: () => void) => {
      const {
        items: [lineTemplate],
      } = await dispatch(
        getLineTemplates({ id: +params.lineTemplateId })
      ).unwrap();
      history.replace({
        pathname: location.pathname,
        search: location.search,
        state: { lineTemplate },
      });
      cb?.();
    },
    [
      dispatch,
      history,
      location.pathname,
      location.search,
      params.lineTemplateId,
    ]
  );
  const readLineTemplateComposition = useCallback(
    async (compositionId: string) => {
      try {
        dispatch(setLoadingKey(COMPOSITION_VEHICLE_LOADING));
        await dispatch(
          getAssignedComposition({ compositionId, type: 'line-template' })
        );
      } catch (e) {
      } finally {
        dispatch(setLoadingKey(null));
      }
    },
    [dispatch]
  );
  const compositionConstruct = useSelector(compositionConstructSelector);

  useEffect(() => {
    (async () => {
      if (!state.lineTemplate) {
        await readCurrentLineTemplate();
      } else {
        readLineTemplateComposition(compositionId);
      }
    })();

    return () => {
      dispatch(clearCurrentComposition());
      dispatch(setCurrentVehicle());
    };
  }, [
    compositionId,
    dispatch,
    readCurrentLineTemplate,
    readLineTemplateComposition,
    state.lineTemplate,
  ]);

  useEffect(() => {
    if (refreshComposition) {
      readCurrentLineTemplate(() => readLineTemplateComposition(compositionId));
    }
  }, [
    compositionId,
    readCurrentLineTemplate,
    readLineTemplateComposition,
    refreshComposition,
  ]);

  const onCompositionDrop = useCallback(
    async (compositionId: string) => {
      const compositionToReplace = compositionOptions.find(
        ({ id }) => id === +compositionId!
      )!;
      setReplaceComposition(compositionToReplace);
    },
    [compositionOptions]
  );

  const replaceCurrentComposition = useCallback(async () => {
    const { id, name } = replaceComposition!;
    const { vehicleCompositions } = lineTemplate!;
    const compositionToReplace = vehicleCompositions.find(
      ({ lineTemplateVehicleCompositionId }) =>
        lineTemplateVehicleCompositionId === +compositionId!
    )!;
    const {
      lineTemplateVehicleCompositionId,
      startDate,
      endDate,
      departureTimes,
      vehicleCompositionDirectionId,
    } = compositionToReplace;

    await dispatch(
      updateLineTemplate({
        startDate,
        endDate,
        departureTimes,
        lineTemplateId: lineTemplateVehicleCompositionId,
        vehicleCompositionId: id,
        vehicleCompositionDirectionId,
      })
    ).unwrap();

    await readLineTemplateComposition(compositionId);

    history.replace({
      state: {
        lineTemplate: {
          ...lineTemplate!,
          vehicleCompositions: vehicleCompositions.map((composition) =>
            composition.lineTemplateVehicleCompositionId === +compositionId
              ? { ...composition, name, id }
              : composition
          ),
        },
      },
      search,
      pathname,
    });
  }, [
    compositionId,
    dispatch,
    history,
    lineTemplate,
    pathname,
    readLineTemplateComposition,
    replaceComposition,
    search,
  ]);

  const onVehicleChange = useCallback(
    (orderNumber: number) => {
      dispatch(
        setCurrentVehicle(
          compositionConstruct!.compositionVehiclesData![orderNumber - 1]!
        )
      );
    },
    [compositionConstruct, dispatch]
  );

  const vehicleTabsRender = useCallback(
    (props) => (
      <CompositionVehicle
        key={props.vehicle.orderNumber}
        {...props}
        readOnly
        actions={{ onVehicleChange }}
      />
    ),
    [onVehicleChange]
  );
  const lineTemplateCompositions = useMemo(() => {
    return state.lineTemplate
      ? state.lineTemplate.vehicleCompositions.map(
          ({ lineTemplateVehicleCompositionId, name }) => ({
            id: lineTemplateVehicleCompositionId,
            name,
          })
        )
      : [];
  }, [state.lineTemplate]);

  if (!state.lineTemplate) return null;

  return (
    <div className="line-template-composition-edit panel">
      <h2>
        <TransTitle i18nKey="manageCompositionForLineTemplate" />
      </h2>
      <LineTemplatesTable
        data={[state.lineTemplate]}
        controlsAccessor={updateCompositionControl}
        initialExpanded={{ '0': true }}
        renderRowSubComponent={() => (
          <CompositionManage
            compositionsList={lineTemplateCompositions}
            palettePanel={<CompositionsPanel />}
            onViewerDrop={onCompositionDrop}
            vehicleTabsRenderFn={vehicleTabsRender}
            readOnly
          />
        )}
      />
      {replaceComposition && (
        <Modal
          open
          title={<TransTitle i18nKey="replaceConfirmation" />}
          message={
            t('message.replacingCurrentComposition', {
              defaultValue:
                'You are replacing current composition with {{name}}',
              name: replaceComposition.name,
            })!
          }
          actionButton={{
            label: <TransButton i18nKey="update" />,
            onClick: replaceCurrentComposition,
          }}
          onClose={setReplaceComposition.bind(null, null)}
        />
      )}
    </div>
  );
};
