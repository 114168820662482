import { createLoadingSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
  getFloorElementsList,
  getInventoryBlockingReasons,
  getInventoryClasses,
  getServiceCodes,
} from 'features/classification/classificationActions';
import {
  copySiAllocationRuleset,
  createSiAllocationRuleset,
  createUpdateSiAllocationRulesetRule,
  deleteSiAllocationRuleset,
  deleteSiAllocationRulesetRule,
  getSiAllocationRuleset,
  updateSiAllocationRuleset,
} from 'features/siAllocationRuleset/siAllocationRulesetActions';
import {
  deleteSiServiceAllocationRuleset,
  getSiServiceAllocationRuleset,
  getSiServiceAllocationRulesets,
  createUpdateSiServiceAllocationRuleset,
} from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetActions';

export const appLoadingSelector = createLoadingSelector(
  getBusinessEntities,
  getClassifications,
  getInventoryClasses,
  getInventoryBlockingReasons,
  getFloorElementsList,
  getServiceCodes
);

export const siAllocationRulesetSelector = createLoadingSelector(
  getSiAllocationRuleset,
  createSiAllocationRuleset,
  updateSiAllocationRuleset,
  copySiAllocationRuleset,
  deleteSiAllocationRuleset,
  createUpdateSiAllocationRulesetRule,
  deleteSiAllocationRulesetRule
);

export const serviceSiAllocationRulesetsSelector = createLoadingSelector(
  getSiServiceAllocationRulesets
);

export const serviceSiAllocationRulesetSelector = createLoadingSelector(
  getSiServiceAllocationRuleset,
  createUpdateSiServiceAllocationRuleset,
  deleteSiServiceAllocationRuleset
);
