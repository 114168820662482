import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import {
  FormatAlignCenterButton,
  FormatAlignLeftButton,
  FormatAlignRightButton,
  FormatBoldButton,
  FormatCodeButton,
  FormatHeadingButtons,
  FormatItalicButton,
  FormatLinkButton,
  FormatListBulletedButton,
  FormatListNumberedButton,
  FormatQuoteButton,
  FormatStrikeButton,
  FormatUnderlinedButton,
  InsertImageButton,
} from 'components/common/editor/buttons';
import { Divider, Stack } from '@mui/material';
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeBlockExtension,
  HeadingExtension,
  ImageExtension,
  ItalicExtension,
  LinkExtension,
  NodeFormattingExtension,
  OrderedListExtension,
  PlaceholderExtension,
  StrikeExtension,
  UnderlineExtension,
} from 'remirror/extensions';

// quote, link, image, heading, undo, redo
export const extensions = () => [
  new BlockquoteExtension(),
  new BoldExtension({}),
  new BulletListExtension(),
  new CodeBlockExtension(),
  new HeadingExtension(),
  new ImageExtension({ enableResizing: true }),
  new ItalicExtension(),
  new LinkExtension(),
  new NodeFormattingExtension(),
  new OrderedListExtension(),
  new PlaceholderExtension({ placeholder: 'Describe content here...' }),
  new StrikeExtension(),
  new UnderlineExtension(),
];

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.secondary,
      flexWrap: 'wrap',

      '& span > .MuiButton-root': {
        padding: '4px',
        transform: 'scale(.75)',
      },
    },
  }),
  {
    name: 'Toolbar',
  }
);

interface ToolbarProps {}

export const Toolbar: FC<ToolbarProps> = () => {
  const classes = useStyles();

  return (
    <>
      <Stack direction="row" className={classes.root}>
        <FormatBoldButton />
        <FormatItalicButton />
        <FormatStrikeButton />
        <FormatUnderlinedButton />
        <FormatHeadingButtons />
        <FormatLinkButton />
        <InsertImageButton />
      </Stack>
      <Stack direction="row" className={classes.root}>
        <FormatAlignLeftButton />
        <FormatAlignCenterButton />
        <FormatAlignRightButton />
        <FormatListBulletedButton />
        <FormatListNumberedButton />
        <FormatQuoteButton />
        <FormatCodeButton />
      </Stack>
      <Divider />
    </>
  );
};
