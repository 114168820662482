import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ElementDetailsRow } from 'components/elements/ElementDetailsRow';
import { useDropzone } from 'react-dropzone';
import { ElementIcon } from 'components/elements/ElementIcon';
import { Button, FormHelperText } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import { classifierSubTypeMapSelector } from 'features/classification/classificationSelectors';
import { FloorElementIcon } from 'dto/floor';
import { getSignLocalizations } from 'features/element/elementActions';
import { useRouteMatch } from 'react-router-dom';
import { Localization, ElementTab } from 'dto/element';
import { ElementLocalizationFields } from 'components/elements/ElementLocalizationFields';
import { TransLabel } from 'i18n/trans/label';
import { useTranslation } from 'react-i18next';
import { Classifier } from '@fleet/shared/dto/classifier';
import { TransButton } from 'i18n/trans/button';

export interface ElementFormValues extends Classifier {
  type: string;
  subTypeId: string;
  category: string;
  icon: FloorElementIcon | null;
  iconCode: string;
  localizations: Array<Localization>;
}

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const maxIconSize = 51200;

export const ElementForm = () => {
  const { control, setError, setValue, register, watch } =
    useFormContext<ElementFormValues>();
  const { icon: iconValue, subTypeId } = watch();
  const dispatch = useDispatch();
  const {
    params: { category, id },
  } = useRouteMatch<{ category: ElementTab; id: string }>();

  const isSign = useMemo(() => category === 'sign', [category]);
  useEffect(() => {
    if (isSign)
      dispatch(getSignLocalizations(id))
        .unwrap()
        .then(({ localizations }) => setValue('localizations', localizations));
  }, [isSign, dispatch, id, setValue]);

  const { t } = useTranslation();
  const handleDropIcon = useCallback(
    async ([file]: Array<File>) => {
      if (file.size > maxIconSize) {
        return setError('icon', {
          message: t('message.fileSizeMax50Kb', 'File can not exceed 50 kB')!,
        });
      }
      if (!file) return;
      setValue(
        'icon',
        {
          name: file.name,
          contentType: file.type,
          contentLength: file.size,
          content: await toBase64(file),
        },
        {
          shouldValidate: true,
        }
      );
    },
    [setError, setValue, t]
  );
  const handleDeleteIcon = useCallback(() => {
    setValue('icon', null, { shouldValidate: true });
  }, [setValue]);

  const handleValidateIcon = useCallback(
    (value: ElementFormValues['icon']) =>
      Boolean(value === null || value?.content),
    []
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleDropIcon,
    accept: 'image/svg+xml',
    multiple: false,
  });

  const signSubTypeMap = useSelector(classifierSubTypeMapSelector);

  return (
    <>
      <ElementDetailsRow
        columns={[
          <TransLabel i18nKey="name" />,
          <Controller
            control={control}
            name="name"
            render={({ field: { value } }) => <>{value}</>}
          />,
        ]}
      />
      <ElementDetailsRow
        columns={[
          <TransLabel i18nKey="type" />,
          <Controller
            control={control}
            name="type"
            render={({ field: { value } }) => <>{value}</>}
          />,
        ]}
      />
      <ElementDetailsRow
        columns={[
          <TransLabel i18nKey="subType" />,
          <Controller
            control={control}
            name="subTypeId"
            render={({ field: { value } }) => (
              <>{signSubTypeMap.get(value)?.name}</>
            )}
          />,
        ]}
      />
      <ElementDetailsRow
        columns={[
          <TransLabel i18nKey="code" />,
          <Controller
            control={control}
            name="iconCode"
            render={({ field: { value } }) => <>{value}</>}
          />,
        ]}
      />
      <ElementDetailsRow columns={[<TransLabel i18nKey="fileUpload" />]} />
      <>
        <ElementDetailsRow
          sx={{ verticalAlign: 'top' }}
          columns={[
            <div {...getRootProps()}>
              <ElementIcon icon={iconValue?.content ?? ''} sx={{ width: 80 }} />
              <input
                type="hidden"
                {...register('icon', {
                  validate: handleValidateIcon,
                })}
              />
              <input {...getInputProps()} />
            </div>,
            <>
              <div>
                <Button
                  variant="text"
                  color="primary"
                  onClick={open}
                  startIcon={<Icon name="upload" />}
                >
                  <TransButton i18nKey="uploadNewFile" />
                </Button>
              </div>
              {iconValue && iconValue?.content && (
                <div>
                  <Button
                    variant="text"
                    color="error"
                    startIcon={<Icon name="delete" />}
                    onClick={handleDeleteIcon}
                  >
                    <TransButton i18nKey="deleteFile" />
                  </Button>
                </div>
              )}
            </>,
          ]}
        />
        <ElementDetailsRow
          columns={[
            <Controller
              control={control}
              name="icon"
              render={({ fieldState }) => (
                <>
                  {fieldState.error?.message && (
                    <FormHelperText error>
                      {fieldState.error.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />,
          ]}
        />
      </>

      {isSign && (
        <ElementLocalizationFields
          availableLocalizations={
            signSubTypeMap.get(subTypeId)?.localizations ?? []
          }
        />
      )}
    </>
  );
};
