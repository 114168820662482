import type { FC } from 'react';
import type { TableColumns } from '@fleet/shared';
import type { SiServiceAllocationRuleset } from 'dto/siServiceAllocationRuleset';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { deleteSiServiceAllocationRuleset } from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetActions';
import { selectSiServiceAllocationRulesets } from 'features/siServiceAllocationRuleset/siServiceAllocationRulesetSelectors';
import {
  ConfirmDeleteModal,
  formatDate,
  Icon,
  SearchResult,
  Table,
  useModal,
  useRowActive,
  useRowSelectCheckbox,
  useTable,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { siAllocationRulesetMapSelector } from 'features/siAllocationRuleset/siAllocationRulesetSelectors';
import { Link, useHistory, useParams } from 'react-router-dom';
import { SiServiceAllocationRulesetSearchForm } from 'routes/siServiceAllocationRuleset/SiServiceAllocationRulesetSearchForm';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Button, CardHeader, Divider, Stack, Typography } from '@mui/material';
import { useRowSelect } from 'react-table';
import { useAlert } from 'react-alert';
import { TransModal } from 'i18n/trans/modal';
import { TransMessage } from 'i18n/trans/message';
import { TransButton } from 'i18n/trans/button';

interface SiServiceAllocationRulesetTableProps {}

export const SiServiceAllocationRulesetTable: FC<SiServiceAllocationRulesetTableProps> =
  () => {
    const dispatch = useDispatch();

    const data = useSelector(selectSiServiceAllocationRulesets);

    const siAllocationRulesetMap = useSelector(siAllocationRulesetMapSelector);
    const columns = useMemo<TableColumns<SiServiceAllocationRuleset>>(
      () => [
        {
          accessor: 'sleeperInventoryAllocationRulesetId',
          Header: <TransTableHead i18nKey="siAllocationRuleset" />,
          Cell: ({ row, value }) => (
            <Link to={`/service-allocation-rulesets/edit/${row.original.id}`}>
              {siAllocationRulesetMap.get(value)}
            </Link>
          ),
        },
        {
          accessor: 'serviceCodes',
          Header: <TransTableHead i18nKey="serviceCodes" />,
        },
        {
          accessor: 'isException',
          Header: <TransTableHead i18nKey="isException" />,
          Cell: ({ value }) => value && <Icon name="check" color="success" />,
          width: 56,
        },
        {
          accessor: 'validityPeriod',
          Header: <TransTableHead i18nKey="validity" />,
          Cell: ({ value }) =>
            [value.from, value.to]
              .filter(Boolean)
              .map((date) => formatDate(date))
              .join(' - '),
        },
        {
          accessor: 'exceptionDates',
          Header: <TransTableHead i18nKey="exceptionDates" />,
          Cell: ({ value }) =>
            (value || []).map((date) => formatDate(date)).join(', '),
        },
      ],
      [siAllocationRulesetMap]
    );

    const table = useTable<SiServiceAllocationRuleset>(
      {
        data,
        columns,
      },
      useRowActive,
      useRowSelect,
      useRowSelectCheckbox
    );

    const deletionModal = useModal();
    const { activeFlatRow, resetRowActive, selectedFlatRows } = table;

    const history = useHistory();
    useEffect(() => {
      if (activeFlatRow)
        history.replace(
          `/service-allocation-rulesets/edit/${activeFlatRow.original.id}`
        );
    }, [activeFlatRow, history]);

    const { id } = useParams<{ id?: string }>();
    useEffect(() => {
      if (!id) resetRowActive();
    }, [id, resetRowActive]);

    const alert = useAlert();
    const handleRowsRemove = useCallback(async () => {
      await dispatch(
        deleteSiServiceAllocationRuleset(
          selectedFlatRows.map(({ original }) => original.id)
        )
      ).unwrap();
      deletionModal.onClose();
      alert.success(
        <TransMessage i18nKey="serviceSiAllocationRulesetsDeleted" />
      );
    }, [dispatch, selectedFlatRows, deletionModal, alert]);

    return (
      <>
        <SiServiceAllocationRulesetSearchForm />

        <Divider />

        <SearchResult results={!!data.length}>
          <Table
            caption={
              <CardHeader
                subheader={
                  <Stack direction="row" alignItems="center">
                    <Typography variant="subtitle" fontWeight="700">
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>

                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      <TransSubtitle
                        i18nKey="allocationsQty"
                        values={{ count: data?.length }}
                        tOptions={{ postProcess: 'interval' }}
                      />
                    </Typography>
                  </Stack>
                }
                action={
                  <Button
                    startIcon={<Icon name="trash" />}
                    sx={{ p: 0, whitespace: 'nowrap' }}
                    onClick={deletionModal.onOpen}
                    disabled={!Object.keys(table.state.selectedRowIds).length}
                    color="error"
                  >
                    <TransButton i18nKey="deleteSelected" />
                  </Button>
                }
              />
            }
            table={table}
          />
        </SearchResult>

        <ConfirmDeleteModal
          handleDelete={handleRowsRemove}
          title={<TransModal i18nKey="deleteServiceSiAllocationRuleset" />}
          description={
            <TransModal i18nKey="deleteServiceSiAllocationRulesetDescription" />
          }
          isOpen={deletionModal.open}
          onClose={deletionModal.onClose}
        />
      </>
    );
  };
