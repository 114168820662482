import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { useAttrs, useChainedCommands } from '@remirror/react';
import { ToolbarButton } from 'components/common/editor/createButton';
import FormatLinkIcon from '@mui/icons-material/InsertLink';
import { Popper } from '@fleet/shared/mui';
import { ReactFrameworkOutput } from '@remirror/react-core/dist/declarations/src/react-types';
import { useTranslation } from 'react-i18next';
import { InputBase } from '@mui/material';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    input: {
      zIndex: 1,
      padding: 8,
    },
    popper: {
      zIndex: theme.zIndex.drawer + 1,
      background: theme.palette.common.white,
      '& .input': {
        border: 0,
      },
    },
  }),
  {
    name: 'FormatLinkButton',
  }
);

interface FormatLinkButtonProps {}

export const FormatLinkButton: FC<FormatLinkButtonProps> = () => {
  const url = (useAttrs().link()?.href as string) ?? '';
  const [href, setHref] = useState<string>(url);
  useEffect(() => {
    setHref(url);
  }, [url]);
  const chain = useChainedCommands();
  const [selection, setSelection] = useState({ to: 0 });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = useCallback(
    (
      event: MouseEvent<HTMLButtonElement>,
      context: ReactFrameworkOutput<Remirror.Extensions>
    ) => {
      const { currentTarget } = event;
      const selection = context.getState().selection;
      setSelection(selection);
      setAnchorEl((prev) => (prev ? null : currentTarget));
    },
    []
  );
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setHref(event.target.value);
  }, []);
  const handleSubmit = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const { code } = event;
      if (['Enter', 'Escape'].includes(code)) {
        if (code === 'Enter') {
          if (href === '') {
            chain.removeLink();
          } else {
            chain.updateLink({ href, auto: false });
          }
        }
        chain.focus(selection.to).run();
        handleClose();
      }
    },
    [chain, handleClose, href, selection.to]
  );

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <ToolbarButton
        commandName="updateLink"
        onClick={handleClick}
        className={classes.root}
        children={<FormatLinkIcon />}
      />

      <Popper
        open={Boolean(anchorEl)}
        reference={anchorEl}
        onClickOutside={handleClose}
        className={classes.popper}
      >
        <InputBase
          className={classes.input}
          autoFocus
          placeholder={t('label.enterLink', 'Enter link...')!}
          onChange={handleChange}
          value={href}
          onKeyPress={handleSubmit}
        />
      </Popper>
    </>
  );
};
