import { useCallback, useMemo, useState, MouseEvent } from 'react';
import {
  LineTemplateDto,
  LineTemplateVehicleComposition,
} from 'dto/lineTemplate';
import { TripDto, TripVehicleComposition } from 'dto/trip';
import { CompositionConstructDto } from 'dto/composition';
import { ModalProps } from '@fleet/shared/mui/Modal';

type lineTemplateModalPayload = {
  lineTemplates: LineTemplateDto[];
  vehicleComposition:
    | LineTemplateVehicleComposition
    | CompositionConstructDto
    | {};
  action: string;
};

type tripModalPayload = {
  trips: TripDto[];
  vehicleComposition?: Partial<TripVehicleComposition>;
  action: string;
};

export const useCompositionManage = (entity: 'trip' | 'lineTemplate') => {
  const lineTemplateInitialState = useMemo(
    () => ({
      lineTemplates: [],
      vehicleComposition: null,
      refreshSearch: false,
    }),
    []
  );
  const tripInitialState = useMemo(
    () => ({
      trips: [],
      vehicleComposition: null,
      refreshSearch: false,
    }),
    []
  );
  const [lineTemplateState, setLineTemplateState] = useState<{
    lineTemplates: LineTemplateDto[];
    vehicleComposition: LineTemplateVehicleComposition | null;
    action?: 'add' | 'replace' | 'delete';
    refreshSearch: boolean;
    autoResetExpanded?: boolean;
  }>(lineTemplateInitialState);
  const [tripState, setTripState] = useState<{
    trips?: TripDto[];
    vehicleComposition: TripVehicleComposition | null;
    action?: 'add' | 'replace' | 'delete';
    refreshSearch: boolean;
    autoResetExpanded?: boolean;
  }>(tripInitialState);
  const setState = useCallback(
    (param) => {
      if (entity === 'lineTemplate') {
        setLineTemplateState((s) => ({ ...s, ...param }));
      } else {
        setTripState((s) => ({ ...s, ...param }));
      }
    },
    [entity]
  );

  const getModalHandler = useCallback(
    (payload: lineTemplateModalPayload | tripModalPayload) =>
      (e?: MouseEvent): void => {
        e?.stopPropagation();
        if (entity === 'trip') {
          setState(payload as tripModalPayload);
        } else {
          setState(payload as lineTemplateModalPayload);
        }
      },
    [entity, setState]
  );
  const onModalClose = useCallback<Required<ModalProps>['onClose']>(
    (event, reason) => {
      const isCancelClose = reason !== 'action';
      const { action } = entity === 'trip' ? tripState : lineTemplateState;
      setState({
        ...(entity === 'trip' ? tripInitialState : lineTemplateInitialState),
        ...(!isCancelClose && {
          refreshSearch: true,
          autoResetExpanded: action === 'delete',
        }),
      });

      if (!isCancelClose) {
        setTimeout(() =>
          setState({
            refreshSearch: false,
          })
        );
      }
    },
    [
      entity,
      lineTemplateInitialState,
      lineTemplateState,
      setState,
      tripInitialState,
      tripState,
    ]
  );

  return {
    tripState,
    lineTemplateState,
    getModalHandler,
    onModalClose,
  };
};
