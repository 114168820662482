import 'components/common/editor/editor.scss';
import { forwardRef, useCallback } from 'react';
import {
  htmlToProsemirrorNode,
  prosemirrorNodeToHtml,
  RemirrorEventListener,
} from 'remirror';
import {
  ThemeProvider,
  Remirror,
  useRemirror,
  EditorComponent,
} from '@remirror/react';
import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';
import { Toolbar, extensions } from 'components/common/editor/Toolbar';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      fontWeight: 400,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '&:focus': {
        outline: 0,
      },
    },
  }),
  {
    name: 'Editor',
  }
);

interface EditorProps {
  value: string;
  onChange: (value: string) => void;
}

export const Editor = forwardRef<typeof Remirror, EditorProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ value, onChange }, ref) => {
    const remirror = useRemirror({
      extensions,
      content: value,
      stringHandler: htmlToProsemirrorNode,
    });

    const { manager, state, onChange: handleRemirrorChange } = remirror;

    const classes = useStyles();
    const handleChange: RemirrorEventListener<Remirror.Extensions> =
      useCallback(
        (params) => {
          handleRemirrorChange(params);
          onChange(prosemirrorNodeToHtml(params.state.doc));
        },
        [handleRemirrorChange, onChange]
      );

    return (
      <Paper variant="outlined">
        <ThemeProvider>
          <Remirror
            manager={manager}
            onChange={handleChange}
            initialContent={state}
            autoRender={false}
            classNames={[classes.root]}
          >
            <Toolbar />
            <EditorComponent />
          </Remirror>
        </ThemeProvider>
      </Paper>
    );
  }
);
