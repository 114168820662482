import { createAsyncThunk } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { CarriageBlockingValues, DepartureTimes } from 'dto/vehicle';
import { LineTemplateDto, LineTemplateFilterDto } from 'dto/lineTemplate';
import { createAction } from '@reduxjs/toolkit';
import _first from 'lodash/first';
import _last from 'lodash/last';
import _find from 'lodash/find';
import {
  readVehicle,
  setCurrentVehicle,
} from 'features/vehicle/vehicleActions';
import {
  AssignedComposition,
  CompositionConstructDto,
  CompositionRowDto,
  LineTransportationTypes,
  TripCompositionProcess,
} from 'dto/composition';
import { CompositionConstructVehicleDto } from '@fleet/widget/dto/composition';
import qs from 'qs';
import { TripDto, TripFilterDto } from 'dto/trip';
import { ManageableSpace } from '@fleet/widget/dto/floor';
import { Pagination } from '@fleet/shared/dto/pagination';
import { api } from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';
import { compositionConstructSelector } from 'features/composition/compositionSelectors';

export const setCompositionProcessId = createAction<string | undefined>(
  'setCompositionProcessId'
);

export const createComposition = createAsyncThunk<number, void>(
  'createComposition',
  async (_, { getState }) => {
    const state = getState();
    return (
      await api.post(
        `/organizations/${currentBusinessEntityIdSelector(
          state
        )}/vehicle-compositions`,
        { name: `Untitled-${state.composition.list.length}` }
      )
    ).data.id;
  }
);

export const getCompositions = createAsyncThunk<
  { vehicleCompositions: Array<CompositionRowDto> },
  { searchString: string; transportationTypeId?: string } | undefined
>('getCompositions', async (params, { getState }) => {
  const state = getState();
  return (
    await api.get<{ vehicleCompositions: Array<CompositionRowDto> }>(
      `/organizations/${currentBusinessEntityIdSelector(
        state
      )}/vehicle-compositions/all?${qs.stringify({ ...params })}`
    )
  ).data;
});

export const clearCurrentComposition = createAction('clearCurrentComposition');

export const getComposition = createAsyncThunk<CompositionConstructDto, string>(
  'getComposition',
  async (compositionId, { getState }) => {
    const composition = (
      await api.get<CompositionConstructDto>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicle-compositions/${compositionId}`
      )
    ).data;
    const {
      compositionVehicles: [vehicle],
    } = composition;

    return {
      ...composition,
      id: Number(compositionId),
      vehicle,
    };
  }
);

export const getAssignedComposition = createAsyncThunk<
  CompositionConstructDto,
  {
    compositionId: string;
    type: 'trip' | 'line-template';
    orderNumber?: number;
  }
>(
  'getAssignedComposition',
  async ({ compositionId, type, orderNumber }, { getState, dispatch }) => {
    const state = getState();
    const organizationId = currentBusinessEntityIdSelector(state);

    const composition = (
      await api.get<AssignedComposition>(
        `/organizations/${organizationId}/${type}-vehicle-compositions/${compositionId}`
      )
    ).data;

    const {
      vehicles,
      tripVehicleCompositionId,
      lineTemplateVehicleCompositionId,
      vehicleCompositionDirection,
      vehicleCompositionCode,
      vehicleCompositionId,
      vehicleCompositionName,
      vehicleCompositionTransportationType,
      ...restComposition
    } = composition;
    let compositionTotalCapacity = {};
    let processHistory = {};

    if (type === 'trip') {
      processHistory = (
        await api.get<{ processes: TripCompositionProcess[] }>(
          `/organizations/${organizationId}/${type}-vehicle-compositions/${compositionId}/process-history`
        )
      ).data;
      const { totalCapacity } = composition;
      const { totalPlaces, ...inventoryClasses } =
        totalCapacity.inventoryClasses.reduce(
          (acc, { inventoryClass, count }) => ({
            ...acc,
            [inventoryClass.name]: count,
            totalPlaces: acc.totalPlaces + count,
          }),
          { totalPlaces: 0 }
        );
      const { totalManageableSpaces, ...manageableSpaces } =
        totalCapacity.manageableSpaces.reduce(
          (acc, { manageableSpace, count }) => ({
            ...acc,
            [manageableSpace.name]: count,
            totalManageableSpaces: acc.totalManageableSpaces + count,
          }),
          { totalManageableSpaces: 0 }
        );

      compositionTotalCapacity = {
        totalCapacity: {
          totalPlaces,
          totalManageableSpaces,
          inventoryClasses,
          manageableSpaces,
        },
      };
    }
    const selectedVehicle =
      vehicles.find((vehicle) => vehicle.orderNumber === orderNumber) ||
      _first(vehicles);

    dispatch(setCurrentVehicle(selectedVehicle));

    return {
      id:
        type === 'trip'
          ? tripVehicleCompositionId!
          : lineTemplateVehicleCompositionId!,
      vehicleCompositionId,
      name: vehicleCompositionName,
      code: vehicleCompositionCode,
      vehicleCompositionDirection,
      vehicle: selectedVehicle
        ? {
            id: selectedVehicle.id,
            vehicleId: selectedVehicle.id,
            orderNumber: selectedVehicle.orderNumber,
            isVehicleFlipped: selectedVehicle.isVehicleFlipped,
            isBlocked: selectedVehicle.isBlocked,
            blockingReason: selectedVehicle.blockingReason,
            blockingOriginStop: selectedVehicle.blockingOriginStop,
            blockingDestinationStop: selectedVehicle.blockingDestinationStop,
            number: selectedVehicle.number,
            salesOpeningPriority: selectedVehicle.salesOpeningPriority,
            salesOpeningThresholdPercentage:
              selectedVehicle.salesOpeningThresholdPercentage,
          }
        : undefined,
      compositionVehicles: vehicles.map(
        ({
          id,
          orderNumber,
          isVehicleFlipped,
          floors,
          isBlocked,
          blockingReason,
          blockingOriginStop,
          blockingDestinationStop,
          number,
          salesOpeningPriority,
          salesOpeningThresholdPercentage,
        }) => ({
          id: id,
          vehicleId: id,
          orderNumber,
          isVehicleFlipped,
          floors,
          isBlocked,
          blockingReason,
          blockingOriginStop,
          blockingDestinationStop,
          number,
          salesOpeningPriority,
          salesOpeningThresholdPercentage,
        })
      ),
      compositionVehiclesData: vehicles,
      transportationTypeId: vehicleCompositionTransportationType.id,
      transportationTypeName: vehicleCompositionTransportationType.name,
      ...restComposition,
      ...processHistory,
      ...compositionTotalCapacity,
    } as unknown as CompositionConstructDto;
  }
);

export const setCurrentCompositionVehicle = createAction<
  CompositionConstructVehicleDto | undefined
>('setCurrentCompositionVehicle');

export const setCurrentCompositionLineTemplates = createAction<{
  data: LineTemplateDto[];
  totalCount: number;
  offset: number;
}>('setCurrentCompositionLineTemplates');

export const setCurrentCompositionTrips = createAction<{
  data: TripDto[];
  totalCount: number;
  offset: number;
}>('setCurrentCompositionTrips');

export const setCompositionsTransportType =
  createAction<LineTransportationTypes>('setCompositionsTransportType');

export const duplicateComposition = createAsyncThunk(
  'duplicateComposition',
  async (id: number, { dispatch, getState }) => {
    const composition = (
      await api.post(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicle-compositions/${id}/duplicate`
      )
    ).data;

    dispatch(getCompositions());

    return composition.id;
  }
);

export const deleteComposition = createAsyncThunk(
  'deleteComposition',
  async (id: number, { dispatch, getState }) => {
    await api.delete(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicle-compositions/${id}`
    );

    dispatch(getCompositions());
  }
);

export const updateComposition = createAsyncThunk<
  unknown,
  {
    compositionId: number | string;
    name: string;
    code?: string;
    number?: string;
  }
>(
  'updateComposition',
  async ({ compositionId, code, ...data }, { getState, dispatch }) => {
    const compositionConstruct = compositionConstructSelector(getState());

    await api.put(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicle-compositions/${compositionId}`,
      { ...data, code: code ?? compositionConstruct?.code }
    );

    await dispatch(getComposition(`${compositionId}`));
  }
);

const addVehicleToComposition = createAsyncThunk<
  Pick<CompositionConstructVehicleDto, 'id' | 'orderNumber'>,
  {
    compositionId: string;
    vehicleId: string;
  }
>(
  'addVehicleToComposition',
  async ({ compositionId, vehicleId }, { getState }) =>
    (
      await api.post(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicle-compositions/${compositionId}/vehicles`,
        { id: vehicleId }
      )
    ).data
);

export const pushVehicleToComposition = createAsyncThunk<
  unknown,
  {
    compositionId: string;
    vehicleId: string;
  }
>('pushVehicleToComposition', async (data, { dispatch }) => {
  await dispatch(addVehicleToComposition(data));
  const [{ compositionVehicles }] = await Promise.all([
    dispatch(getComposition(`${data.compositionId}`)).unwrap(),
    dispatch(readVehicle(Number(data.vehicleId))),
  ]);

  const vehicle = _last(compositionVehicles)!;
  dispatch(setCurrentCompositionVehicle(vehicle));
});

export const duplicateVehicleComposition = createAsyncThunk<
  unknown,
  {
    compositionId: string;
    compositionVehicleId: string;
    orderNumber: number;
    isVehicleFlipped: boolean;
    number: string;
  }
>(
  'duplicateVehicleComposition',
  async (
    { compositionId, compositionVehicleId, ...data },
    { dispatch, getState }
  ) => {
    (
      await api.post(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/vehicle-compositions/${compositionId}/vehicles/${compositionVehicleId}/copy`,
        data
      )
    ).data;
    const { compositionVehicles } = await dispatch(
      getComposition(`${compositionId}`)
    ).unwrap();
    const vehicle = _find(
      compositionVehicles,
      (vehicle) => `${vehicle.id}` === compositionVehicleId
    )!;
    dispatch(setCurrentCompositionVehicle(vehicle));
  }
);

export const updateCompositionVehicle = createAsyncThunk<
  unknown,
  {
    compositionId: string;
    compositionVehicleId: string;
    orderNumber?: number;
    isVehicleFlipped?: boolean;
    isBlocked?: boolean;
    number: string;
    salesOpeningPriority?: number;
    salesOpeningThresholdPercentage?: number;
    blockingReasonId?: string;
    blockingOriginStopId?: number;
    blockingDestinationStopId?: number;
  }
>(
  'updateCompositionVehicle',
  async (
    { compositionId, compositionVehicleId, ...data },
    { getState, dispatch }
  ) => {
    await api.put(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicle-compositions/${compositionId}/vehicles/${compositionVehicleId}`,
      data
    );

    const { compositionVehicles } = await dispatch(
      getComposition(`${compositionId}`)
    ).unwrap();
    const vehicle = _find(
      compositionVehicles,
      (vehicle) => `${vehicle.id}` === compositionVehicleId
    )!;
    dispatch(setCurrentCompositionVehicle(vehicle));
  }
);

export const deleteCompositionVehicle = createAsyncThunk<
  unknown,
  { compositionId: string; compositionVehicleId: string }
>(
  'deleteCompositionVehicle',
  async ({ compositionId, compositionVehicleId }, { getState, dispatch }) => {
    await api.delete(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/vehicle-compositions/${compositionId}/vehicles/${compositionVehicleId}`
    );

    const { compositionVehicles } = await dispatch(
      getComposition(`${compositionId}`)
    ).unwrap();

    const vehicle = _first(compositionVehicles)!;
    if (vehicle) {
      dispatch(setCurrentCompositionVehicle(vehicle));
      dispatch(readVehicle(Number(vehicle.vehicleId)));
    } else {
      dispatch(setCurrentVehicle());
    }
  }
);

export const getLineTemplates = createAsyncThunk<
  Pagination<LineTemplateDto>,
  Partial<LineTemplateFilterDto> & { offset?: number; limit?: number }
>('filterLineTemplates', async (payload, { getState }) => {
  const state = getState();
  const { limit = 10 } = payload;

  return (
    await api.post<Pagination<LineTemplateDto>>(
      `/organizations/${currentBusinessEntityIdSelector(
        state
      )}/line-templates/get`,
      { ...payload, limit },
      { baseURL: process.env.REACT_APP_API_LM }
    )
  ).data;
});

export interface LineTemplatePayload {
  lineTemplateId?: number;
  lineTemplateIds?: Array<number>;
  vehicleCompositionId: number;
  vehicleCompositionDirectionId: string;
  startDate?: string;
  endDate?: string;
  departureTimesFromTemplate?: boolean;
  departureTimes?: DepartureTimes[];
}

export const assignLineTemplate = createAsyncThunk<
  unknown,
  LineTemplatePayload
>('assignLineTemplate', async (payload, { getState }) => {
  await api.post<{ id: number }>(
    `/organizations/${currentBusinessEntityIdSelector(
      getState()
    )}/line-template-vehicle-compositions${
      payload.lineTemplateIds ? '/bulk' : ''
    }`,
    payload
  );
});

export const updateLineTemplate = createAsyncThunk<
  unknown,
  LineTemplatePayload
>(
  'updateLineTemplate',
  async ({ lineTemplateId, ...payload }, { getState, rejectWithValue }) => {
    try {
      await api.put<{ id: number }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/line-template-vehicle-compositions/${lineTemplateId}`,
        payload
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const blockLineTemplateVehicle = createAsyncThunk<
  unknown,
  { lineTemplateId: string; vehicleId: string } & CarriageBlockingValues
>(
  'blockLineTemplateVehicle',
  async (
    { lineTemplateId, vehicleId, ...payload },
    { getState, rejectWithValue }
  ) => {
    try {
      await api.put<{ id: number }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/line-template-vehicle-compositions/${lineTemplateId}/vehicles/${vehicleId}/blocking`,
        payload
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const removeLineTemplateRelation = createAsyncThunk<unknown, number>(
  'removeLineTemplateRelation',
  async (id, { getState }) => {
    await api.delete<never>(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/line-template-vehicle-compositions/${id}`
    );
  }
);

export const getTrips = createAsyncThunk<
  Pagination<TripDto>,
  Partial<TripFilterDto>
>('filterTrips', async (payload, { getState }) => {
  const state = getState();
  const { limit = 10 } = payload;

  return (
    await api.post<Pagination<TripDto>>(
      `/organizations/${currentBusinessEntityIdSelector(state)}/trips/get`,
      {
        ...payload,
        limit,
      },
      { baseURL: process.env.REACT_APP_API_LM }
    )
  ).data;
});

export interface TripVehicleReplacePayload {
  tripVehicleCompositionVehicleToDelete: number;
  newVehicleId: number;
  number: string;
}

export interface TripVehicleAddPayload {
  vehicleId: number;
  orderNumber: number;
  number: string;
}

export interface TripEditPayload {
  tripId?: number;
  tripRelationId?: number;
  vehicleCompositionId?: number;
  vehicleCompositionDirectionId: string;
  tripVehicleCompositionVehicles?: Array<{
    id: number;
    orderNumber: number;
    isVehicleFlipped: boolean;
    isBlocked?: boolean;
    blockingReason?: Classifier;
    blockingOriginStop?: Classifier<number>;
    blockingDestinationStop?: Classifier<number>;
    number?: string;
    salesOpeningPriority?: number;
    salesOpeningThresholdPercentage?: number;
  }>;
}

export interface CompositionElements {
  compartments?: Array<{
    id: string;
    inventoryClassId?: string;
    isBlocked?: boolean;
    blockingReasonId?: string;
  }>;
  places?: Array<{
    id: string;
    inventoryClassId?: string;
    rank?: number;
    isBlocked?: boolean;
    blockingReasonId?: string;
    isForOnBoardSalesOnly?: boolean;
    isReserved?: boolean;
    isInPetFriendlyArea?: boolean;
  }>;
  manageableSpaces?: Array<Omit<ManageableSpace, 'type' | 'typeId '>>;
}

export const assignCompositionToTrips = createAsyncThunk<
  unknown,
  {
    targetTripIds: Array<number>;
    vehicleCompositionId?: number;
    tripVehicleCompositionId?: number;
    vehicleCompositionDirectionId: string;
  }
>('assignCompositionToTrips', async (payload, { getState }) => {
  await api.post<{ id: number }>(
    `/organizations/${currentBusinessEntityIdSelector(
      getState()
    )}/trip-vehicle-compositions/from-${
      payload.tripVehicleCompositionId ? 'trip-vehicle' : 'vehicle'
    }-composition/bulk`,
    payload
  );
});

export const updateTrip = createAsyncThunk<unknown, TripEditPayload>(
  'updateTrip',
  async ({ tripRelationId, ...payload }, { getState, rejectWithValue }) => {
    try {
      await api.put<{ id: number }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/trip-vehicle-compositions/${tripRelationId}`,
        payload
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const replaceTripComposition = createAsyncThunk<
  { newCompositionId: number; processId: string },
  TripEditPayload
>(
  'replaceTripComposition',
  async ({ tripRelationId, ...payload }, { getState }) => {
    const { newCompositionId, processId } = (
      await api.put<{ newCompositionId: number; processId: string }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/trip-vehicle-compositions/${tripRelationId}/vehicle-composition`,
        payload
      )
    ).data;

    return { processId, newCompositionId };
  }
);

export const updateTripComposition = createAsyncThunk<unknown, TripEditPayload>(
  'updateTrip',
  async ({ tripRelationId, ...payload }, { getState }) => {
    await api.put<{ id: number }>(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/trip-vehicle-compositions/${tripRelationId}/vehicle-composition-vehicles`,
      {
        ...payload,
        tripVehicleCompositionVehicles:
          payload.tripVehicleCompositionVehicles?.map(
            ({
              blockingReason,
              blockingOriginStop,
              blockingDestinationStop,
              ...vehicle
            }) => ({
              ...vehicle,
              blockingReasonId: blockingReason?.id,
              blockingOriginStopId: blockingOriginStop?.id,
              blockingDestinationStopId: blockingDestinationStop?.id,
            })
          ),
      }
    );
  }
);

export const replaceTripCompositionVehicle = createAsyncThunk<
  { processId: string },
  TripVehicleReplacePayload & { tripRelationId: number }
>(
  'replaceTripCompositionVehicle',
  async ({ tripRelationId, ...payload }, { getState }) => {
    const { processId } = (
      await api.put<{ processId: string }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/trip-vehicle-compositions/${tripRelationId}/vehicle-composition-vehicles/replace`,
        payload
      )
    ).data;

    return { processId };
  }
);

export const addVehicleToTripComposition = createAsyncThunk<
  unknown,
  { tripRelationId: number; vehicleId: number }
>(
  'addVehicleToTripComposition',
  async ({ tripRelationId, vehicleId }, { getState }) => {
    await api.post<{ id: number }>(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/trip-vehicle-compositions/${tripRelationId}/vehicle`,
      { vehicleId }
    );
  }
);

export const insetVehicleToTripComposition = createAsyncThunk<
  { processId: string },
  TripVehicleAddPayload & { tripRelationId: number }
>(
  'addTripCompositionVehicle',
  async ({ tripRelationId, ...payload }, { getState }) => {
    const { processId } = (
      await api.put<{ processId: string }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/trip-vehicle-compositions/${tripRelationId}/vehicle-composition-vehicles/insert`,
        payload
      )
    ).data;

    return { processId };
  }
);

export const updateTripCompositionElements = createAsyncThunk<
  { processId: string },
  CompositionElements
>(
  'updateTripCompositionElements',
  async (payload, { getState, rejectWithValue }) => {
    const state = getState();
    const vehicleId = state.composition.construct!.vehicle!.id!;

    try {
      const { processId } = (
        await api.put<{ id: number; processId: string }>(
          `/organizations/${currentBusinessEntityIdSelector(
            state
          )}/trip-vehicle-composition-vehicles/${vehicleId}/floor-elements`,
          payload
        )
      ).data;

      return { processId };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const removeTripCompositionVehicle = createAsyncThunk<
  { processId: string },
  { compositionId: number; compositionVehicleId: number }
>(
  'removeTripCompositionVehicle',
  async ({ compositionId, compositionVehicleId }, { getState }) => {
    const { processId } = (
      await api.delete<{ id: number; processId: string }>(
        `/organizations/${currentBusinessEntityIdSelector(
          getState()
        )}/trip-vehicle-compositions/${compositionId}/vehicles/${compositionVehicleId}`
      )
    ).data;

    return { processId };
  }
);

export const removeTripRelation = createAsyncThunk<unknown, number>(
  'removeTripRelation',
  async (tripRelationId, { getState }) => {
    await api.delete<never>(
      `/organizations/${currentBusinessEntityIdSelector(
        getState()
      )}/trip-vehicle-compositions/${tripRelationId}`
    );
  }
);
