import { createAsyncThunk } from 'store/utils';
import { createAction } from '@reduxjs/toolkit';
import type {
  SiServiceAllocationRuleset,
  SiServiceAllocationRulesetQueryParams,
} from 'dto/siServiceAllocationRuleset';
import { api } from '@fleet/shared';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import qs from 'qs';

export const setServiceAllocationRulesetsFilter = createAction<
  Partial<SiServiceAllocationRulesetQueryParams>
>('sleeperInventory/setServiceAllocationRulesetsFilter');

export const getSiServiceAllocationRulesets = createAsyncThunk<
  Array<SiServiceAllocationRuleset>,
  Partial<SiServiceAllocationRulesetQueryParams> | undefined
>(
  'sleeperInventory/getServiceAllocationRulesets',
  async (params = {}, { dispatch, getState }) => {
    params && dispatch(setServiceAllocationRulesetsFilter(params));
    const { filter } = getState().siServiceAllocationRuleset;
    return (
      await api.get<{ items: Array<SiServiceAllocationRuleset> }>(
        `/sleeper-inventory/service-allocation-rulesets${qs.stringify(
          {
            sleeperInventoryAllocationRulesetOwnerId:
              currentBusinessEntityIdSelector(getState()),
            ...filter,
          },
          {
            addQueryPrefix: true,
            allowDots: true,
          }
        )}`
      )
    ).data.items;
  }
);

export const setSiServiceAllocationRuleset = createAction<
  SiServiceAllocationRuleset | undefined
>('sleeperInventory/setServiceAllocationRulesets');
export const getSiServiceAllocationRuleset = createAsyncThunk<
  SiServiceAllocationRuleset,
  SiServiceAllocationRuleset['id']
>('sleeperInventory/getServiceAllocationRuleset', async (id, { dispatch }) => {
  const { data } = await api.get<SiServiceAllocationRuleset>(
    `/sleeper-inventory/service-allocation-rulesets/${id}`
  );
  dispatch(setSiServiceAllocationRuleset(data));
  return data;
});

export const createUpdateSiServiceAllocationRuleset = createAsyncThunk<
  SiServiceAllocationRuleset,
  Partial<SiServiceAllocationRuleset>
>(
  'sleeperInventory/updateCreateServiceAllocationRuleset',
  async ({ id, ...payload }, { dispatch }) => {
    const { data } = await (id ? api.put : api.post)(
      `/sleeper-inventory/service-allocation-rulesets${id ? `/${id}` : ''}`,
      payload
    );
    dispatch(setSiServiceAllocationRuleset(data));
    return data;
  }
);

export const deleteSiServiceAllocationRuleset = createAsyncThunk<
  void,
  Array<SiServiceAllocationRuleset['id']>
>(
  'sleeperInventory/deleteServiceAllocationRuleset',
  async (ids, { dispatch }) => {
    (
      await api.delete(
        `/sleeper-inventory/service-allocation-rulesets/bulk-delete${qs.stringify(
          { ids: ids.join(',') },
          { addQueryPrefix: true }
        )}`
      )
    ).data;
    await dispatch(getSiServiceAllocationRulesets());
  }
);
