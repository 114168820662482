import { createSelector, selector } from 'store/utils';

export const selectInventoryClasses = selector(
  (state) => state.inventoryClass.list
);
export const inventoryClassesSelector = createSelector(selectInventoryClasses);

export const selectCurrentInventoryClass = selector(
  (state) => state.inventoryClass.current
);
export const currentInventoryClassSelector = createSelector(
  selectCurrentInventoryClass
);

export const selectInventoryClassFilter = selector(
  (state) => state.inventoryClass.filter
);
export const inventoryClassFilterSelector = createSelector(
  selectInventoryClassFilter
);
