import { createSlice } from '@reduxjs/toolkit';
import {
  getCompositions,
  getComposition,
  setCurrentCompositionVehicle,
  setCurrentCompositionLineTemplates,
  setCurrentCompositionTrips,
  clearCurrentComposition,
  setCompositionProcessId,
  getAssignedComposition,
  setCompositionsTransportType,
} from 'features/composition/compositionActions';
import type {
  CompositionConstructDto,
  CompositionRowDto,
  LineTransportationTypes,
} from 'dto/composition';
import type { LineTemplateDto, LineTemplateFilterDto } from 'dto/lineTemplate';

export interface CompositionState {
  list: Array<CompositionRowDto>;
  construct?: CompositionConstructDto | null;
  transportationTypeId: LineTransportationTypes;
  lineTemplatesFilter: Partial<LineTemplateFilterDto>;
  lineTemplates: {
    data?: LineTemplateDto[];
    totalCount?: number;
    loading?: boolean;
  };
}

const initialState: CompositionState = {
  list: [],
  transportationTypeId: '',
  lineTemplatesFilter: {
    limit: 10,
  },
  lineTemplates: {},
};

export const { reducer: compositionReducer } = createSlice({
  name: 'composition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompositions.fulfilled, (state, action) => {
        state.list = action.payload.vehicleCompositions;
      })
      .addCase(getComposition.fulfilled, (state, action) => {
        state.construct = action.payload;
      })
      .addCase(getAssignedComposition.fulfilled, (state, action) => {
        state.construct = action.payload;
      })
      .addCase(getAssignedComposition.rejected, (state) => {
        state.construct = null;
      })
      .addCase(setCompositionProcessId, (state, action) => {
        if (state.construct) {
          state.construct.ongoingModificationProcessId = action.payload;
        }
      })
      .addCase(clearCurrentComposition, (state) => {
        delete state.construct;
      })
      .addCase(setCurrentCompositionVehicle, (state, action) => {
        state.construct!.vehicle = action.payload;
      })
      .addCase(setCurrentCompositionLineTemplates, (state, action) => {
        state.construct!.lineTemplates = action.payload;
      })
      .addCase(setCurrentCompositionTrips, (state, action) => {
        state.construct!.trips = action.payload;
      })
      .addCase(setCompositionsTransportType, (state, action) => {
        state.transportationTypeId = action.payload;
      });
  },
});
