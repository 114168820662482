import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  ConfirmDeleteModal,
  Drawer,
  FormControl,
  FormProvider,
  formSubmit,
  makeClassificationOptions,
  RadioGroupField,
  SelectField,
  TextField,
  useForm,
  useModal,
} from '@fleet/shared';
import { Icon, Tooltip } from '@fleet/shared/mui';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { TransTitle } from 'i18n/trans/title';
import { useAlert } from 'react-alert';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { TransLabel } from 'i18n/trans/label';
import { TransMessage } from 'i18n/trans/message';
import {
  createOrUpdateAllocationScoreTemplate,
  deleteAllocationScoreTemplate,
  getAllocationScoreTemplate,
  getAllocationScoreTemplates,
  setAllocationScoreTemplate,
} from 'features/allocationScoreTemplate/allocationScoreTemplateActions';
import { AllocationScoreTemplate } from 'dto/allocationScoreTemplate';
import {
  businessEntitiesSelector,
  inventoryClassesOptionsSelector,
} from 'features/classification/classificationSelectors';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { currentAllocationScoreTemplateSelector } from 'features/allocationScoreTemplate/allocationScoreTemplateSelectors';
import { makeStyles } from '@mui/styles';
import { TransModal } from 'i18n/trans/modal';
import { TransButton } from 'i18n/trans/button';

const useStyles = makeStyles(
  (theme) => ({
    action: { alignSelf: 'flex-start' },
    form: {
      height: '100%',
      width: '424px !important',
      minWidth: '0 !important',
    },
    content: {
      flex: 1,
    },
    actions: {
      padding: theme.spacing(3),
      justifyContent: 'flex-end',
      boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    },
  }),
  {
    name: 'AllocationScoreTemplateDetails',
  }
);

interface AllocationScoreTemplateDetailsProps {}

export const AllocationScoreTemplateDetails: FC<AllocationScoreTemplateDetailsProps> =
  () => {
    const { action, id } =
      useParams<{ action: 'create' | 'edit'; id?: string }>();
    const history = useHistory();
    const dispatch = useDispatch();
    const alert = useAlert();
    const currentAllocationScoreTemplate = useSelector(
      currentAllocationScoreTemplateSelector
    );
    const businessEntities = useSelector(businessEntitiesSelector);
    const currentBusinessEntityId = useSelector(
      currentBusinessEntityIdSelector
    );
    const inventoryClassOptions = useSelector(inventoryClassesOptionsSelector);
    const { open: isOpen, onOpen, onClose } = useModal();
    const classes = useStyles();

    const closeDrawer = useCallback(() => {
      history.replace('/allocation-score-templates');
    }, [history]);

    useEffect(() => {
      dispatch(setAllocationScoreTemplate());
      if (action === 'edit' && id) {
        dispatch(getAllocationScoreTemplate(id)).unwrap().catch(closeDrawer);
      }

      return () => {
        dispatch(setAllocationScoreTemplate());
      };
    }, [action, closeDrawer, dispatch, id]);

    const onSubmit = useCallback(
      (values) =>
        formSubmit(async () => {
          const data = await dispatch(
            createOrUpdateAllocationScoreTemplate(values)
          ).unwrap();

          alert.success(
            <TransMessage
              i18nKey={
                values.id
                  ? 'allocationScoreTemplateUpdated'
                  : 'allocationScoreTemplateCreated'
              }
            />
          );

          if (!values.id) {
            history.replace(`/allocation-score-templates/edit/${data.id}`);
          }

          await dispatch(getAllocationScoreTemplates());
        }),
      [dispatch, alert, history]
    );

    const initialValues = useMemo(
      () => ({
        preferVehiclesWithLowerLoadFactor: true,
        ownerId: currentBusinessEntityId,
        ...currentAllocationScoreTemplate,
      }),
      [currentAllocationScoreTemplate, currentBusinessEntityId]
    );

    const { form, handleSubmit, submitting } = useForm<AllocationScoreTemplate>(
      {
        initialValues,
        onSubmit,
        subscription: { submitting: true },
      }
    );

    const handleDelete = useCallback(async () => {
      await dispatch(deleteAllocationScoreTemplate()).unwrap();

      alert.success(<TransMessage i18nKey="allocationScoreTemplateDeleted" />);
      closeDrawer();
    }, [alert, closeDrawer, dispatch]);

    return (
      <Drawer anchor="right" elevation={0} onClose={closeDrawer} open>
        <FormProvider {...form}>
          <Stack
            className={classes.form}
            component="form"
            onSubmit={handleSubmit}
          >
            <CardHeader
              sx={{ pb: 0 }}
              classes={{ action: classes.action }}
              title={
                <Typography variant="subtitle" component="div">
                  {id ? (
                    <TransTitle i18nKey="allocationScoreTemplate" />
                  ) : (
                    <TransTitle i18nKey="newAllocationScoreTemplate" />
                  )}
                </Typography>
              }
              action={
                <IconButton aria-label="close" onClick={closeDrawer}>
                  <Tooltip content={<TransLabel i18nKey="close" />} delay={500}>
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              }
            />
            <CardContent sx={{ flex: 1 }}>
              {id && (
                <>
                  <Button
                    size="small"
                    variant="text"
                    color="error"
                    startIcon={<Icon name="delete" size={16} />}
                    onClick={onOpen}
                  >
                    <TransButton i18nKey="delete" />
                  </Button>
                  <ConfirmDeleteModal
                    handleDelete={handleDelete}
                    title={
                      <TransModal i18nKey="deleteAllocationScoreTemplate" />
                    }
                    description={
                      <TransModal i18nKey="allocationScoreTemplateDeletionDescription" />
                    }
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                </>
              )}
              <TextField
                label={<TransLabel i18nKey="name" />}
                name="name"
                required
                margin="dense"
              />
              <SelectField
                name="ownerId"
                label={<TransLabel i18nKey="owner" />}
                options={makeClassificationOptions(businessEntities)}
                margin="dense"
                disabled
              />
              <SelectField
                name="inventoryClassId"
                label={<TransLabel i18nKey="inventoryClass" />}
                options={inventoryClassOptions}
                margin="dense"
                showEmptyOption
              />
              <FormControl
                label={<TransLabel i18nKey="passengerCountFromTo" />}
                sx={{ margin: '8px 0 4px' }}
              >
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    gap: '10px',
                  }}
                >
                  <TextField name="passengerCountFrom" type="number" />
                  <Box sx={{ marginTop: '6px' }}>-</Box>
                  <TextField name="passengerCountTo" type="number" />
                </Grid>
              </FormControl>

              <TextField
                label={<TransLabel i18nKey="snugRankWeight" />}
                name="snugRankWeight"
                type="number"
                margin="dense"
                required
              />
              <TextField
                label={<TransLabel i18nKey="snugLoadFactorWeight" />}
                name="snugLoadFactorWeight"
                type="number"
                margin="dense"
                required
              />
              <TextField
                label={<TransLabel i18nKey="snugAvailabilityWeight" />}
                name="snugAvailabilityWeight"
                type="number"
                margin="dense"
                required
              />
              <TextField
                label={<TransLabel i18nKey="snugConditionedPropertiesWeight" />}
                name="snugConditionedPropertiesWeight"
                type="number"
                margin="dense"
                required
              />
              <TextField
                label={<TransLabel i18nKey="snugMatchedPropertiesWeight" />}
                name="snugMatchedPropertiesWeight"
                type="number"
                margin="dense"
                required
              />
              <TextField
                label={<TransLabel i18nKey="vehicleLoadFactorWeight" />}
                name="vehicleLoadFactorWeight"
                type="number"
                margin="dense"
                required
              />
              <TextField
                label={<TransLabel i18nKey="usedVehicleWeight" />}
                name="usedVehicleWeight"
                type="number"
                margin="dense"
                required
              />
              <RadioGroupField
                name="preferVehiclesWithLowerLoadFactor"
                label={
                  <TransLabel i18nKey="preferVehiclesWithLowerLoadFactor" />
                }
                options="BOOL_ONLY"
                margin="dense"
                inline
              />
            </CardContent>
            <CardActions className={classes.actions}>
              <Button variant="text" color="primary" onClick={closeDrawer}>
                <TransButton i18nKey="cancel" />
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                startIcon={<Icon name={id ? 'check' : 'plus'} />}
              >
                <TransButton i18nKey={id ? 'save' : 'create'} />
              </Button>
            </CardActions>
          </Stack>
        </FormProvider>
      </Drawer>
    );
  };
