import { FC } from 'react';
import { Select, SelectProps } from '@fleet/shared/mui';
import { SelectOption } from '@fleet/shared';

export const SeatRankSelect: FC<Omit<SelectProps<number>, 'options'>> = (
  props
) => {
  const options: SelectOption<number>[] = [...Array(10).keys()].map((num) => ({
    value: num + 1,
    label: String(num + 1),
  }));

  return <Select options={options} {...props} />;
};
